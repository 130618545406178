import { FC, useEffect, useMemo, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import { useMutation, useQuery } from 'react-query';

import { useGetCountriesList } from '@app/api/hooks/useGetCountries';
import { useHttpClient } from '@app/api/lib/useHttpClient';
import { EEligibilityStatus, getPairEligibilityMutation } from '@app/api/requests/getEligibilityMutation';
import { ISearchCompanyForm } from '@app/components/features/SearchCompanyForm/types';
import { CompaniesListContainer } from '@app/components/features/SelectCompany/CompaniesListContainer';
import { TCompany } from '@app/components/features/SelectCompany/CompaniesListContainer/CompaniesList/types';
import { SelectCompanyDialogsContainer } from '@app/components/features/SelectCompany/DialogsContainer';
import { StepContainer } from '@app/components/features/StepContainer';
import { EDealTradeRole, EFlowStep } from '@app/context/FlowTransitionsContext';
import {
  TStennClientsServiceApplicationPortModelsCounterpartyAddCounterpartyDto,
  TStennClientsServiceContractsEnumsCompanyRole,
  TStennClientsServiceRegistryClientModelsSearchDto,
  TStennClientsServiceRegistryClientModelsSearchResultDto,
} from '@app/core/__generated__';
import { apiRoutes } from '@app/core/__generated__/apiRoutes';
import { TEST_IDS } from '@app/core/constants';
import { useLogout } from '@app/hooks/auth/useLogout';
import { useFlowTransitionsContext } from '@app/hooks/useFlowTransitionsContext';
import { useInitialFlowState } from '@app/hooks/useInitialFlowState';
import { assert } from '@app/utils/assert';
import { ArrowForward } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { Button } from '@stenngroup/ui-kit';
import { sleep } from '@stenngroup/utilities';
import { match } from 'ts-pattern';

export const SelectNewTradePartner: FC = () => {
  const { handlePrevStep, getStateByStep, getFlowState, handleNextStep } = useFlowTransitionsContext();
  const value = getStateByStep<ISearchCompanyForm>(EFlowStep.SEARCH_TRADE_PARTNER);
  const companyRole = getFlowState().tradeRole as TStennClientsServiceContractsEnumsCompanyRole;
  const [selectedCompany, setSelectedCompany] = useState<TCompany | null>(null);
  const [newCompany, setNewCompany] = useState<TStennClientsServiceRegistryClientModelsSearchResultDto | null>(null);

  const httpClient = useHttpClient();

  const query: TStennClientsServiceRegistryClientModelsSearchDto = useMemo(
    () => ({
      companyName: value.companyName,
      duns: value.duns,
      registrationNumber: value.registrationNumber,
      countryCode: value.country?.place,
      state: value.state?.place,
    }),
    [value]
  );

  const {
    data: companies,
    status,
    error,
  } = useQuery([apiRoutes.SearchSearchDuns, { query }], () =>
    httpClient(apiRoutes.SearchSearchDuns, 'post', {
      data: {
        ...query,
      },
    })
  );

  const contractorCompanyRole = match<
    TStennClientsServiceContractsEnumsCompanyRole,
    TStennClientsServiceContractsEnumsCompanyRole
  >(companyRole)
    .with('Buyer', () => 'Supplier')
    .with('Supplier', () => 'Buyer')
    .exhaustive();

  const { handleLogout } = useLogout();

  const [currentDialog, setCurrentDialog] = useState<'companyNotListed' | 'dnbError' | 'ineligibleCompany' | null>(
    null
  );

  useEffect(() => {
    if (!error) return;
    setCurrentDialog('dnbError');
  }, [error]);

  const handleSubmitNewCompany = async (data: ISearchCompanyForm) => {
    setCurrentDialog(null);
    const company: TCompany = {
      companyName: data.companyName,
      duns: data.duns || null,
      registrationNumber: data.registrationNumber || null,
      address: {
        countryCode: data.country?.place || null,
        state: data.state?.place || null,
        city: null,
        street: null,
        postalCode: null,
      },
      operationStatus: 'Unknown',
    };
    setNewCompany(company);
    setSelectedCompany(company);
  };

  const handleSearchAgain = () => {
    setCurrentDialog(null);
    handlePrevStep();
  };

  const { data: countriesList } = useGetCountriesList();
  const { retrieveInitialFlowState, isLoading: isLoadingInitialFlowState } = useInitialFlowState();
  const { mutate: addCounterparty, isLoading } = useMutation({
    mutationFn: (data: TStennClientsServiceApplicationPortModelsCounterpartyAddCounterpartyDto) => {
      return httpClient(apiRoutes.CounterpartyAddCounterparty, 'post', {
        data,
      });
    },
    onSuccess: async (data, values) => {
      await sleep(2500);
      handleNextStep({
        newFlowState: await retrieveInitialFlowState({
          trId: data.tradeRelationId,
          counterpartyCompanyId: data.counterpartyCompanyId,
          tradeRole: companyRole as EDealTradeRole,
        }),
        stepState: {
          ...data,
          ...values,
        },
      });
    },
  });

  const { mutateAsync: getEligibility, isLoading: isEligibilityFetching } = useMutation(getPairEligibilityMutation);

  const handleNext = async () => {
    assert(selectedCompany, 'selectedCompany is not defined');
    const eligibilityStatus = await getEligibility({
      companyRole,
      alpha2Code: value.country.place || '',
      counterPartyDuns: selectedCompany.duns || undefined,
    });
    if (eligibilityStatus !== EEligibilityStatus.Eligible) {
      setCurrentDialog('ineligibleCompany');
      return;
    }
    addCounterparty({
      ...selectedCompany,
      name: selectedCompany.companyName,
      address: {
        ...selectedCompany.address,
        country: countriesList?.find((country) => country.alpha2Code === value.country.place)?.id || null,
      },
      companyRole: contractorCompanyRole,
      chineseCompanyName: value.companyNameInChinese || null,
      chineseSocialCreditCode: value.unifiedSocialCreditCode || null,
    });
  };

  const isPending = isLoading || isEligibilityFetching || isLoadingInitialFlowState;

  return (
    <StepContainer
      title={<FormattedMessage id="createDeal.selectTradePartner" />}
      actions={
        <Stack gap={1} direction="row">
          <Button
            data-testid={TEST_IDS.companyNotListedButton}
            size="medium"
            variant="secondary"
            onClick={() => setCurrentDialog('companyNotListed')}
          >
            <FormattedMessage id="pages.SelectCompanyPage.companyIsNotOnTheList" />
          </Button>
          <Button
            variant="primary"
            onClick={handleNext}
            endIcon={<ArrowForward />}
            disabled={isPending || !selectedCompany}
            isLoading={isPending}
            data-testid={TEST_IDS.nextButton}
          >
            <FormattedMessage id="constants.universalMessages.next" />
          </Button>
        </Stack>
      }
    >
      <CompaniesListContainer
        companies={companies}
        selectedCompany={selectedCompany}
        setSelectedCompany={setSelectedCompany}
        newCompany={newCompany}
        status={status}
      />
      <SelectCompanyDialogsContainer
        onClose={() => setCurrentDialog(null)}
        currentDialog={currentDialog}
        currentRole={contractorCompanyRole}
        handleLogout={handleLogout}
        handleSubmitNewCompany={handleSubmitNewCompany}
        handleSearchAgain={handleSearchAgain}
        defaultFormValues={value}
        showLogoutButton={false}
      />
    </StepContainer>
  );
};
