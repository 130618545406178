import { useHttpClient } from '@app/api/lib/useHttpClient';
import { TSubmitData } from '@app/components/features/FlowTransitions/screens/AddBankDetails/types';
import { EDealTradeRole } from '@app/context/FlowTransitionsContext';
import { apiRoutes } from '@app/core/__generated__/apiRoutes';
import { useMutation, useQuery } from 'react-query';

interface IUseBankDetailsScreenPayload {
  tradeRole: EDealTradeRole | null;
  tradeRelationId: string;
  counterpartyCompanyId: string;
  onSuccess: () => void;
}

export const useBankDetailsScreen = (payload: IUseBankDetailsScreenPayload) => {
  const { tradeRelationId, tradeRole, counterpartyCompanyId, onSuccess } = payload;
  const isCounterpartySupplier = tradeRole === EDealTradeRole.Buyer;

  const httpClient = useHttpClient();

  const { data: isAbleToCreateBankAccount, isLoading: isLoadingBankDetailsState } = useQuery({
    queryKey: [
      isCounterpartySupplier ? apiRoutes.BankDetailsCanCreateForSupplierSupplierId : apiRoutes.BankDetailsCanCreate,
      tradeRelationId,
    ],
    queryFn: ({ signal }) => {
      if (isCounterpartySupplier) {
        return httpClient(apiRoutes.BankDetailsCanCreateForSupplierSupplierId, 'get', {
          signal,
          routeParams: {
            supplierId: counterpartyCompanyId,
          },
        });
      }
      return httpClient(apiRoutes.BankDetailsCanCreate, 'get', {
        signal,
      });
    },
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (bankAccountDetails: TSubmitData) => {
      if (isCounterpartySupplier) {
        return httpClient(apiRoutes.BankDetailsCreateForSupplierSupplierId, 'post', {
          routeParams: {
            supplierId: counterpartyCompanyId,
          },
          data: {
            ...bankAccountDetails,
          },
        });
      }
      return httpClient(apiRoutes.BankDetailsCreate, 'post', {
        data: {
          ...bankAccountDetails,
        },
      });
    },
    onSuccess,
  });

  return {
    canCreate: isAbleToCreateBankAccount,
    isFetching: isLoadingBankDetailsState,
    createBankDetails: mutate,
    isLoading,
  };
};
