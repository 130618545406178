import { FC, ReactNode } from 'react';

import { FormattedMessage } from 'react-intl';

import { TEST_IDS } from '@app/core/constants';
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@stenngroup/ui-kit';

import { useApplicationState } from '@app/context/ApplicationStateContext/hooks/useApplicationState';

interface IIneligibleCompanyDialogContentProps {
  closeLabel: ReactNode;
  actionLabel: ReactNode;
  onClose: () => void;
  onAction: () => void;
}

export const IneligibleCompanyDialogContent: FC<IIneligibleCompanyDialogContentProps> = ({
  onClose,
  onAction,
  actionLabel,
  closeLabel,
}) => {
  const { isReadOnly } = useApplicationState();
  return (
    <>
      <DialogTitle
        gap={2}
        sx={{
          py: 1,
        }}
        width="100%"
        alignItems="center"
        data-testid={TEST_IDS.ineligibleCompanyDialog}
      >
        <Typography.H5>
          <FormattedMessage
            data-testid={TEST_IDS.ineligibleCompanyDialogTitle}
            id="containers.BuyerSupplierFlow.steps.AddYourCompany.ineligibleCompany.yourCompany.title"
          />
        </Typography.H5>
      </DialogTitle>
      <DialogContent>
        <Typography.Body1 data-testid={TEST_IDS.ineligibleCompanyDialogDescription}>
          <FormattedMessage
            id="containers.BuyerSupplierFlow.steps.AddYourCompany.ineligibleCompany.yourCompany.cannotFinanceDeal"
            values={{
              mailTo: <a href="mailto:cs@stenn.com">cs@stenn.com</a>,
            }}
          />
        </Typography.Body1>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} data-testid={TEST_IDS.ineligibleCompanyDialogButtonCancel}>
          {closeLabel}
        </Button>
        <Button
          variant="contained"
          onClick={onAction}
          data-testid={TEST_IDS.ineligibleCompanyDialogButton}
          disabled={isReadOnly}
        >
          {actionLabel}
        </Button>
      </DialogActions>
    </>
  );
};
