import {
  TOperationsContractsDtoDocumentType,
  TStennClientsServiceApplicationPortModelsInvoiceInvoiceAttachmentResponseDto,
  TStennClientsServiceApplicationPortModelsInvoiceInvoiceResponseDto,
} from '@app/core/__generated__';
import { EDealType } from '@app/core/constants';
import { IEditInvoiceForm, IEditLoadedDocument } from '../../types';

function formatDocument(
  document: TStennClientsServiceApplicationPortModelsInvoiceInvoiceAttachmentResponseDto | undefined,
  documentType: TOperationsContractsDtoDocumentType
): IEditLoadedDocument | null {
  if (!document) return null;

  return {
    documentType,
    documentId: document.id!,
    name: document.fileName!,
    uid: document.id,
    isUploaded: true,
  };
}

const hasAdditionalUploadedDocuments = (docType: TOperationsContractsDtoDocumentType) => {
  const knownDocuments: TOperationsContractsDtoDocumentType[] = ['Other', 'CommercialInvoice', 'TransportDocument'];

  return !knownDocuments.includes(docType!);
};

export const getFormData = (
  invoice: Required<TStennClientsServiceApplicationPortModelsInvoiceInvoiceResponseDto>
): IEditInvoiceForm => {
  return {
    number: invoice.number ?? '',
    currency: String(invoice.currencyIsoNumericCode) ?? '',
    faceValueNationalCurrency: String(invoice.totalValue),
    prepaidAmount: invoice.prepaidAmount ? String(invoice.prepaidAmount) : '',
    dueDate: new Date(invoice.dueDate),
    otherDocuments: (invoice.attachments ?? [])
      .filter((doc) => doc.documentType === 'Other')
      .map((doc) => formatDocument(doc, 'Other')) as IEditInvoiceForm['otherDocuments'],

    invoiceDocument: formatDocument(
      invoice.attachments?.find((doc) => doc.documentType === 'CommercialInvoice'),
      'CommercialInvoice'
    ),

    transportDocument: formatDocument(
      invoice.attachments?.find((doc) => doc.documentType === 'TransportDocument'),
      'TransportDocument'
    ),

    additionalRequiredDocuments: (invoice.attachments ?? [])
      .filter((document) => hasAdditionalUploadedDocuments(document.documentType!))
      .map((doc) => formatDocument(doc, doc.documentType!)) as IEditInvoiceForm['additionalRequiredDocuments'],

    dealType: EDealType.Goods,
    isCommodity: invoice.isCommodity,
    isPerishable: invoice.isPerishable,
    isUnknownCategory: invoice.isCommodity === false && invoice.isPerishable === false,
  };
};
