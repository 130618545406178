import { FC, PropsWithChildren, useMemo, useState } from 'react';
import { BreadcrumbContainerContext } from './context';

export const BreadcrumbContainerProvider: FC<PropsWithChildren> = ({ children }) => {
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);

  const contextValue = useMemo(
    () => ({
      containerRef,
      setContainerRef,
    }),
    [containerRef]
  );

  return <BreadcrumbContainerContext.Provider value={contextValue}>{children}</BreadcrumbContainerContext.Provider>;
};
