import { FormattedMessage } from 'react-intl';

import { ICompanyStatusProps } from '../types';

export const StatusText: Record<ICompanyStatusProps['status'], JSX.Element> = {
  Available: <FormattedMessage id="components.companyStatus.approved" />,
  FinancingDenied: <FormattedMessage id="components.companyStatus.financeDenied" />,
  Unknown: <FormattedMessage id="components.companyStatus.unknown" />,
  Overdue: <FormattedMessage id="components.companyStatus.overdue" />,
  UnderReview: <FormattedMessage id="components.companyStatus.underReview" />,
  RequestingLimit: <FormattedMessage id="components.companyStatus.requestingLimit" />,
};
