import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import { SentryFingerprintError } from '@app/utils/errors';

const ACCESS_TOKEN_NAME = 'access_token';
const ACCESS_TOKEN_REGEX = new RegExp(`${ACCESS_TOKEN_NAME}=[^&]+`);

if (window._STENN_.FCG_SENTRY_DSN && import.meta.env.MODE === 'production') {
  Sentry.init({
    dsn: window._STENN_.FCG_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.browserTracingIntegration({
        enableInp: true,
      }),
    ],
    tracesSampleRate: window._STENN_.ENVIRONMENT === 'live' ? 1 : 0.1,
    environment: window._STENN_.ENVIRONMENT,
    beforeBreadcrumb(breadcrumb) {
      if (breadcrumb.category === 'console') {
        const message = breadcrumb.message;
        const args = breadcrumb.data?.arguments || [];

        if (message?.includes(ACCESS_TOKEN_NAME)) {
          breadcrumb.message = message.replace(ACCESS_TOKEN_REGEX, `${ACCESS_TOKEN_NAME}=***`);
        }

        args.forEach((arg: unknown, index: number) => {
          if (typeof arg === 'string' && arg.includes(ACCESS_TOKEN_NAME)) {
            args[index] = arg.replace(ACCESS_TOKEN_REGEX, `${ACCESS_TOKEN_NAME}=***`);
          }
        });

        if (breadcrumb.data) {
          breadcrumb.data.arguments = args;
        }
      }
      return breadcrumb;
    },
    beforeSend: (event, hint) => {
      const exception = hint.originalException;

      if (exception instanceof Error) {
        if (exception.message.includes('Failed to fetch dynamically imported module')) {
          if (window.confirm('Reload is required for application to work properly. Do you want to reload now?')) {
            window.location.reload();
            return null;
          }
        }

        if (exception instanceof SentryFingerprintError) {
          const fingerprint = exception.message.replace(/\W+/g, '-');
          event.fingerprint = [fingerprint];
        }
      }

      return event;
    },
  });
}
