import { BASE_API_URLS } from '@app/core/api';
import { TUnionApiResponse } from '@app/types/apiResponse';

interface IAcceptContactPayload {
  configuredFetch: typeof fetch;
  payload: {
    requestId: string;
    fullName: string;
  };
}

export const acceptContactRequest = async ({
  configuredFetch,
  payload,
}: IAcceptContactPayload): Promise<TUnionApiResponse<void>> => {
  const response = await configuredFetch(`${BASE_API_URLS.clientsServiceWeb}/LinkToCompanyRequest/ApproveByCompany`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      requestId: payload.requestId,
    }),
  });
  if (!response.ok) {
    return {
      type: 'error',
    };
  }
  return {
    type: 'success',
    data: undefined,
  };
};
