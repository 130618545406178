import { TStennClientsServiceApplicationPortModelsInvoiceInvoiceResponseDto } from '@app/core/__generated__';
import { CircularProgress, DialogContent } from '@mui/material';
import { Stack } from '@mui/system';
import { FC } from 'react';

import { useGetInvoiceById } from '@app/hooks/useGetInvoiceById';
import { EditInvoiceInner } from './EditInvoiceInner';
import { IEditInvoiceDialogContentProps } from './types';

export const EditInvoiceDialogContent: FC<IEditInvoiceDialogContentProps> = ({
  trId,
  invoiceId,
  onClose,
  onSuccess,
}) => {
  const { data: invoice } = useGetInvoiceById(invoiceId);

  if (!invoice)
    return (
      <DialogContent>
        <Stack flexGrow={1} alignItems="center" justifyContent="center" height="100%" minHeight="80vh">
          <CircularProgress />
        </Stack>
      </DialogContent>
    );

  return (
    <EditInvoiceInner
      trId={trId}
      invoice={invoice as Required<TStennClientsServiceApplicationPortModelsInvoiceInvoiceResponseDto>}
      onClose={onClose}
      onSuccess={onSuccess}
    />
  );
};
