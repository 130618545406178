import { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';

import { Dialog, DrawerHeader, DrawerV2, Typography, useToast } from '@stenngroup/ui-kit';

import { ConfirmationDialogContent } from '@app/components/features/FlowDrawerContainer/ConfirmationDialogContent';
import { FlowTransitions } from '@app/components/features/FlowTransitions/FlowTransitions';
import { EFlowType } from '@app/context/FlowTransitionsContext';
import { apiRoutes } from '@app/core/__generated__/apiRoutes';
import { TEST_IDS } from '@app/core/constants/testIds';
import { useFlowTransitionsContext } from '@app/hooks/useFlowTransitionsContext';

export const FlowDrawerContainer: FC = () => {
  const intl = useIntl();
  const { showSuccess } = useToast();
  const { isOpen, close, getFlowState, isDealSubmitted } = useFlowTransitionsContext();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  const { flowType, dealId } = getFlowState();
  const queryClient = useQueryClient();

  const handleClose = () => {
    if (!dealId || isDealSubmitted) {
      close();
      return;
    }

    setIsConfirmationDialogOpen(true);
  };

  const handleExit = async () => {
    queryClient.invalidateQueries([apiRoutes.DealsGetDeal, dealId, false]);

    setIsConfirmationDialogOpen(false);
    close();
    showSuccess(intl.formatMessage({ id: 'createDeal.draftDeal.saved' }));
  };

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (dealId && !isDealSubmitted) {
        event.preventDefault();
        event.returnValue = intl.formatMessage({ id: 'confirmations.beforeUnload' });
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [dealId, intl, isDealSubmitted]);

  return (
    <>
      <DrawerV2
        open={isOpen}
        onClose={handleClose}
        sx={{
          '& .MuiDrawer-paper': {
            overflowX: 'hidden',
            minWidth: 580,
          },
        }}
      >
        <>
          <DrawerHeader onClose={handleClose}>
            <Typography.H5 data-testid={TEST_IDS.dealFlow}>
              {flowType === EFlowType.AddTradePartner ? (
                <FormattedMessage id="constants.universalMessages.addTradePartner" />
              ) : (
                <FormattedMessage id="constants.universalMessages.createNewDeal" />
              )}
            </Typography.H5>
          </DrawerHeader>
          <FlowTransitions />
        </>
      </DrawerV2>
      <Dialog open={isConfirmationDialogOpen} onClose={() => setIsConfirmationDialogOpen(false)}>
        <ConfirmationDialogContent onContinue={() => setIsConfirmationDialogOpen(false)} onExit={handleExit} />
      </Dialog>
    </>
  );
};
