import { withSentryReactRouterV6Routing } from '@sentry/react';
import React, { FC, Suspense, memo } from 'react';
import { Navigate, Outlet, Route, Routes as RouterRoutes } from 'react-router-dom';

import { routes } from '@app/routes/config';
import { withAuthenticated } from '@app/routes/hocs/withAuthenticated/withAuthenticated';
import { withAutoNavigation } from '@app/routes/hocs/withAutoNavigation/withAutoNavigation';
import { withUnauthenticated } from '@app/routes/hocs/withUnauthenticated/withUnauthenticated';

const RouterRoutesWithSentry = withSentryReactRouterV6Routing(RouterRoutes);

import { FlowDrawerContainer } from '@app/components/features/FlowDrawerContainer';
import { DashboardRoutesLayout } from '@app/components/layouts/DashboardRoutesLayout';
import { OnboardingRoutesLayout } from '@app/components/layouts/OnboardingRoutesLayout';
import { FlowTransitionsContextProvider } from '@app/context/FlowTransitionsContext';
import DocusignRedirect from '@app/pages/Deals/DocusignRedirect';
import { withEmailNotVerified } from '@app/routes/hocs/withEmailNotVerified';
import { withOnboardingNotRequired } from '@app/routes/hocs/withOnboardingNotRequired';
import { withRoleRequired } from '@app/routes/hocs/withRoleRequired';

const RootRoute = withAuthenticated(() => <Navigate to={routes.dashboard.path} />);

const LogoutPage = React.lazy(() => import('@app/pages/Auth/LogoutPage'));
const DashboardPage = React.lazy(() => import('@app/pages/Dashboard/DashboardPage'));
const InvoicesPage = React.lazy(() => import('@app/pages/Invoices/InvoicesPage'));
const InvoicesLegacyRedirectPage = React.lazy(() => import('@app/pages/Invoices/InvoicesLegacyPage'));
const InvoiceDetailsPage = React.lazy(() => import('@app/pages/Invoices/InvoiceDetailsPage/InvoiceDetailsPage'));
const DealsPage = React.lazy(() => import('@app/pages/Deals/DealsPage'));
const DealsLegacyPage = React.lazy(() => import('@app/pages/Deals/DealsLegacyPage'));
const DealDetailsPage = React.lazy(() => import('@app/pages/Deals/DealDetailsPage/DealDetailsPage'));
const BuyersPage = React.lazy(() => import('@app/pages/Buyers/BuyersPage'));
const BuyerDetailsPage = React.lazy(() => import('@app/pages/Buyers/BuyerDetailsPage'));
const SuppliersPage = React.lazy(() => import('@app/pages/Suppliers/SuppliersPage'));
const SupplierDetailsPage = React.lazy(() => import('@app/pages/Suppliers/SupplierDetailsPage'));
const RoleSelectionV2 = React.lazy(() => import('@app/pages/RoleSelection/RoleSelectionV2'));
const SystemAdministrator = React.lazy(() => import('@app/legacy/SystemAdministrator'));
const AddCompanyInformationPage = React.lazy(() => import('@app/pages/AddCompanyInformationPage'));

const LoginPageV3 = React.lazy(() => import('@app/pages/Auth/LoginPageV3'));
const SignUpPageV3 = React.lazy(() => import('@app/pages/Auth/SignUpPageV3'));

const SocialAccountActivationPageV3 = React.lazy(() => import('@app/pages/Auth/SocialAccountActivationPageV3'));
const ForgotPasswordPageV3 = React.lazy(() => import('@app/pages/Auth/ForgotPasswordPageV3'));

const ForgotPasswordEmailConfirmationPageV3 = React.lazy(
  () => import('@app/pages/Auth/ForgotPasswordPageV3/steps/ForgotPasswordEmailConfirmationPageV3')
);

const ForgotPasswordChangePasswordPageV3 = React.lazy(
  () => import('@app/pages/Auth/ForgotPasswordPageV3/steps/ForgotPasswordChangePasswordPageV3')
);

const ForgotPasswordSuccessPageV3 = React.lazy(
  () => import('@app/pages/Auth/ForgotPasswordPageV3/steps/PasswordChangeSuccessPageV3')
);

const AcceptInvitationPageV3 = React.lazy(() => import('@app/pages/Auth/AcceptInvitationPageV3'));

const EmailConfirmationPageV4 = React.lazy(() => import('@app/pages/Auth/EmailConfirmationPageV4'));

const AccountNotCreatedPageV3 = React.lazy(() => import('@app/pages/Auth/AccountNotCreatedPageV3'));

const GoogleAuthHandlerPage = React.lazy(() => import('@app/pages/Auth/GoogleAuthHandlerPage'));

const UnauthenticatedRouteContainer: FC = withUnauthenticated(() => <Outlet />);

const AuthenticatedRouteContainer: FC = withAuthenticated(() => (
  <FlowTransitionsContextProvider>
    <FlowDrawerContainer />
    <Outlet />
  </FlowTransitionsContextProvider>
));

const AutoNavigationRouteContainer: FC = withAutoNavigation(() => <Outlet />);

const OnboardingNotRequiredRouteContainer: FC = withOnboardingNotRequired(() => <Outlet />);

const EmailNotVerifiedRouteContainer: FC = withEmailNotVerified(() => <Outlet />);

const BuyerOnlyRouteContainer: FC = withRoleRequired(() => <Outlet />, 'Buyer');

const SupplierOnlyRouteContainer: FC = withRoleRequired(() => <Outlet />, 'Supplier');

const PublicRouteContainer: FC = () => <Outlet />;

const RouteContainer: FC = () => (
  <Suspense fallback={null}>
    <Outlet />
  </Suspense>
);

export const Routes = memo(function Routes() {
  return (
    <RouterRoutesWithSentry>
      {/* Redirects to main page when authenticated and to login otherwise */}
      <Route path={routes.root.path} element={<RootRoute />} />

      <Route element={<RouteContainer />}>
        {/* Routes that can be accessed only when unauthenticated  */}
        <Route element={<UnauthenticatedRouteContainer />}>
          <Route element={<OnboardingRoutesLayout isLogoutButtonShown={false} />}>
            <Route path={routes.auth.login.path} element={<LoginPageV3 />} />
            <Route path={routes.auth.signUp.path} element={<SignUpPageV3 />} />
            <Route path={routes.auth.forgotPassword.path} element={<ForgotPasswordPageV3 />} />
            <Route
              path={routes.auth.forgotPassword.emailConfirmation.path}
              element={<ForgotPasswordEmailConfirmationPageV3 />}
            />
            <Route
              path={routes.auth.forgotPassword.changePassword.path}
              element={<ForgotPasswordChangePasswordPageV3 />}
            />
            <Route path={routes.auth.forgotPassword.passwordWasSet.path} element={<ForgotPasswordSuccessPageV3 />} />
            <Route path={routes.auth.acceptInvitation.path} element={<AcceptInvitationPageV3 />} />
            <Route path={routes.acceptInvitationExternal.path} element={<AcceptInvitationPageV3 />} />
          </Route>
        </Route>

        {/* Routes that can be accessed only when authenticated */}
        <Route element={<AuthenticatedRouteContainer />}>
          <Route element={<OnboardingRoutesLayout isLogoutButtonShown />}>
            <Route element={<EmailNotVerifiedRouteContainer />}>
              <Route path={routes.auth.emailConfirmation.path} element={<EmailConfirmationPageV4 />} />
              <Route
                path={routes.auth.signUp.socialAccountActivation.path}
                element={<SocialAccountActivationPageV3 />}
              />
            </Route>
            <Route path={routes.auth.signUp.accountNotCreated.path} element={<AccountNotCreatedPageV3 />} />
            <Route element={<OnboardingNotRequiredRouteContainer />}>
              <Route path={routes.roleSelection.path} element={<RoleSelectionV2 />} />
            </Route>
            <Route element={<OnboardingNotRequiredRouteContainer />}>
              <Route path={`${routes.addCompanyInformation.path}/*`} element={<AddCompanyInformationPage />} />
            </Route>
          </Route>
          <Route path={routes.systemAdministrator.path} element={<SystemAdministrator />} />
          <Route path={routes.auth.googleHandler.path} element={<GoogleAuthHandlerPage />} />
          <Route element={<AutoNavigationRouteContainer />}>
            <Route element={<DashboardRoutesLayout />}>
              <Route path={routes.dashboard.path} element={<DashboardPage />} />
              <Route path={routes.invoices.path}>
                <Route path={routes.invoices.path} element={<InvoicesPage />} />
                <Route path={routes.invoices.legacyOfSuppliers.path} element={<InvoicesLegacyRedirectPage />} />
                <Route path={routes.invoices.invoiceDetails.path} element={<InvoiceDetailsPage />} />
              </Route>
              <Route element={<BuyerOnlyRouteContainer />}>
                <Route path={routes.suppliers.path}>
                  <Route path={routes.suppliers.path} element={<SuppliersPage />} />
                  <Route path={`${routes.suppliers.supplierDetails.path}/*`} element={<SupplierDetailsPage />} />
                </Route>
              </Route>
              <Route element={<SupplierOnlyRouteContainer />}>
                <Route path={routes.buyers.path}>
                  <Route path={routes.buyers.path} element={<BuyersPage />} />
                  <Route path={`${routes.buyers.buyerDetails.path}/*`} element={<BuyerDetailsPage />} />
                </Route>
              </Route>
              <Route path={routes.deals.path}>
                <Route path={routes.deals.path} element={<DealsPage />} />
                <Route path={routes.deals.legacyOfSuppliers.path} element={<DealsLegacyPage />} />
                <Route path={routes.deals.dealDetails.path} element={<DealDetailsPage />} />
              </Route>
              <Route path={routes.deals.legacyDealDetails.path} element={<DealDetailsPage />} />
              {/* Redirect from DocuSign routes START */}
              <Route
                path={routes.deals.fromDocuSign.success.path}
                element={<DocusignRedirect docusignOutcome="success" />}
              />
              <Route
                path={routes.deals.fromDocuSign.cancel.path}
                element={<DocusignRedirect docusignOutcome="cancel" />}
              />
              <Route
                path={routes.deals.fromDocuSign.declined.path}
                element={<DocusignRedirect docusignOutcome="declined" />}
              />
              {/* Redirect from DocuSign routes END */}
            </Route>
          </Route>
          <Route path={routes.logout.path} element={<LogoutPage />} />
          <Route path="*" element={<Navigate to={routes.root.path} />} />
        </Route>

        {/* Public routes, can be accessed when authenticated and unauthenticated */}
        <Route element={<PublicRouteContainer />}>
          {/* <Route element={<LegalRoutesLayout />}>
            <Route path={routes.termsOfUse.path} element={<LazyTermsOfUsePage />} />
            <Route path={routes.privacyPolicy.path} element={<LazyPrivacyPolicyPage />} />
          </Route> */}
        </Route>
      </Route>

      {/* Redirects to root / from anywhere, which in turn will redirect to needed page */}
      <Route path="*" element={<Navigate to={routes.root.path} />} />
    </RouterRoutesWithSentry>
  );
});
