import { FC } from 'react';

import { useIntl } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';

import { useGetProfile } from '@app/api/hooks/useGetProfile';
import { useHttpClient } from '@app/api/lib/useHttpClient';
import { apiRoutes } from '@app/core/__generated__/apiRoutes';
import { TEST_IDS } from '@app/core/constants';
import { Nova2ThemeWrapper } from '@app/core/layouts';
import { useLogout } from '@app/hooks/auth/useLogout';
import { AcceptPolicies, useToast } from '@stenngroup/ui-kit';
import { TLocale } from '@stenngroup/ui-kit/internationalization/types';

export const AcceptPoliciesContainer: FC = () => {
  const toast = useToast();
  const { formatMessage, locale } = useIntl();
  const { data: profile } = useGetProfile();
  const { handleLogout } = useLogout();
  const queryClient = useQueryClient();
  const httpClient = useHttpClient();
  const acceptPolicyMutation = useMutation({
    mutationFn: () => httpClient(apiRoutes.SecurityAcceptLegalPoliciesAcceptLegalPolicies, 'get', {}),
    onSuccess: () => {
      queryClient.refetchQueries([apiRoutes.Profile]);
    },
    onError: () => {
      toast.showError(
        formatMessage({
          id: 'constants.universalMessages.somethingWentWrongSupportTeamAlreadyNotified',
        }),
        {
          testId: TEST_IDS.acceptPoliciesRequestError,
        }
      );
    },
  });

  if (!profile) return null;

  if (profile.hasAcceptedLegalPolicies) return null;

  return (
    <Nova2ThemeWrapper>
      <AcceptPolicies
        locale={locale as Exclude<TLocale, 'zh-Hant'>}
        onAgreeClick={(): void => {
          acceptPolicyMutation.mutate();
        }}
        onLogOutClick={(): void => {
          handleLogout();
        }}
        isAgreeButtonLoading={acceptPolicyMutation.isLoading}
      />
    </Nova2ThemeWrapper>
  );
};
