import { TAvailableDocumentTypes } from '@app/components/features/FlowTransitions/screens/DocumentsRequired/types';
import { TOperationsContractsDtoDocumentType } from '@app/core/__generated__';

export const isDealFlowRequiredDocument = (
  documentType: TOperationsContractsDtoDocumentType
): documentType is TAvailableDocumentTypes => {
  /**
   * @see https://stennteam.slack.com/archives/C07DHGZV5FA/p1727787288399049?thread_ts=1727783984.687769&cid=C07DHGZV5FA
   */
  const blackList: TOperationsContractsDtoDocumentType[] = [
    'AcceptanceAct',
    'Exhibit',
    'FinancialStatement',
    'Notice',
    'PaymentTransactionsEntry',
    'TradeHistory',
  ];

  return !blackList.includes(documentType);
};
