import { ELocalStorageKey } from '@app/storage-keys';
import { EDevFeature } from '../types';

export const getDevFeaturesFromStorage = (): Record<EDevFeature, boolean> => {
  const fallback = {
    [EDevFeature.InvitationFlow]: false,
  } as Record<EDevFeature, boolean>;
  const jsonString = localStorage.getItem(ELocalStorageKey.DevFeatures);
  if (!jsonString) return fallback;
  try {
    return JSON.parse(jsonString);
  } catch {
    return fallback;
  }
};
