import { FC, useEffect } from 'react';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { CriticalErrorLayout } from '@app/components/layouts/CriticalErrorLayout';
import { DevFeatureToggleContainer } from '@app/components/ui/DevFeatureToggleContainer';
import { AuthProviderWrapper } from '@app/context/AuthProviderWrapper';
import { LangContextProvider } from '@app/context/LangContext/LangContextProvider';
import { useLangContext } from '@app/context/LangContext/hooks/useLangContext';
import NotificationContext from '@app/context/NotificationContext';
import { QueryClientProviderWrapper } from '@app/context/QueryClientProvider';
import SignalrProvider from '@app/context/signalr/SignalrProvider';
import { HubSpotAnalyticsListener } from '@app/core/analytics/HubSpotAnalyticsListener';
import { store } from '@app/core/global-store';
import { IntlWrapperV2 } from '@app/core/internationalization';
import { useSetAnalitycsIdentity } from '@app/hooks/useSetAnalyticsIdentity';
import { Routes } from '@app/routes';
import { HttpClientError } from '@app/utils/errors';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { CircularProgress, Stack } from '@mui/material';
import { ErrorBoundary } from '@sentry/react';
import { useAuth } from '@stenngroup/auth0-sdk';
import { ResetStylesNew } from '@stenngroup/theme';
import { ConfigProvider, ToastManager as StennToastManager } from '@stenngroup/ui-kit';

import { useFinishRegistration } from '@app/api/hooks/useFinishRegistration';
import { useGetProfile } from '@app/api/hooks/useGetProfile';
import { useConfiguredFetch } from '@app/api/lib/useConfiguredFetch';
import { CookiesConsentWrapper } from '@app/components/ui/CookiesConsentWrapper';
import { ApiProvider } from '@app/core/api';
import { AcceptPoliciesContainer } from './components/features/AcceptPoliciesContainer';

const AppInner: FC = () => {
  const { isLoading: isAuthLoading, isAuthenticated, userInfo } = useAuth();
  const { data: profile, isError, error, isLoading } = useGetProfile();
  const configuredFetch = useConfiguredFetch();
  const { mutateAsync: finishRegistration, isLoading: isFinishRegistrationLoading } = useFinishRegistration();

  useSetAnalitycsIdentity();

  useEffect(() => {
    ApiProvider.setConfiguredFetch(configuredFetch);
  }, []);

  const shouldFinishRegistration = profile?.isEmailVerified && profile.needToFinishRegistration;

  useEffect(() => {
    if (shouldFinishRegistration && !isLoading) {
      finishRegistration();
    }
  }, [shouldFinishRegistration, finishRegistration, isLoading]);

  if (isAuthLoading || (isAuthenticated && !userInfo) || isFinishRegistrationLoading) {
    return (
      <Stack flexGrow={1} alignItems="center" justifyContent="center">
        <CircularProgress />
      </Stack>
    );
  }

  const errorStatus = (error as HttpClientError)?.status;
  if (isError && errorStatus !== 403) {
    return <CriticalErrorLayout errorCode={errorStatus} />;
  }

  return (
    <>
      <Routes />
      {isAuthenticated && <AcceptPoliciesContainer />}
      <CookiesConsentWrapper userId={profile?.userId} />
    </>
  );
};

const OuterApp: FC = () => {
  const { langId } = useLangContext();
  return (
    // TODO REMOVE REDUX PROVIDER AFTER MIGRATION
    <Provider store={store}>
      <ConfigProvider locale={langId} colorMode="light">
        <IntlWrapperV2>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ResetStylesNew />
            <StennToastManager>
              <QueryClientProviderWrapper>
                <NotificationContext.Provider value={{ isSignalrConnected: false }}>
                  <BrowserRouter>
                    <HubSpotAnalyticsListener>
                      <AuthProviderWrapper>
                        <ErrorBoundary fallback={<CriticalErrorLayout />}>
                          <SignalrProvider>
                            <DevFeatureToggleContainer />

                            <Stack height="100%" minWidth={320}>
                              <AppInner />
                            </Stack>
                          </SignalrProvider>
                        </ErrorBoundary>
                      </AuthProviderWrapper>
                    </HubSpotAnalyticsListener>
                  </BrowserRouter>
                </NotificationContext.Provider>
              </QueryClientProviderWrapper>
            </StennToastManager>
          </LocalizationProvider>
        </IntlWrapperV2>
      </ConfigProvider>
    </Provider>
  );
};

export const NovaApp: FC = () => {
  return (
    <LangContextProvider>
      <OuterApp />
    </LangContextProvider>
  );
};
