import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from 'react-query';

import { useHttpClient } from '@app/api/lib/useHttpClient';
import { useLangContext } from '@app/context/LangContext/hooks/useLangContext';
import { apiRoutes } from '@app/core/__generated__/apiRoutes';
import { HttpClientError } from '@app/utils/errors';
import { getDomainHeader } from '@app/utils/getDomainHeader';
import { useAuth } from '@stenngroup/auth0-sdk';

export function useFinishRegistration() {
  const { getAccessTokenSilently } = useAuth();
  const httpClient = useHttpClient();
  const queryClient = useQueryClient();
  const { langId } = useLangContext();

  return useMutation({
    mutationFn: () =>
      httpClient(apiRoutes.SecurityFinishRegistration, 'post', {
        data: {
          region: getDomainHeader(),
          lang: langId,
        },
      }),
    onSuccess: async () => {
      queryClient.refetchQueries([apiRoutes.Profile]);
      await getAccessTokenSilently({ force: true });
    },
    onError: (error: unknown) => {
      if (error instanceof HttpClientError) {
        captureException(error, {
          extra: error.toObject(),
          fingerprint: error.getFingerprint(),
        });
      }
    },
    retry: 2,
  });
}
