import { FC } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { SearchCompanyForm } from '@app/components/features/SearchCompanyForm';
import { getFormData } from '@app/components/features/SearchCompanyForm/helpers/getFormData';
import { ISearchCompanyForm } from '@app/components/features/SearchCompanyForm/types';
import { confirmCompanySchema } from '@app/components/features/SelectCompany/DialogsContainer/ConfirmCompanyDialogContent/schema';
import { IConfirmCompanyDialogContentProps } from '@app/components/features/SelectCompany/DialogsContainer/ConfirmCompanyDialogContent/types';
import { useApplicationState } from '@app/context/ApplicationStateContext/hooks/useApplicationState';
import { TEST_IDS } from '@app/core/constants';
import { yupResolver } from '@hookform/resolvers/yup';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { DialogTitle, Stack } from '@mui/material';
import { Button, DialogActions, DialogContent, Typography } from '@stenngroup/ui-kit';

export const ConfirmCompanyDialogContent: FC<IConfirmCompanyDialogContentProps> = ({
  onCancelClick,
  onNextClick,
  isDunsRequired,
  defaultFormValues,
}) => {
  const intl = useIntl();

  const { isReadOnly } = useApplicationState();

  const form = useForm<ISearchCompanyForm>({
    mode: 'onSubmit',
    defaultValues: getFormData(defaultFormValues),
    resolver: yupResolver(
      confirmCompanySchema({
        formatMessage: intl.formatMessage,
        isDunsRequired,
      })
    ),
    reValidateMode: 'onChange',
  });

  const handleFormSubmit = form.handleSubmit((data) => {
    onNextClick(data);
  });

  return (
    <>
      <DialogTitle
        sx={{
          py: 1,
        }}
        width="100%"
        alignItems="center"
      >
        <Typography.H5 color={(t) => t.palette.text.primary}>
          <FormattedMessage id="components.NotListedCompany.confirmCompanyDetails" />
        </Typography.H5>
      </DialogTitle>
      <DialogContent
        sx={{
          width: '100%',
        }}
      >
        <Stack gap={3} width="100%">
          <Typography.Body1>
            <FormattedMessage id="components.NotListedCompany.addCompanyDetailsDescription" />
          </Typography.Body1>
          <SearchCompanyForm form={form} dunsField={isDunsRequired ? 'required' : undefined} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack gap={2} direction="row">
          <Button variant="tertiary" size="medium" data-testid={TEST_IDS.acceptButton} onClick={onCancelClick}>
            <FormattedMessage id="constants.universalMessages.cancel" />
          </Button>
          <Button
            variant="primary"
            size="medium"
            data-testid={TEST_IDS.nextButton}
            onClick={handleFormSubmit}
            endIcon={<ArrowForwardIcon />}
            disabled={isReadOnly}
          >
            <FormattedMessage id="constants.universalMessages.next" />
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
};
