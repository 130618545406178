import { ComponentType, FC, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import { useApplicationState } from '@app/context/ApplicationStateContext/hooks/useApplicationState';
import { routes } from '@app/routes/config';

export const withEmailVerified = <P extends object>(Component: ComponentType<P>): FC<P> => {
  return function WithEmailVerified(props: P): ReactElement | null {
    const { isEmailVerified, authProvider } = useApplicationState();

    if (!isEmailVerified && authProvider === 'local') {
      return <Navigate to={routes.auth.emailConfirmation.path} />;
    }

    /**
     * TODO consider refactoring due to automatic email verification using Social Auth
     */
    if (!isEmailVerified && (authProvider === 'google' || authProvider === 'facebook')) {
      return <Navigate to={routes.auth.signUp.socialAccountActivation.path} />;
    }

    return <Component {...props} />;
  };
};
