import { IntlFormatters } from 'react-intl';

import { IContactForm } from '@app/components/layouts/DashboardLayout/Header/PersonalSpace/sections/Team/types';
import { REGEXP } from '@app/core/constants';
import { isPhoneNumberNotLong, isPhoneNumberTooShort, isPhoneNumberWithPrefix } from '@app/core/utilities';
import * as yup from 'yup';

export const contactSchema = (formatMessage: IntlFormatters['formatMessage']) =>
  yup.object().shape<Record<keyof Omit<IContactForm, 'phoneNumber'>, yup.AnySchema>>({
    firstName: yup
      .string()
      .trim()
      .matches(RegExp(REGEXP.userName), formatMessage({ id: 'constants.frontendErrors.enterValidFirstName' }))
      .matches(RegExp(REGEXP.validName), formatMessage({ id: 'constants.frontendErrors.enterValidFirstName' }))
      .max(40)
      .required(formatMessage({ id: 'constants.frontendErrors.enterName' })),
    lastName: yup
      .string()
      .trim()
      .matches(RegExp(REGEXP.userName), formatMessage({ id: 'constants.frontendErrors.enterValidLastName' }))
      .matches(RegExp(REGEXP.validName), formatMessage({ id: 'constants.frontendErrors.enterValidLastName' }))
      .max(40)
      .required(formatMessage({ id: 'constants.frontendErrors.enterLastName' })),
    email: yup
      .string()
      .matches(RegExp(REGEXP.email), formatMessage({ id: 'constants.frontendErrors.enterValidEmail' }))
      .email(formatMessage({ id: 'constants.frontendErrors.enterValidEmail' }))
      .required(),
    position: yup.string().required(formatMessage({ id: 'constants.frontendErrors.enterPosition' })),
    internalPhoneNumber: yup
      .string()
      .optional()
      .test(
        'isPhoneNumberWithPrefix',
        formatMessage({ id: 'constants.frontendErrors.invalidPhoneNumber' }),
        isPhoneNumberWithPrefix
      )
      .test(
        'isPhoneNumberTooShort',
        formatMessage({
          id: 'constants.frontendErrors.phoneNumberIsTooShort',
        }),
        isPhoneNumberTooShort
      )
      .test(
        'isPhoneNumberTooLong',
        formatMessage({
          id: 'constants.frontendErrors.phoneNumberIsTooLong',
        }),
        isPhoneNumberNotLong
      ),
  });
