import { useQuery } from 'react-query';

import { ApiProvider, BASE_API_URLS } from '@app/core/api';
import { useAuth } from '@stenngroup/auth0-sdk';

enum EAvailableFunctionsRequestKey {
  GetAvailableFunctions = 'getAvailableFunctions',
}

const getAvailableFunctionsRequest = () => {
  const requestOptions = {
    method: 'GET',
  };

  return ApiProvider.apiRequest<string[]>(
    `${BASE_API_URLS.onboarding}/api/v1.2/Security/GetAvailableFunctions`,
    requestOptions
  ).then((response) => {
    return response;
  });
};

export const useGetAvailableFunctions = () => {
  const { isAuthenticated, userInfo } = useAuth();

  return useQuery({
    queryKey: EAvailableFunctionsRequestKey.GetAvailableFunctions,
    queryFn: getAvailableFunctionsRequest,
    cacheTime: 5000,
    staleTime: 5000,
    enabled: isAuthenticated && !!userInfo,
  });
};
