export const formatCurrency = (
  amount: number,
  params?: {
    locale?: Intl.LocalesArgument;
    maximumFractionDigits?: number;
    minimumFractionDigits?: number;
    currency?: string;
    notation?: 'standard' | 'scientific' | 'engineering' | 'compact';
  }
): string => {
  const {
    locale = 'en-US',
    maximumFractionDigits = 2,
    minimumFractionDigits = 2,
    currency = 'USD',
    notation = 'standard',
  } = params ?? {};

  return amount.toLocaleString(locale, {
    style: 'currency',
    maximumFractionDigits,
    minimumFractionDigits,
    currency: currency ?? 'USD',
    currencyDisplay: 'narrowSymbol',
    notation,
  });
};
