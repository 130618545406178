import { FC } from 'react';

import { FormattedMessage } from 'react-intl';
import { useMutation, useQuery } from 'react-query';

import { useHttpClient } from '@app/api/lib/useHttpClient';
import { StepContainer } from '@app/components/features/StepContainer';
import { EFlowStep } from '@app/context/FlowTransitionsContext';
import { apiRoutes } from '@app/core/__generated__/apiRoutes';
import { useFlowTransitionsContext } from '@app/hooks/useFlowTransitionsContext';
import { formatAddress } from '@app/utils/formatAddress';
import { ArrowForward, ErrorOutlineRounded as ErrorOutlineRoundedIcon, Loop } from '@mui/icons-material';
import { Alert } from '@mui/material';
import { Button, Card, Divider, Typography } from '@stenngroup/ui-kit';
import { match } from 'ts-pattern';

import { TAddCounterpartyCreateRequestPayload } from '@app/core/__generated__';
import { TEST_IDS } from '@app/core/constants';
import { sleep } from '@stenngroup/utilities';
import { CompanyDetails } from './CompanyDetails';
import { RequestLimitForm } from './RequestLimitForm';

interface IRequestLimitProps {}

export const RequestLimit: FC<IRequestLimitProps> = () => {
  const { handleNextStep, getStateByStep } = useFlowTransitionsContext();
  const httpClient = useHttpClient();

  const prevStepData = getStateByStep<TAddCounterpartyCreateRequestPayload & { counterpartyCompanyId: string }>(
    EFlowStep.SELECT_NEW_TRADE_PARTNER
  );

  const query = useQuery({
    queryKey: [apiRoutes.BuyerGetBuyer, prevStepData.counterpartyCompanyId],
    queryFn: ({ signal }) =>
      httpClient(apiRoutes.BuyerGetBuyer, 'post', {
        signal,
        data: {
          id: prevStepData.counterpartyCompanyId,
        },
      }),
  });

  const { mutate: requestLimit, isLoading: isSubmitting } = useMutation({
    mutationFn: (limit: number) =>
      httpClient(apiRoutes.LimitRequestLimit, 'post', {
        data: {
          amount: limit,
          buyerCompanyId: prevStepData.counterpartyCompanyId,
        },
      }),
    onSuccess: async () => {
      // TODO: hack because of backend replication delay on backend side
      await sleep(2000);
      handleNextStep();
    },
  });

  const handleSubmit = (limit: number | undefined): void => {
    const totalLimit = query.data?.totalLimit ?? 0;

    if (limit && limit > totalLimit) {
      requestLimit(limit);
    } else {
      handleNextStep();
    }
  };

  return (
    <StepContainer
      title={<FormattedMessage id="createDeal.requestLimit" />}
      actions={
        <Button
          variant="primary"
          type="submit"
          form="limitForm"
          endIcon={<ArrowForward />}
          disabled={isSubmitting || query.status === 'loading'}
          isLoading={isSubmitting}
          data-testid={TEST_IDS.submitButton}
        >
          <FormattedMessage id="constants.universalMessages.next" />
        </Button>
      }
    >
      {match(query)
        .with({ status: 'error' }, () => (
          <Alert
            color="error"
            icon={<ErrorOutlineRoundedIcon />}
            action={
              <Button size="small" variant="tertiary" endIcon={<Loop />} onClick={() => query.refetch()}>
                <FormattedMessage id="constants.universalMessages.tryAgain" />
              </Button>
            }
          >
            <Typography.Body1>
              <FormattedMessage id="constants.universalMessages.somethingWentWrong" />
            </Typography.Body1>
          </Alert>
        ))
        .otherwise(() => (
          <Card
            data-testid={TEST_IDS.requestLimitScreen}
            sx={(theme) => ({
              padding: 2,
              boxShadow: 'none',
              border: `1px solid ${theme.palette.grey['200']}`,
              borderRadius: theme.shape.borderRadius / 2,
            })}
          >
            <CompanyDetails
              name={query.data?.counterpartyCompanyName || prevStepData.name || ''}
              address={formatAddress({
                street: query.data?.address?.street || prevStepData?.address?.street,
                state: query.data?.address?.state || prevStepData?.address?.state,
                city: query.data?.address?.city || prevStepData?.address?.city,
                postalCode: query.data?.address?.postalCode || prevStepData?.address?.postalCode,
                countryCode: query.data?.address?.countryName || prevStepData?.address?.country,
              })}
              duns={query.data?.counterpartyDuns || prevStepData?.duns || ''}
              queryStatus={query.status}
            />

            <Divider sx={{ borderWidth: 1, marginBlock: 1.5 }} />

            <RequestLimitForm
              status={query.data?.status}
              totalLimit={query.data?.totalLimit ?? 0}
              requestedLimit={query.data?.requestedLimit ?? 0}
              onSubmit={handleSubmit}
              key={query.status === 'loading' ? 'loading' : 'not-loading'}
            />
          </Card>
        ))}
    </StepContainer>
  );
};
