import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { TAvailableDocumentTypes } from './types';

/**
 * TODO consider refactoring in accordance with new requirements
 * @see https://stennteam.slack.com/archives/C07DHGZV5FA/p1727787288399049?thread_ts=1727783984.687769&cid=C07DHGZV5FA
 */
export const titles: Record<TAvailableDocumentTypes, ReactNode> = {
  PurchaseOrder: <FormattedMessage id="createDeal.documentsRequired.form.purchaseOrder.title" />,
  PackingList: <FormattedMessage id="createDeal.documentsRequired.form.packingList.title" />,
  CommercialInvoice: <FormattedMessage id="createDeal.documentsRequired.form.commercialInvoice.title" />,
  TransportDocument: <FormattedMessage id="createDeal.documentsRequired.form.transportDocument.title" />,
  CertificateOfOrigin: <FormattedMessage id="createDeal.documentsRequired.form.certificateOfOrigin.title" />,
  ShippingBill: <FormattedMessage id="createDeal.documentsRequired.form.shippingBill.title" />,
  Contract: <FormattedMessage id="createDeal.documentsRequired.form.contract.title" />,
  Other: <FormattedMessage id="createDeal.documentsRequired.form.other.title" />,
  OtherFromClient: <FormattedMessage id="createDeal.documentsRequired.form.other.title" />,
};

export const messages: Record<TAvailableDocumentTypes, ReactNode> = {
  PurchaseOrder: <FormattedMessage id="createDeal.documentsRequired.form.purchaseOrder.subTitle" />,
  PackingList: <FormattedMessage id="createDeal.documentsRequired.form.packingList.subTitle" />,
  CommercialInvoice: <FormattedMessage id="createDeal.documentsRequired.form.commercialInvoice.subTitle" />,
  TransportDocument: <FormattedMessage id="createDeal.documentsRequired.form.transportDocument.subTitle" />,
  CertificateOfOrigin: <FormattedMessage id="createDeal.documentsRequired.form.certificateOfOrigin.subTitle" />,
  ShippingBill: <FormattedMessage id="createDeal.documentsRequired.form.shippingBill.subTitle" />,
  Contract: <FormattedMessage id="createDeal.documentsRequired.form.contract.subTitle" />,
  Other: <FormattedMessage id="createDeal.documentsRequired.form.other.subTitle" />,
  OtherFromClient: <FormattedMessage id="createDeal.documentsRequired.form.other.subTitle" />,
};
