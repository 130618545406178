import { FC, useCallback } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import { StepContainer } from '@app/components/features/StepContainer';
import { useFlowTransitionsContext } from '@app/hooks/useFlowTransitionsContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { Card } from '@stenngroup/ui-kit';
import { FormProvider, useForm } from 'react-hook-form';
import { InviteTradePartnerForm } from './InviteTradePartnerForm';
import { getSchema } from './schema';
import { IInviteTradePartnerForm } from './types';

interface IInviteTradePartnerProps {}
export const InviteTradePartner: FC<IInviteTradePartnerProps> = () => {
  const { handleNextStep } = useFlowTransitionsContext();

  const { formatMessage } = useIntl();

  const form = useForm<IInviteTradePartnerForm>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(getSchema(formatMessage)),
  });

  const handleFormSubmit = useCallback(() => {
    form.handleSubmit((data) => {
      setTimeout(() => {
        console.log(data);
        handleNextStep({ stepState: form.getValues() });
      }, 500);
    })();
  }, [form, handleNextStep]);

  return (
    <StepContainer
      title={<FormattedMessage id="createDeal.inviteTradePartner" />}
      canGoBack={false}
      showActions={false}
    >
      <Card
        sx={(theme) => ({
          padding: 2,
          boxShadow: 'none',
          border: `1px solid ${theme.palette.grey['200']}`,
          borderRadius: theme.shape.borderRadius / 2,
        })}
      >
        <FormProvider {...form}>
          <InviteTradePartnerForm handleFormSubmit={handleFormSubmit} />
        </FormProvider>
      </Card>
    </StepContainer>
  );
};
