import { BASE_API_URLS } from '@app/core/api';
import { HttpClientError } from '@app/utils/errors';

export interface ICompanySignatoryInfoView {
  infoAboutMe: {
    firstName: string;
    lastName: string;
    position: string;
    corporateEmail: {
      emailAddress: string;
      emailVerified: true;
    };
  };
  anotherPerson: {
    firstName: string;
    lastName: string;
    position: string;
    corporateEmail: {
      emailAddress: string;
      emailVerified: true;
    };
  };
  personalEmailAsWorkEmail: true;
  passedFlow: any;
  companyInfo: {
    website: string;
  };
  isSuccess: boolean;
  error: {
    message: string;
    strCode: string;
  };
}

interface ICompanySignatoryInfoViewPayload {
  configuredFetch: typeof fetch;
}

export const getCompanySignatoryInfoView = async ({ configuredFetch }: ICompanySignatoryInfoViewPayload) => {
  const response = await configuredFetch(
    `${BASE_API_URLS.onboarding}/api/v1.2/CompanySignatory/GetCompanySignatoryInfoView`,
    { method: 'GET', headers: { 'Content-Type': 'application/json' } }
  );

  if (!response.ok) {
    throw await HttpClientError.fromResponse(response);
  }

  return response.json();
};
