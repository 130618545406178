import { type FC } from 'react';

import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Button, FormTextField, Stack, Typography } from '@stenngroup/ui-kit';

import { TEST_IDS } from '@app/core/constants';
import { IInviteTradePartnerForm } from '../types';

interface IProps {
  disabled?: boolean;
  handleFormSubmit: () => void;
}

export const InviteTradePartnerForm: FC<IProps> = ({ disabled, handleFormSubmit }) => {
  const form = useFormContext<IInviteTradePartnerForm>();

  const { control } = form;

  // TODO: Replace with actual loading state
  const isLoading = false;

  return (
    <form>
      <Stack spacing={3}>
        <Typography.TextMd.Medium>
          <FormattedMessage id="createDeal.inviteTradePartner.form.description" />
        </Typography.TextMd.Medium>

        <Stack spacing={2}>
          <FormTextField
            name="firstName"
            control={control}
            label={<FormattedMessage id="createDeal.inviteTradePartner.form.firstNameField" />}
            disabled={disabled}
          />
          <FormTextField
            name="lastName"
            control={control}
            label={<FormattedMessage id="createDeal.inviteTradePartner.form.lastNameField" />}
            disabled={disabled}
          />
          <FormTextField
            name="email"
            control={control}
            label={<FormattedMessage id="createDeal.inviteTradePartner.form.emailField" />}
            disabled={disabled}
          />

          <Button
            disabled={isLoading}
            isLoading={isLoading}
            onClick={handleFormSubmit}
            variant="secondary"
            size="small"
            data-testid={TEST_IDS.submitButton}
          >
            <FormattedMessage id="createDeal.inviteTradePartner.form.sendInvite" />
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};
