import { IDocumentsRequiredForm } from '../types';

export function getFormData(): IDocumentsRequiredForm {
  return {
    PackingList: null,
    PurchaseOrder: null,
    CommercialInvoice: null,
    TransportDocument: null,
    CertificateOfOrigin: null,
    ShippingBill: null,
    Contract: null,
    Other: null,
    OtherFromClient: null,
  };
}
