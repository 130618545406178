import { FC, useMemo } from 'react';

import { FormattedMessage } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';

import { useHttpClient } from '@app/api/lib/useHttpClient';
import { StepContainer } from '@app/components/features/StepContainer';
import { TStennClientsServiceApplicationOperationsModelsSubmitDealRequest } from '@app/core/__generated__';
import { apiRoutes } from '@app/core/__generated__/apiRoutes';
import { formatDealData } from '@app/core/features/mappers';
import { useFlowTransitionsContext } from '@app/hooks/useFlowTransitionsContext';
import { Button, Card } from '@stenngroup/ui-kit';

import { TEST_IDS } from '@app/core/constants';
import { useGetDealById } from '@app/hooks/useGetDealById';
import { sleep } from '@stenngroup/utilities';
import { ReviewDealCard } from './ReviewDealCard';

interface IReviewDealProps {}

export const ReviewDeal: FC<IReviewDealProps> = () => {
  const { handleNextStep, getFlowState } = useFlowTransitionsContext();

  const { tradeRole, dealId, trId } = getFlowState();

  const { data: dealData, status } = useGetDealById({ id: dealId, isActual: true });
  const formattedDealData = useMemo(() => formatDealData(dealData), [dealData]);
  const invoicesCount = dealData?.invoices?.length ?? 0;

  const queryClient = useQueryClient();

  const refetchSubmittedDeal = async () => {
    await sleep(2000);
    await queryClient.invalidateQueries({
      queryKey: [apiRoutes.DealsGetDeal, dealId, false],
    });
  };

  const httpClient = useHttpClient();
  const submitDealMutation = useMutation({
    mutationFn: (data: TStennClientsServiceApplicationOperationsModelsSubmitDealRequest) =>
      httpClient(apiRoutes.DealsSubmitDeal, 'post', {
        data,
      }),
    onSuccess: () => {
      refetchSubmittedDeal();
      handleNextStep();
    },
  });

  const handleSubmitDeal = () => {
    submitDealMutation.mutate({
      tradeRelationId: trId,
    });
  };

  return (
    <StepContainer
      actions={
        <Button
          variant="contained"
          size="medium"
          onClick={handleSubmitDeal}
          isLoading={submitDealMutation.isLoading}
          disabled={submitDealMutation.isLoading || !invoicesCount}
          data-testid={TEST_IDS.submitButton}
        >
          <FormattedMessage id="constants.universalMessages.submitDeal" />
        </Button>
      }
      title={<FormattedMessage id="createDeal.reviewDeal" />}
    >
      <Card
        sx={(theme) => ({
          padding: 2,
          boxShadow: 'none',
          border: `1px solid ${theme.palette.grey['200']}`,
          borderRadius: theme.shape.borderRadius / 2,
        })}
      >
        <ReviewDealCard
          invoicesCount={invoicesCount}
          tradeRole={tradeRole}
          totalInvoicesValue={formattedDealData.value}
          supplierReceives={formattedDealData.youWillReceive}
          firstPayment={formattedDealData.firstPayment}
          secondPayment={formattedDealData.secondPayment}
          totalFees={formattedDealData.fees}
          volumeAdjustmentFees={formattedDealData.volumeAdjustmentFees}
          insuranceCharges={formattedDealData.insuranceCharges}
          latePaymentFees={formattedDealData.latePaymentFees}
          currencyCode={formattedDealData.currencyCode || 'USD'}
          buyerName={formattedDealData.buyerName}
          supplierName={formattedDealData.supplierName}
          loadingState={status}
          discountFee={formattedDealData.discountTotal}
        />
      </Card>
    </StepContainer>
  );
};
