type TNonUndefined<T> = {
  [P in keyof T]: Exclude<T[P], undefined>;
};

const omitBy = <T extends object>(obj: T, check: (arg: unknown) => boolean): T => {
  obj = { ...obj };
  Object.entries(obj).forEach(([key, value]) => check(value) && delete obj[key as keyof T]);
  return obj;
};

export const omitEmpty = <T extends Record<string, string | undefined>>(obj: T): TNonUndefined<T> => {
  return omitBy(obj, (val) => val === undefined || val === null) as TNonUndefined<T>;
};

export const omitUndefined = <T extends Record<string, any>>(obj: T): TNonUndefined<T> => {
  return omitBy(obj, (val) => val === undefined) as TNonUndefined<T>;
};
