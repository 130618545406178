import { FC, ReactNode, useMemo } from 'react';

import { IApplicationStateContext } from '@app/context/ApplicationStateContext/types';
import { TStennClientsServiceApplicationPortModelsProfileInfo } from '@app/core/__generated__';

import { getIsOnboardingFlow } from '@app/context/ApplicationStateContext/helpers/getIsOnboardingFlow';
import { TUserInfo } from '@stenngroup/auth0-sdk/lib/types';
import { match } from 'ts-pattern';
import { ApplicationStateContext } from './ApplicationStateContext';

interface IApplicationStateContextProviderProps {
  children: ReactNode;
  profile: TStennClientsServiceApplicationPortModelsProfileInfo;
  userInfo: TUserInfo;
  availableFunctions: string[] | undefined;
}

export const ApplicationStateContextProvider: FC<IApplicationStateContextProviderProps> = ({
  children,
  profile,
  userInfo,
  availableFunctions,
}) => {
  const contextValue: IApplicationStateContext = useMemo(() => {
    return {
      userId: profile.userId,
      stennId: profile.userStennId,
      isEmailVerified: profile.isEmailVerified,
      authProvider: userInfo?.socialProvider ? userInfo.socialProvider : 'local',
      mainProduct: profile.mainProduct,
      hasBuyerRole: profile.hasBuyerRole,
      hasSupplierRole: profile.hasSupplierRole,
      email: profile.email,
      hasSigner: Boolean(profile.companyInfo?.companyHasSigner),
      hasBankDetails: Boolean(profile.companyInfo?.companyHasBankDetails),
      hasDuns: Boolean(profile.companyInfo?.duns),
      hasCompanyInfo: Boolean(profile.companyInfo),
      isAuthorisedSignatory: Boolean(profile.companyInfo?.forSign),
      isOnboardingFlow: getIsOnboardingFlow({
        hasBuyerRole: profile.hasBuyerRole,
        hasSupplierRole: profile.hasSupplierRole,
        isEmailVerified: profile.isEmailVerified,
      }),
      onboardingRole: profile.chosenCompanyRole,
      tradeRole: match<TStennClientsServiceApplicationPortModelsProfileInfo, IApplicationStateContext['tradeRole']>(
        profile
      )
        .with({ hasBuyerRole: true, hasSupplierRole: false }, () => 'Buyer')
        .with({ hasBuyerRole: false, hasSupplierRole: true }, () => 'Supplier')
        .otherwise(() => 'Unknown'),
      isReadOnly: availableFunctions === undefined ? false : !availableFunctions.includes('CustomerWriteAccess'),
    };
  }, [profile, userInfo?.socialProvider]);
  return <ApplicationStateContext.Provider value={contextValue}>{children}</ApplicationStateContext.Provider>;
};
