import { FC } from 'react';

import { TextSkeleton } from '@app/components/ui/TextSkeleton';
import { calculateTypographyHeight } from '@app/utils/calculateTypographyHeight';
import { Stack, useTheme } from '@mui/material';

import { InvoiceDetails } from '../InvoiceDetails';

export const InvoicesListSkeleton: FC = () => {
  const theme = useTheme();
  return (
    <Stack gap={2}>
      {Array.from({ length: 2 }).map((_, index) => (
        <InvoiceDetails
          key={index}
          isOverdue={false}
          onUpdate={() => void 0}
          trId="trId"
          invoiceId="id"
          invoiceNumber={
            <TextSkeleton
              status="loading"
              style={{
                height: calculateTypographyHeight(theme.typography.body2SemiBold),
              }}
            />
          }
          invoiceValue={
            <TextSkeleton
              status="loading"
              style={{
                height: calculateTypographyHeight(theme.typography.body2),
              }}
            />
          }
          dueDate={
            <TextSkeleton
              status="loading"
              style={{
                height: calculateTypographyHeight(theme.typography.body2),
              }}
            />
          }
        />
      ))}
    </Stack>
  );
};
