import { formatCurrency } from '@app/utils/formatCurrency';
import { Stack } from '@mui/material';
import { FC } from 'react';

interface IAmountTextProps {
  amount: number;
  isEstimated?: boolean;
  currency?: string;
}

export const AmountText: FC<IAmountTextProps> = ({ amount, isEstimated, currency }) => {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      {Boolean(isEstimated) && '~'}
      {formatCurrency(amount, { currency })}
    </Stack>
  );
};
