import { IntlFormatters } from 'react-intl';

import { EXPANDED_DATE_OPTIONS, NOTIFICATION_CODE_VALUES, NOTIFICATION_DATA_KEYS } from '@app/core/constants';
import { convertUTCDateToLocalDate, formatDate } from '@app/core/utilities';

import * as Sentry from '@sentry/react';
import messages from './messages';
import { INotification, INotificationData, INotificationsResponse } from './types';

const TRUE_VALUE_STRING = 'true';

interface IGenerateNotificationObj {
  notification: INotificationsResponse;
  message: string;
  viewMode?: boolean;
  route?: string;
}

const generateNotificationObj = ({ notification, message, viewMode = false, route }: IGenerateNotificationObj) => ({
  date: getFormattedNotificationDate(notification.notificationDate),
  id: notification.id,
  viewMode,
  route,
  message,
});

export const notificationTyping = (
  notification: INotificationsResponse,
  formatMessage: IntlFormatters['formatMessage']
): INotification | undefined => {
  if (notification.code.localeCompare(NOTIFICATION_CODE_VALUES.reps_invitation_has_been_accepted) === 0) {
    const currentValue: INotificationData | undefined = findNotificationDataByKey(
      notification.notificationData,
      NOTIFICATION_DATA_KEYS.fullName
    );
    const message = formatMessage(messages.repsAcceptedInvitation, {
      fullName: currentValue && currentValue.value,
    });
    return generateNotificationObj({ notification, message });
  }
  if (notification.code.localeCompare(NOTIFICATION_CODE_VALUES.default_notification) === 0) {
    const message = notification.body;
    return generateNotificationObj({ notification, message });
  }
  if (notification.code.localeCompare(NOTIFICATION_CODE_VALUES.reps_invitation_has_been_rejected) === 0) {
    const currentValue: INotificationData | undefined = findNotificationDataByKey(
      notification.notificationData,
      NOTIFICATION_DATA_KEYS.fullName
    );
    const message = formatMessage(messages.repsRejectedInvitation, {
      fullName: currentValue && currentValue.value,
    });
    return generateNotificationObj({ notification, message });
  }
  if (notification.code.localeCompare(NOTIFICATION_CODE_VALUES.reps_invitation_is_about_to_expire) === 0) {
    const currentValue: INotificationData | undefined = findNotificationDataByKey(
      notification.notificationData,
      NOTIFICATION_DATA_KEYS.fullName
    );
    const currentExpirationDate: INotificationData | undefined = notification.notificationData.find(
      (data: INotificationData) => data.key.localeCompare(NOTIFICATION_DATA_KEYS.expirationDate) === 0
    );
    const message = formatMessage(messages.repsInvitationisAboutToExpire, {
      fullName: currentValue && currentValue.value,
      expirationDate: currentExpirationDate && currentExpirationDate.value,
    });
    return generateNotificationObj({ notification, message });
  }
  if (notification.code.localeCompare(NOTIFICATION_CODE_VALUES.reps_invitation_is_expiring_today) === 0) {
    const currentValue: INotificationData | undefined = findNotificationDataByKey(
      notification.notificationData,
      NOTIFICATION_DATA_KEYS.fullName
    );
    const message = formatMessage(messages.repsInvitationisExpiringToday, {
      fullName: currentValue && currentValue.value,
    });
    return generateNotificationObj({ notification, message });
  }
  if (notification.code.localeCompare(NOTIFICATION_CODE_VALUES.reps_invitation_has_expired) === 0) {
    const currentValue: INotificationData | undefined = findNotificationDataByKey(
      notification.notificationData,
      NOTIFICATION_DATA_KEYS.fullName
    );
    const message = formatMessage(messages.repsInvitationHasExpired, {
      fullName: currentValue && currentValue.value,
    });
    return generateNotificationObj({ notification, message });
  }
  if (notification.code.localeCompare(NOTIFICATION_CODE_VALUES.reps_new_lessons) === 0) {
    const message = formatMessage(messages.repsNewLessons);
    return generateNotificationObj({ notification, message });
  }
  if (notification.code.localeCompare(NOTIFICATION_CODE_VALUES.reps_buyer_limit_has_been_changed) === 0) {
    const currentValue: INotificationData | undefined = findNotificationDataByKey(
      notification.notificationData,
      NOTIFICATION_DATA_KEYS.buyerName
    );
    const currentLimit: INotificationData | undefined = notification.notificationData.find(
      (data: INotificationData) => data.key.localeCompare(NOTIFICATION_DATA_KEYS.limitAmount) === 0
    );
    const message = formatMessage(messages.repsBuyerLimitChanged, {
      buyerName: currentValue && currentValue.value,
      limitAmount: currentLimit && currentLimit.value,
    });
    return generateNotificationObj({ notification, message });
  }
  if (notification.code.localeCompare(NOTIFICATION_CODE_VALUES.reps_buyer_marketing_limit_has_been_changed) === 0) {
    const currentValue: INotificationData | undefined = findNotificationDataByKey(
      notification.notificationData,
      NOTIFICATION_DATA_KEYS.buyerName
    );
    const currentLimit: INotificationData | undefined = notification.notificationData.find(
      (data: INotificationData) => data.key.localeCompare(NOTIFICATION_DATA_KEYS.limitAmount) === 0
    );
    const message = formatMessage(messages.repsBuyerMarketingLimitChanged, {
      buyerName: currentValue && currentValue.value,
      limitAmount: currentLimit && currentLimit.value,
    });
    return generateNotificationObj({ notification, message });
  }
  if (notification.code.localeCompare(NOTIFICATION_CODE_VALUES.reps_overdue_payment_after_30_days) === 0) {
    const currentValue: INotificationData | undefined = findNotificationDataByKey(
      notification.notificationData,
      NOTIFICATION_DATA_KEYS.invoiceNumber
    );
    const message = formatMessage(messages.repsOverduePayment30days, {
      invoiceNumber: currentValue && currentValue.value,
    });
    return generateNotificationObj({ notification, message });
  }
  if (notification.code.localeCompare(NOTIFICATION_CODE_VALUES.reps_overdue_payment_after_7_days) === 0) {
    const currentValue: INotificationData | undefined = findNotificationDataByKey(
      notification.notificationData,
      NOTIFICATION_DATA_KEYS.invoiceNumber
    );
    const message = formatMessage(messages.repsOverduePayment7days, {
      invoiceNumber: currentValue && currentValue.value,
    });
    return generateNotificationObj({ notification, message });
  }
  if (notification.code.localeCompare(NOTIFICATION_CODE_VALUES.reps_overdue_payment_on_the_due_date) === 0) {
    const currentValue: INotificationData | undefined = findNotificationDataByKey(
      notification.notificationData,
      NOTIFICATION_DATA_KEYS.invoiceNumber
    );
    const message = formatMessage(messages.repsOverduePaymentDueDate, {
      invoiceNumber: currentValue && currentValue.value,
    });
    return generateNotificationObj({ notification, message });
  }
  if (notification.code.localeCompare(NOTIFICATION_CODE_VALUES.reps_invoice_has_been_repaid) === 0) {
    const currentValue: INotificationData | undefined = findNotificationDataByKey(
      notification.notificationData,
      NOTIFICATION_DATA_KEYS.invoiceNumber
    );
    const message = formatMessage(messages.repsInvoiceRepaid, {
      invoiceNumber: currentValue && currentValue.value,
    });
    return generateNotificationObj({ notification, message });
  }
  if (notification.code.localeCompare(NOTIFICATION_CODE_VALUES.reps_deal_has_been_financed) === 0) {
    const currentValue: INotificationData | undefined = findNotificationDataByKey(
      notification.notificationData,
      NOTIFICATION_DATA_KEYS.dealNumber
    );
    const message = formatMessage(messages.repsDealFinanced, {
      dealNumber: currentValue && currentValue.value,
    });
    return generateNotificationObj({ notification, message });
  }
  if (notification.code.localeCompare(NOTIFICATION_CODE_VALUES.reps_documents_have_been_signed) === 0) {
    const currentValue: INotificationData | undefined = findNotificationDataByKey(
      notification.notificationData,
      NOTIFICATION_DATA_KEYS.dealNumber
    );
    const message = formatMessage(messages.repsDealSigned, {
      dealNumber: currentValue && currentValue.value,
    });
    return generateNotificationObj({ notification, message });
  }
  if (notification.code.localeCompare(NOTIFICATION_CODE_VALUES.reps_documents_have_been_sent_to_signatories) === 0) {
    const currentValue: INotificationData | undefined = findNotificationDataByKey(
      notification.notificationData,
      NOTIFICATION_DATA_KEYS.dealNumber
    );
    const dealId: INotificationData | undefined = findNotificationDataByKey(
      notification.notificationData,
      NOTIFICATION_DATA_KEYS.dealId
    );
    const message = formatMessage(messages.repsDocumentSentToSignatures, {
      dealNumber: currentValue && currentValue.value,
    });
    const viewModeSettings = dealId?.value
      ? {
          viewMode: true,
          route: `/deals/${dealId.value}`,
        }
      : {};
    return generateNotificationObj({
      notification,
      message,
      ...viewModeSettings,
    });
  }
  if (notification.code.localeCompare(NOTIFICATION_CODE_VALUES.reps_outgoing_payment) === 0) {
    const currentPaymentNumber: INotificationData | undefined = findNotificationDataByKey(
      notification.notificationData,
      NOTIFICATION_DATA_KEYS.paymentNumber
    );
    const currentSupplierName: INotificationData | undefined = notification.notificationData.find(
      (data: INotificationData) => data.key.localeCompare(NOTIFICATION_DATA_KEYS.supplierName) === 0
    );
    const currentCurrency: INotificationData | undefined = notification.notificationData.find(
      (data: INotificationData) => data.key.localeCompare(NOTIFICATION_DATA_KEYS.currency) === 0
    );
    const currentPaymentAmount: INotificationData | undefined = notification.notificationData.find(
      (data: INotificationData) => data.key.localeCompare(NOTIFICATION_DATA_KEYS.paymentAmount) === 0
    );
    const message = formatMessage(messages.repsOutgoingPayment, {
      paymentNumber: currentPaymentNumber && currentPaymentNumber.value,
      supplierName: currentSupplierName && currentSupplierName.value,
      currency: currentCurrency && currentCurrency.value,
      paymentAmount: currentPaymentAmount && currentPaymentAmount.value,
    });
    return generateNotificationObj({ notification, message });
  }

  if (notification.code.localeCompare(NOTIFICATION_CODE_VALUES.deal_attachment_is_awaiting_signing) === 0) {
    const currentValue: INotificationData | undefined = findNotificationDataByKey(notification.notificationData);

    return {
      date: getFormattedNotificationDate(notification.notificationDate),
      id: notification.id,
      route: `/deals/${notification.entityId}`,
      viewMode: true,
      message: formatMessage(messages.awaitingSigning, {
        name: currentValue && currentValue.value,
      }),
    };
  }

  if (
    notification.code.localeCompare(NOTIFICATION_CODE_VALUES.limit_change_minus) === 0 ||
    notification.code.localeCompare(NOTIFICATION_CODE_VALUES.limit_change_plus) === 0
  ) {
    const currentValue: INotificationData | undefined = findNotificationDataByKey(notification.notificationData);

    const currentLimit: INotificationData | undefined = notification.notificationData.find(
      (data: INotificationData) => data.key.localeCompare(NOTIFICATION_DATA_KEYS.newLimit) === 0
    );

    return {
      date: getFormattedNotificationDate(notification.notificationDate),
      id: notification.id,
      viewMode: false,
      message: formatMessage(messages.limitChange, {
        name: currentValue && currentValue.value,
        limit: currentLimit && currentLimit.value,
      }),
    };
  }

  if (notification.code.localeCompare(NOTIFICATION_CODE_VALUES.money_transferred) === 0) {
    const currentValue: INotificationData | undefined = findNotificationDataByKey(notification.notificationData);

    return {
      date: getFormattedNotificationDate(notification.notificationDate),
      id: notification.id,
      route: `/deals/${notification.entityId}`,
      viewMode: true,
      message: formatMessage(messages.moneyTransferred, {
        name: currentValue && currentValue.value,
      }),
    };
  }

  if (notification.code.localeCompare(NOTIFICATION_CODE_VALUES.invoice_repaid) === 0) {
    const currentValue: INotificationData | undefined = findNotificationDataByKey(notification.notificationData);

    return {
      date: getFormattedNotificationDate(notification.notificationDate),
      id: notification.id,
      route: `/invoices/${notification.entityId}`,
      viewMode: true,
      message: formatMessage(messages.invoiceRepaid, {
        name: currentValue && currentValue.value,
      }),
    };
  }

  if (notification.code.localeCompare(NOTIFICATION_CODE_VALUES.deal_review_needed) === 0) {
    const currentValue: INotificationData | undefined = findNotificationDataByKey(notification.notificationData);

    return {
      date: getFormattedNotificationDate(notification.notificationDate),
      id: notification.id,
      route: `/deals/${notification.entityId}`,
      viewMode: true,
      message: formatMessage(messages.dealReviewNeeded, {
        name: currentValue && currentValue.value,
      }),
    };
  }

  if (notification.code.localeCompare(NOTIFICATION_CODE_VALUES.invoices_overdue) === 0) {
    const currentValue: INotificationData | undefined = findNotificationDataByKey(notification.notificationData);

    return {
      date: getFormattedNotificationDate(notification.notificationDate),
      id: notification.id,
      route: `/invoices/${notification.entityId}`,
      viewMode: true,
      message: formatMessage(messages.invoiceOverdue, {
        name: currentValue && currentValue.value,
      }),
    };
  }

  if (notification.code.localeCompare(NOTIFICATION_CODE_VALUES.referral_updated) === 0) {
    const currentValue: INotificationData | undefined = findNotificationDataByKey(notification.notificationData);
    const isSupplierInvitationData = notification.notificationData.find(
      (data: INotificationData) => data.key.localeCompare(NOTIFICATION_DATA_KEYS.isSupplierInvitation) === 0
    );

    let linkUrl = '';

    if (isSupplierInvitationData?.value && isSupplierInvitationData?.value === TRUE_VALUE_STRING) {
      const supplierIdData = findNotificationDataByKey(
        notification.notificationData,
        NOTIFICATION_DATA_KEYS.supplierId
      );
      // @TODO ASK
      linkUrl = `/suppliers/${supplierIdData?.value}`;
    }

    const statusData = findNotificationDataByKey(notification.notificationData, NOTIFICATION_DATA_KEYS.status);

    const message = formatMessage(messages.referralUpdated, {
      name: currentValue && currentValue.value,
      status: statusData && statusData.value.toLowerCase(),
    });

    return {
      date: getFormattedNotificationDate(notification.notificationDate),
      id: notification.id,
      route: linkUrl,
      viewMode: true,
      message,
    };
  }

  if (notification.code.localeCompare(NOTIFICATION_CODE_VALUES.link_to_company_request_created) === 0) {
    const currentValue: INotificationData | undefined = findNotificationDataByKey(notification.notificationData);

    return {
      id: notification.id,
      date: getFormattedNotificationDate(notification.notificationDate),
      message: formatMessage(messages.linkToCompanyCreate, {
        name: currentValue && currentValue.value,
      }),
      route: '#personal-space',
      viewMode: true,
    };
  }

  if (notification.code.localeCompare(NOTIFICATION_CODE_VALUES.link_to_company_request_declined) === 0) {
    return {
      id: notification.id,
      date: getFormattedNotificationDate(notification.notificationDate),
      message: notification.body,
      viewMode: false,
    };
  }

  if (notification.code.localeCompare(NOTIFICATION_CODE_VALUES.link_to_company_request_approved) === 0) {
    return {
      id: notification.id,
      date: getFormattedNotificationDate(notification.notificationDate),
      message: notification.body,
      route: '#personal-space',
      viewMode: true,
    };
  }

  if (notification.code.localeCompare(NOTIFICATION_CODE_VALUES.communication_tool_request_created) === 0) {
    const dealId = findNotificationDataByKey(notification.notificationData, NOTIFICATION_DATA_KEYS.id);
    return {
      id: notification.id,
      date: getFormattedNotificationDate(notification.notificationDate),
      message: notification.body,
      route: `/deals/${dealId?.value}`,
      viewMode: true,
    };
  }

  Sentry.captureException(new Error(`Notification code ${JSON.stringify(notification)} not found`), {
    extra: {
      notification,
    },
  });
  return {
    id: notification.id,
    date: getFormattedNotificationDate(notification.notificationDate),
    message: notification.body ?? notification.title,
    viewMode: false,
  };
};

function findNotificationDataByKey(
  notificationData: INotificationData[],
  key: string = NOTIFICATION_DATA_KEYS.entityDisplayName
) {
  return notificationData.find((data: INotificationData) => data.key.localeCompare(key) === 0);
}

function getFormattedNotificationDate(notificationDate: string) {
  const convertedDate = convertUTCDateToLocalDate(notificationDate);

  return formatDate({ date: convertedDate, options: EXPANDED_DATE_OPTIONS });
}
