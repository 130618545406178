import { generatePath } from 'react-router-dom';

import { TApiPayloadTypes } from '@app/core/__generated__';
import { TApiRoutes, TRequestMethod } from '@app/core/httpClient/types';
import { TExtractRouteParams } from '@app/core/types/router';
import { HttpClientError } from '@app/utils/errors';

interface IOptions<M extends TRequestMethod, T extends TApiRoutes> extends Omit<RequestInit, 'body' | 'method'> {
  query?: TApiPayloadTypes[T][M]['requestQuery'];
  data?: TApiPayloadTypes[T][M]['requestBody'];
  routeParams?: TExtractRouteParams<T>;
}

export type THttpClient = <T extends TApiRoutes, M extends TRequestMethod>(
  path: T,
  method: M,
  options: IOptions<M, T>
) => Promise<TApiPayloadTypes[T][M]['response']>;

/**
 * @throws FetchError, HttpClientError
 */
export const createHttpClient =
  (configuredFetch: typeof fetch) =>
  async <T extends TApiRoutes, M extends TRequestMethod>(
    path: T,
    method: M,
    options: IOptions<M, T>
  ): Promise<TApiPayloadTypes[T][M]['response']> => {
    const baseURL = window._STENN_.API_CLIENTS_SERVICE_WEB;
    const apiUrl = new URL(baseURL + path);
    if (options.query) {
      Object.entries(options.query).forEach(([key, value]) => {
        apiUrl.searchParams.append(key, value as string);
      });
    }
    const fetchOptions: RequestInit = {
      ...options,
      method: method.toUpperCase(),
    };
    if (options.data instanceof FormData) {
      fetchOptions.body = options.data;
    } else if (options.data) {
      fetchOptions.body = JSON.stringify(options.data);
      fetchOptions.headers = {
        ...(fetchOptions.headers as Headers),
        'Content-Type': 'application/json',
      };
    }

    const pathname = generatePath(apiUrl.pathname, options.routeParams);
    apiUrl.pathname = pathname;
    const requestUrlWithSlash = apiUrl.toString();

    const response = await configuredFetch(requestUrlWithSlash, fetchOptions);
    if (response.ok) {
      if (response.headers.get('Content-Type')?.includes('application/json')) {
        return await response.json();
      }
      return await response.text();
    }

    throw await HttpClientError.fromResponse(response);
  };
