import { BASE_API_URLS } from '@app/core/api';
import { TUnionApiResponse } from '@app/types/apiResponse';

interface ISendInvitationPayload {
  configuredFetch: typeof fetch;
  payload: {
    contactId: string;
    email: string;
    fullName: string;
  };
}

const sendEmailRequest = async (configuredFetch: typeof fetch, invitationId: string) => {
  const response = await configuredFetch(
    `${BASE_API_URLS.onboarding}/api/v1.2/Invitations/sendInvitationEmail/${invitationId}/Contact/false`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  if (!response.ok) {
    return {
      type: 'error',
    };
  }
  return {
    type: 'success',
  };
};

export const sendInvitationRequest = async ({
  configuredFetch,
  payload,
}: ISendInvitationPayload): Promise<TUnionApiResponse<string>> => {
  const response = await configuredFetch(
    `${BASE_API_URLS.onboarding}/api/v1.2/Contacts/InviteContact/${payload.contactId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  if (!response.ok) {
    return {
      type: 'error',
    };
  }
  const invitationId = await response.json();
  const emailResponse = await sendEmailRequest(configuredFetch, invitationId);
  if (emailResponse.type === 'error') {
    return {
      type: 'error',
    };
  }

  return {
    type: 'success',
    data: invitationId,
  };
};
