import React, { FC } from 'react';

import { OTP_CODE_LENGTH, TEST_IDS } from '@app/core/constants';
import { Button, Dialog, FormOtpCodeField, Typography } from '@stenngroup/ui-kit';
import { FormattedMessage } from 'react-intl';

import { useConfiguredFetch } from '@app/api/lib/useConfiguredFetch';
import { EMAIL_OTP_TIMEOUT } from '@app/constants/auth/constants';
import { useToggle } from '@app/core/hooks';
import { useCountDown } from '@app/core/hooks/useCountDown';
import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { startVerifyCorporateContactDetailRequest } from './mutations/startVerifyCorporateContactDetailRequest';
import { verifyCodeAndBecomeSignerRequest } from './mutations/verifyCodeAndBecomeSignerRequest';
import { IOtpConfirmationFormProps } from './types';

const OtpConfirmationForm: FC<IOtpConfirmationFormProps> = ({ email, isOpen, onSubmit, onClose, contact }) => {
  const { control } = useForm();

  const configuredFetch = useConfiguredFetch();

  const { mutate: verifyCodeAndBecomeSigner, isLoading: isLoadingVerifyCodeAndBecomeSigner } = useMutation(
    (code: string) => verifyCodeAndBecomeSignerRequest({ code, email, configuredFetch, contact })
  );
  const [canResend, toggleResend] = useToggle(false);
  const [timeLeft, restartTimer] = useCountDown(EMAIL_OTP_TIMEOUT, toggleResend);

  const { mutate: resend, isLoading: isLoadingResend } = useMutation(
    () => startVerifyCorporateContactDetailRequest({ email, configuredFetch }),
    {
      onSuccess: () => {
        restartTimer();
        toggleResend();
      },
    }
  );

  const isLoading = isLoadingVerifyCodeAndBecomeSigner || isLoadingResend;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const code = e.target.value;
    if (code?.length === OTP_CODE_LENGTH) {
      verifyCodeAndBecomeSigner(code, {
        onSuccess: () => {
          onSubmit();
        },
      });
    }
  };

  return (
    <Dialog onClose={() => onClose(false)} open={isOpen} hasCloseIcon data-testid={TEST_IDS.otpDialog}>
      <Stack gap={3} py={1} px={5}>
        <Typography.H5>
          <FormattedMessage id="constants.universalMessages.otp.confirmEmail" />
        </Typography.H5>
        <Typography.Body1>
          <p>
            <FormattedMessage id="constants.universalMessages.otp.enterDigitCode" /> <strong>{email}</strong>.
          </p>
          <p>
            <FormattedMessage id="constants.universalMessages.otp.checkYourSpam" />
          </p>
        </Typography.Body1>
        <FormOtpCodeField
          control={control}
          name="otpCode"
          isLoading={isLoading}
          onChange={handleChange}
          defaultValue=""
          data-testid={TEST_IDS.otpCodeInput}
          disabled={isLoading}
          label={<FormattedMessage id="constants.universalMessages.sixDigitCode" />}
        />
        <Typography.Body1 color={(theme) => theme.palette.text.disabled}>
          {canResend ? (
            <Button onClick={() => resend()} data-testid={TEST_IDS.resendCodeButton} variant="text">
              <FormattedMessage id="constants.universalMessages.otp.resendCode" />
            </Button>
          ) : (
            <FormattedMessage id="constants.universalMessages.otp.youCanResend" values={{ timeLeft }} />
          )}
        </Typography.Body1>
      </Stack>
    </Dialog>
  );
};

export default OtpConfirmationForm;
