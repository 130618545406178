export enum ESupplierState {
  Available = 'Available',
  UnderReview = 'UnderReview',
  FinancingDenied = 'FinancingDenied',
  Unknown = 'Unknown',
}

export enum ESupplierStateFilter {
  All = 'All',
  UnderReview = 'UnderReview',
  Available = 'Available',
  FinancingDenied = 'FinancingDenied',
}

export enum ESupplierOrderBy {
  NameAsc = 'NameAsc',
  NameDesc = 'NameDesc',
}

interface ISupplierCompact {
  myCompanyName: string;
  myCompanyId: string;
  counterpartyCompanyName: string;
  counterpartyCompanyId: string;
  status: ESupplierState;
  tradeRelationId: string;
}

export interface ISuppliersList {
  companies: ISupplierCompact[];
  count: number;
}
