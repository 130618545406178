import { FC, useState } from 'react';

import { DeleteInvoiceDialogContent } from '@app/components/features/Invoice/DeleteInvoiceDialogContent';
import { EditInvoiceDialogContent } from '@app/components/features/Invoice/EditInvoiceDialogContent';
import { TEST_IDS } from '@app/core/constants';
import { ESearchParams } from '@app/types/searchParams';
import { Dialog } from '@stenngroup/ui-kit';
import { useParams, useSearchParams } from 'react-router-dom';
import { InvoiceListItem } from '../InvoiceListItem';
import { IInvoiceDetailsProps } from './types';

export const InvoiceDetails: FC<IInvoiceDetailsProps> = ({
  trId,
  invoiceId,
  invoiceNumber,
  invoiceValue,
  dueDate,
  isOverdue,
  error,
  onUpdate,
}) => {
  const { invoiceId: id } = useParams<{ invoiceId: string }>();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(
    () => id === invoiceId && searchParams.get(ESearchParams.EditInvoice) === 'true'
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const handleClose = () => {
    setIsOpenEditModal(false);
    setSearchParams();
  };

  const handleActionComplete = () => {
    setIsOpenEditModal(false);
    setSearchParams();
    onUpdate();
  };

  return (
    <>
      <InvoiceListItem
        invoiceNumber={invoiceNumber}
        invoiceValue={invoiceValue}
        dueDate={dueDate}
        onClickEditButton={() => setIsOpenEditModal(true)}
        onClickDeleteButton={() => setIsDeleteModalOpen(true)}
        isOverdue={isOverdue}
        error={error}
      />

      <Dialog
        open={isOpenEditModal}
        shouldCloseOnBackDropClick={false}
        data-testid={TEST_IDS.invoiceDetailsEditModal}
        fullWidth
        onClose={handleClose}
      >
        <EditInvoiceDialogContent
          trId={trId}
          invoiceId={invoiceId}
          onClose={handleClose}
          onSuccess={handleActionComplete}
        />
      </Dialog>

      <Dialog
        open={isDeleteModalOpen}
        shouldCloseOnBackDropClick={false}
        data-testid={TEST_IDS.invoiceDetailsDeleteModal}
        fullWidth
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <DeleteInvoiceDialogContent
          invoiceNumber={invoiceNumber}
          invoiceId={invoiceId}
          onClose={() => setIsDeleteModalOpen(false)}
          onDelete={handleActionComplete}
        />
      </Dialog>
    </>
  );
};
