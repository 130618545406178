import { useQueryClient } from 'react-query';

import { useAuth } from '@stenngroup/auth0-sdk';

export const useLogout = () => {
  const { logout } = useAuth();
  const queryClient = useQueryClient();

  const handleLogout = async () => {
    try {
      queryClient.removeQueries();
      await logout();
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  return { handleLogout };
};
