import { ComponentType, FC, ReactElement } from 'react';

import { useLocation } from 'react-router-dom';
import { Navigate, useSearchParams } from 'react-router-dom';

import { useGetAvailableFunctions } from '@app/api/hooks/useGetAvailableFunctions';
import { useGetProfile } from '@app/api/hooks/useGetProfile';
import { ApplicationStateContextProvider } from '@app/context/ApplicationStateContext/ApplicationStateContextProvider';
import { routes } from '@app/routes/config';
import { CircularProgress, Stack } from '@mui/material';
import { useAuth } from '@stenngroup/auth0-sdk';

export const withAuthenticated = <P extends object>(Component: ComponentType<P>): FC<P> => {
  return function WithAuthenticated(props: P): ReactElement | null {
    const { isAuthenticated, userInfo } = useAuth();
    const [searchParams] = useSearchParams();
    const { data: profile } = useGetProfile();
    const { data: availableFunctions } = useGetAvailableFunctions();
    const location = useLocation();
    if (!isAuthenticated) {
      searchParams.append('next', location.pathname);
      return <Navigate to={`${routes.auth.login.path}?${searchParams.toString()}`} />;
    }

    if (!userInfo || !profile) {
      return (
        <Stack flexGrow={1} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      );
    }

    return (
      <ApplicationStateContextProvider profile={profile} userInfo={userInfo} availableFunctions={availableFunctions}>
        <Component {...props} />
      </ApplicationStateContextProvider>
    );
  };
};
