import { ComponentType, FC, ReactElement } from 'react';

import { useApplicationState } from '@app/context/ApplicationStateContext/hooks/useApplicationState';
import { routes } from '@app/routes/config';
import { withExternalRedirect } from '@app/routes/hocs/withExternalRedirect';
import { Navigate } from 'react-router-dom';

export const withOnboardingNotRequired = <P extends object>(Component: ComponentType<P>): FC<P> => {
  return withExternalRedirect(function WithOnboardingNotRequired(props: P): ReactElement | null {
    const { isOnboardingFlow, onboardingRole } = useApplicationState();
    if (!isOnboardingFlow) {
      return <Navigate to={routes.dashboard.path} />;
    }
    if (onboardingRole && !location.pathname.includes(routes.addCompanyInformation.path)) {
      return <Navigate to={routes.addCompanyInformation.path} replace />;
    }
    return <Component {...props} />;
  });
};
