import { useConfiguredFetch } from '@app/api/lib/useConfiguredFetch';
import { BASE_API_URLS } from '@app/core/api/constants';
import { HttpClientError } from '@app/utils/errors';

interface IIsPublicEmailRequestPayload {
  email: string;
  configuredFetch: ReturnType<typeof useConfiguredFetch>;
}

export const isPublicEmailRequest = async ({ email, configuredFetch }: IIsPublicEmailRequestPayload) => {
  const response = await configuredFetch(
    `${BASE_API_URLS.onboarding}/api/v1.2/EmailValidation/IsEmailInPublicDomain/${email}`,
    {
      method: 'GET',
    }
  );
  if (!response.ok) {
    throw await HttpClientError.fromResponse(response);
  }
  return response.json();
};
