import { REGEXP, TOO_LONG_NUMBER, TOO_SHORT_NUMBER } from '@app/core/constants';
import isEmailValidator from 'validator/lib/isEmail';

export const hasNumber = (value: string) => new RegExp(REGEXP.number).test(value);

export const hasUpperCase = (value: string) => new RegExp(REGEXP.upperCase).test(value);

export const hasLowerCase = (value: string) => new RegExp(REGEXP.lowerCase).test(value);

export const isNotEmptyString = (value?: string) => typeof value === 'string' && value.trim().length > 0;

export const isEmail = (value: string) =>
  typeof value === 'string' && isEmailValidator(value) && new RegExp(REGEXP.email).test(value);

export const isEmailWithPlus = (value: string) =>
  typeof value === 'string' && isEmailValidator(value) && new RegExp(REGEXP.emailWithPlus).test(value);

export const isNotShortPassword = (value: string) => value.length >= 8;

export const isAllowedNameLength = (value: string) => value.length <= 40;

export const isValidBeneficiaryNameLength = (value: string): boolean => {
  const MAX_LENGTH = 35;
  return value.length <= MAX_LENGTH;
};

export const isPhoneNumberTooShort = (value?: string) =>
  value ? value.slice(1).trim().length >= TOO_SHORT_NUMBER : true;

export const isPhoneNumberNotLong = (value?: string) => (value ? value.slice(1).trim().length < TOO_LONG_NUMBER : true);

export const isPhoneNumberWithPrefix = (value?: string) =>
  value ? new RegExp(REGEXP.phoneNumberWithPrefix).test(value) : true;

export const isUserName = (value: string) => {
  const preparedValue = typeof value === 'string' ? value.trim() : value;

  const isNotEmptyValue = isNotEmptyString(preparedValue);
  const isValidUserName = new RegExp(REGEXP.userName).test(preparedValue);

  return isNotEmptyValue && isValidUserName;
};

export const isUnifiedSocialCreditCode = (value: string): boolean => /^[\u4e00-\u9fa5a-zA-Z0-9]{18}$/.test(value);

export const isChineseCompanyName = (value: string): boolean => {
  const regexp = /^([\s\u4e00-\u9fff])*$/g;
  return regexp.test(value);
};

/**
 * CIN is a 21 digits alpha-numeric code (eg, U12345AB6784CDE123456, additional info) and LLPIN a 7-digit alphanumeric (eg, AAA-7663)
 * @returns boolean
 */
export const isCin = (value: string): boolean => /^(?:[A-Z0-9]{21}|[A-Z]{3}-[A-Z0-9]{4})$/.test(value);

export const isValidName = (value: string): boolean => new RegExp(REGEXP.validName).test(value);

export const isValidPositiveNumber = (value: string | undefined = ''): boolean => {
  const parsedValue = parseFloat(value);
  const isNumber = !Number.isNaN(parsedValue);
  const isPositive = parsedValue > 0;
  const onlyNumbers = /^(\d{1,9})(\.\d{1,2})?$/.test(value);
  const isValidLength = value.length <= 16;
  return isNumber && isPositive && onlyNumbers && isValidLength;
};

export const isFutureDate = (value: string | null): boolean => {
  if (!value) {
    return false;
  }
  const now = new Date();
  const dueDate = new Date(value);
  return dueDate.getTime() >= now.getTime();
};
