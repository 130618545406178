import { FC, useMemo, useState } from 'react';

import { FormattedMessage } from 'react-intl';

import { useGetCompanySignatoryInfoView } from '@app/api/hooks/companySignatory';
import { useGetProfile } from '@app/api/hooks/useGetProfile';
import { useFlowTransitionsContext } from '@app/hooks/useFlowTransitionsContext';
import { Alert } from '@mui/material';

import { match } from 'ts-pattern';

import { EFlowStep } from '@app/context/FlowTransitionsContext';
import { AnotherPersonForm } from './Form/AnotherPersonForm';
import { AuthorizedSignatoryForm } from './Form/AuthorizedSignatoryForm';
import { ESignatoryType, IContactForm } from './types';

const DEFAULT_CORP_EMAIL = 'info@gmail.com';

export const AddCompanySignatory: FC = () => {
  const { getStateByStep } = useFlowTransitionsContext();

  const addCompanySignatoryState = getStateByStep<{
    form: IContactForm;
    signatoryType: ESignatoryType;
  } | null>(EFlowStep.ADD_COMPANY_SIGNATORY);

  const [signatoryType, setSignatoryType] = useState<ESignatoryType>(
    addCompanySignatoryState?.signatoryType || ESignatoryType.AUTHORISED_PERSON
  );

  const { data: profileInfo, refetch: profileRefetch } = useGetProfile();

  const workingEmail = profileInfo?.email || DEFAULT_CORP_EMAIL;

  const isSigner = !!profileInfo?.companyInfo?.forSign;

  const { data: companySignatoryInfo, error } = useGetCompanySignatoryInfoView();

  const isFormLoaded = !!profileInfo && !!companySignatoryInfo;

  const isCorp = profileInfo?.contactDetails?.some((contact) => contact.isCorporate && contact.activated) || isSigner;

  const defaultValues = useMemo(() => {
    return {
      firstName: companySignatoryInfo?.infoAboutMe?.firstName,
      lastName: companySignatoryInfo?.infoAboutMe?.lastName,
      position: companySignatoryInfo?.infoAboutMe?.position,
      website: companySignatoryInfo?.companyInfo?.website,
      email:
        (companySignatoryInfo?.personalEmailAsWorkEmail
          ? workingEmail
          : companySignatoryInfo?.infoAboutMe.corporateEmail?.emailAddress) || '',
    };
  }, [companySignatoryInfo, workingEmail]);

  if (error) {
    return (
      <Alert severity="error">
        <FormattedMessage id="constants.universalMessages.somethingWentWrongSupportTeamAlreadyNotified" />
      </Alert>
    );
  }

  // for re-render to set default values
  const key = isFormLoaded ? 'loaded' : 'not-loaded';

  return match(signatoryType)
    .with(ESignatoryType.AUTHORISED_PERSON, () => (
      <AuthorizedSignatoryForm
        defaultValues={defaultValues}
        isCorporate={isCorp}
        key={key}
        onSignatoryTypeChange={setSignatoryType}
        signatoryType={signatoryType}
        onRefetchProfile={profileRefetch}
      />
    ))
    .with(ESignatoryType.ANOTHER_PERSON, () => (
      <AnotherPersonForm
        key={key}
        onSignatoryTypeChange={setSignatoryType}
        signatoryType={signatoryType}
        onRefetchProfile={profileRefetch}
      />
    ))
    .exhaustive();
};
