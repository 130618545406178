import { TEST_IDS } from '@app/core/constants';
import { Logout as LogoutIcon } from '@mui/icons-material';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Button, StennLogo } from '@stenngroup/ui-kit';

import { useLogout } from '@app/hooks/auth/useLogout';
import { FC, ReactNode, createRef, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import artWork from './artwork.svg';

const TitleByState: Record<number, ReactNode> = {
  [1]: (
    <>
      <FormattedMessage id="layout.onboardingLayout.unlimitedFinance" />
      <br />
      <FormattedMessage id="layout.onboardingLayout.fast" />
    </>
  ),
  [2]: <FormattedMessage id="layout.onboardingLayout.90daysPaymentTerms" />,
  [3]: <FormattedMessage id="layout.onboardingLayout.banks" />,
  [4]: (
    <>
      &quot;
      <FormattedMessage id="layout.onboardingLayout.stennDelivers" />
      &quot;
    </>
  ),
  [5]: <FormattedMessage id="layout.onboardingLayout.financeThatMoves" />,
  [6]: <FormattedMessage id="layout.onboardingLayout.collateralFree" />,
};

const DescriptionByState: Record<number, ReactNode> = {
  [1]: <FormattedMessage id="layout.onboardingLayout.stennFunded" />,
  [2]: <FormattedMessage id="layout.onboardingLayout.getFunding" />,
  [3]: <FormattedMessage id="layout.onboardingLayout.fromCiti" />,
  [4]: <FormattedMessage id="layout.onboardingLayout.invoiceFinancing" />,
  [5]: <FormattedMessage id="layout.onboardingLayout.getAccess" />,
  [6]: <FormattedMessage id="layout.onboardingLayout.easyCapital" />,
};

export const SideBlock: FC<{ isLogoutButtonShown: boolean }> = (props) => {
  const { isLogoutButtonShown } = props;
  const { handleLogout } = useLogout();
  const [activeText, setActiveText] = useState(1);
  const theme = useTheme();
  // const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    let lastTime = Date.now();
    const interval = window.setInterval(() => {
      if (document.visibilityState !== 'visible') return;
      if (Date.now() - lastTime >= 15000) {
        setActiveText((prev) => (prev === Object.keys(TitleByState).length ? 1 : prev + 1));
        lastTime = Date.now();
      }
    }, 500);

    return () => window.clearInterval(interval);
  }, []);

  return (
    <Stack
      paddingY={2}
      sx={(t) => ({
        backgroundColor: '#011d24',
        backgroundImage: `url(${artWork})`,
        backgroundSize: 'cover',
        backgroundPositionY: '40%',
        [t.breakpoints.up('sm')]: {
          paddingY: 2,
        },
        [t.breakpoints.up('md')]: {
          flexGrow: 1,
          padding: 0,
        },
      })}
    >
      <Stack
        direction="row-reverse"
        alignItems="center"
        justifyContent="space-between"
        flexGrow={1}
        paddingX={5}
        gap={4}
        overflow="hidden"
        sx={(t) => ({
          [t.breakpoints.up('md')]: {
            padding: 0,
            alignItems: 'flex-start',
            flexDirection: 'column',
          },
        })}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-end"
          sx={(t) => ({
            order: 1,
            [t.breakpoints.up('md')]: {
              order: 0,
              paddingTop: 15,
              paddingX: 10,
              alignItems: 'flex-start',
              flexGrow: 1,
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
            },
          })}
        >
          <StennLogo invertColors size={isSmallScreen ? 'xsmall' : 'small'} />
        </Stack>

        <Stack
          display="none"
          alignSelf="center"
          flexGrow={1}
          gap={1.15}
          sx={(t) => ({
            [t.breakpoints.up('md')]: {
              display: 'flex',
              paddingX: 10,
            },
          })}
          width="100%"
          height={180}
          position="relative"
        >
          <TransitionGroup component={null}>
            {Array.from({ length: Object.keys(TitleByState).length }).map((_, index) => {
              if (index + 1 !== activeText) return null;
              const ref = createRef<HTMLDivElement>();
              return (
                <CSSTransition
                  key={index + 1}
                  classNames={{
                    enter: 'slideEnter',
                    enterActive: 'slideEnterActive',
                    exit: 'slideExit',
                    exitActive: 'slideExitActive',
                  }}
                  nodeRef={ref}
                  addEndListener={(done) => ref.current!.addEventListener('transitionend', done, false)}
                >
                  <Box
                    ref={ref}
                    position="absolute"
                    width="60%"
                    sx={{
                      '&.slideEnter': {
                        transform: 'translateX(200%)',
                      },
                      '&.slideEnterActive': {
                        transform: 'translateX(0)',
                        transition: 'transform 850ms ease-in-out',
                      },
                      '&.slideExit': {
                        transform: 'translateX(0)',
                      },
                      '&.slideExitActive': {
                        transform: 'translateX(-200%)',
                        transition: 'transform 850ms ease-in-out',
                      },
                    }}
                  >
                    <Stack flexGrow={1} gap={1.15}>
                      <Typography color={(t) => t.palette.common.white} variant="caption">
                        <FormattedMessage id="layout.onboardingLayout.invoiceFinance" />
                      </Typography>
                      <Typography color={(t) => t.palette.common.white} variant="h4">
                        {TitleByState[activeText]}
                      </Typography>
                      <Typography color={(t) => t.palette.common.white} variant="TextMd.Regular">
                        {DescriptionByState[activeText]}
                      </Typography>
                    </Stack>
                  </Box>
                </CSSTransition>
              );
            })}
          </TransitionGroup>
        </Stack>
        <Box
          sx={(t) => ({
            [t.breakpoints.up('md')]: {
              paddingX: 10,
              paddingBottom: 10,
            },
          })}
        >
          {isLogoutButtonShown && (
            <Button
              color="primary"
              variant="link"
              size={isSmallScreen ? 'xsmall' : 'small'}
              onClick={() => handleLogout()}
              data-testid={TEST_IDS.onboardingLayoutLogoutButton}
              startIcon={<LogoutIcon style={{ color: 'white' }} />}
              linkColor="white"
            >
              <FormattedMessage id="containers.Profile.profilePageLogoutButton" />
            </Button>
          )}
        </Box>
      </Stack>
    </Stack>
  );
};
