import { StepContainer } from '@app/components/features/StepContainer';
import { EFlowStep } from '@app/context/FlowTransitionsContext';
import { apiRoutes } from '@app/core/__generated__/apiRoutes';
import { formatDealData } from '@app/core/features/mappers';
import { useFlowTransitionsContext } from '@app/hooks/useFlowTransitionsContext';
import { useGetDealById } from '@app/hooks/useGetDealById';
import { useGetInvoiceById } from '@app/hooks/useGetInvoiceById';
import { InfoRounded } from '@mui/icons-material';
import { AlertCard, Card, Dialog, Stack, Typography } from '@stenngroup/ui-kit';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';
import { ConfirmationDialogContent } from './ConfirmationDialogContent';
import { DocumentsRequiredForm } from './DocumentsRequiredForm';
import { getFormData } from './helpers/getFormData';
import { getInvoicesDocuments } from './helpers/getInvoicesDocuments';
import { isDealFlowRequiredDocument } from './helpers/isDealFlowRequiredDocument';
import { updateInvoiceDocuments } from './helpers/updateInvoiceDTO';
import { useUpdateInvoice } from './mutations';
import { DocumentTypes, IDocumentType, IDocumentsRequiredForm } from './types';

export const DocumentsRequired: FC = () => {
  // TODO: find a way how to rerender Dropzone without taking the manual control on the files
  const [uploadKey, setUploadKey] = useState(0);
  const [isOpenSkipDialog, setIsOpenSkipDialog] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const { getFlowState, getStateByStep, handleNextStep } = useFlowTransitionsContext();
  const { dealId } = getFlowState();
  const state = getStateByStep<DocumentTypes | null>(EFlowStep.INVOICES_LIST);

  const { data: dealData } = useGetDealById({ id: dealId, isActual: true });

  /**
   * We should request invoice by the 1st invoice id from the current deal,
   * as all required documents will be attached to the first invoice in a deal
   */
  const firstInvoiceId = dealData?.invoices?.at(0)?.id ?? null;

  const { data: dealInvoice } = useGetInvoiceById(firstInvoiceId);

  const { mutate: updateInvoice, isLoading } = useUpdateInvoice({
    onSuccess: () => {
      setIsOpenSkipDialog(false);
      handleNextStep();
      queryClient.invalidateQueries([apiRoutes.InvoiceGetInvoice, firstInvoiceId]);
    },
  });

  const initialInvoiceDocuments = useMemo(() => {
    const invoiceDocuments = dealInvoice ? getInvoicesDocuments(dealInvoice) : [];
    const requestedDocumentsTypes = state
      ? [...state.requiredDocumentTypes, ...state.atLeastOneDocumentTypes.flat()]
      : [];

    return invoiceDocuments.filter((document) =>
      requestedDocumentsTypes.some((docType) => docType.documentType === document.documentType)
    );
  }, [dealInvoice, state]);

  const invoicesTotals = useMemo(() => formatDealData(dealData), [dealData]);

  const form = useForm<IDocumentsRequiredForm>({
    mode: 'onSubmit',
    defaultValues: getFormData(),
  });

  const { watch, reset, getValues, handleSubmit } = form;
  const formData = watch();

  const hasRequiredDocuments = useMemo(() => {
    const { requiredDocumentTypes, atLeastOneDocumentTypes } = state || {};

    const hasDocument = (doc: IDocumentType): boolean => {
      const documentField = formData[doc.documentType];

      return Array.isArray(documentField) ? documentField.length > 0 : Boolean(documentField);
    };

    const allRequiredDocumentsUploaded = requiredDocumentTypes?.every(hasDocument);

    if (!allRequiredDocumentsUploaded) {
      return false;
    }

    const atLeastOneUploaded = atLeastOneDocumentTypes?.every((group) => group.some(hasDocument));

    return allRequiredDocumentsUploaded && atLeastOneUploaded;
  }, [state, formData]);

  const handleFormData = useCallback(
    (data: IDocumentsRequiredForm, checkDocuments = true) => {
      if (!dealInvoice) {
        return;
      }

      if (!hasRequiredDocuments && checkDocuments) {
        setIsOpenSkipDialog(true);
        return;
      }

      const updatedInvoice = updateInvoiceDocuments({
        invoice: dealInvoice,
        formDocuments: data,
        initialFormDocuments: initialInvoiceDocuments,
      });

      updateInvoice(updatedInvoice);
    },
    [initialInvoiceDocuments, hasRequiredDocuments, dealInvoice, updateInvoice]
  );

  const handleNext = handleSubmit((data: IDocumentsRequiredForm) => {
    handleFormData(data);
  });

  const handleSkip = () => {
    const data = getValues();
    handleFormData(data, false);
  };

  useEffect(() => {
    if (initialInvoiceDocuments.length > 0) {
      reset({
        ...getValues(),
        ...initialInvoiceDocuments.reduce((accumulator, currentDocument) => {
          if (isDealFlowRequiredDocument(currentDocument.documentType)) {
            if (!accumulator[currentDocument.documentType]) {
              accumulator[currentDocument.documentType] = [];
            }

            accumulator[currentDocument.documentType]?.push(currentDocument);
          }

          return accumulator;
        }, {} as IDocumentsRequiredForm),
      });
      setUploadKey((prevKey) => prevKey + 1);
    }
  }, [initialInvoiceDocuments, getValues, reset]);

  const supplierName = invoicesTotals.supplierName || '';
  const buyerName = invoicesTotals.buyerName || '';

  return (
    <StepContainer
      title={<FormattedMessage id="createDeal.documentsRequired" />}
      isNextLoading={isLoading}
      isNextDisabled={isLoading}
      onNext={handleNext}
    >
      <Stack flexGrow={1}>
        <AlertCard
          direction="horizontal"
          state="primary"
          icon={<InfoRounded sx={{ opacity: 0.6 }} fontSize="small" />}
          title={<FormattedMessage id="createDeal.documentsRequired.alert.title" />}
        >
          <Typography.Body2>
            <FormattedMessage id="createDeal.documentsRequired.alert" />
          </Typography.Body2>
        </AlertCard>
        <Card
          sx={(theme) => ({
            padding: 2,
            marginTop: 2,
            boxShadow: 'none',
            border: `1px solid ${theme.palette.grey['200']}`,
            borderRadius: theme.shape.borderRadius / 2,
          })}
        >
          <FormProvider {...form}>
            <DocumentsRequiredForm key={uploadKey} buyerName={buyerName} supplierName={supplierName} />
          </FormProvider>
        </Card>
        <Dialog
          open={isOpenSkipDialog}
          onClose={() => setIsOpenSkipDialog(false)}
          sx={{
            '& .MuiPaper-root': {
              borderRadius: '16px',
            },
          }}
        >
          <ConfirmationDialogContent
            onCancel={() => setIsOpenSkipDialog(false)}
            onContinue={handleSkip}
            isLoading={isLoading}
          />
        </Dialog>
      </Stack>
    </StepContainer>
  );
};
