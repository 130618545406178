import { FC } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { SearchCompanyForm } from '@app/components/features/SearchCompanyForm';
import { getFormData } from '@app/components/features/SearchCompanyForm/helpers/getFormData';
import { ISearchCompanyForm } from '@app/components/features/SearchCompanyForm/types';
import { StepContainer } from '@app/components/features/StepContainer';
import { EFlowStep } from '@app/context/FlowTransitionsContext';
import { useFlowTransitionsContext } from '@app/hooks/useFlowTransitionsContext';
import { yupResolver } from '@hookform/resolvers/yup';

import { Card } from '@mui/material';
import { searchCompanySchema } from './schema';

interface ISearchTradePartnerProps {}

export const SearchTradePartner: FC<ISearchTradePartnerProps> = () => {
  const { handleNextStep, getStateByStep } = useFlowTransitionsContext();
  const defaultFormValues = getStateByStep<ISearchCompanyForm>(EFlowStep.SEARCH_TRADE_PARTNER);
  const intl = useIntl();
  const form = useForm<ISearchCompanyForm>({
    mode: 'onSubmit',
    defaultValues: getFormData(defaultFormValues),
    resolver: yupResolver(searchCompanySchema(intl.formatMessage)),
    reValidateMode: 'onChange',
  });

  const handleNext = form.handleSubmit((data) => {
    handleNextStep({
      stepState: data,
    });
  });
  return (
    <StepContainer title={<FormattedMessage id="createDeal.selectTradePartner" />} onNext={handleNext}>
      <Card
        sx={(theme) => ({
          padding: 2,
          boxShadow: 'none',
          border: `1px solid ${theme.palette.grey['200']}`,
          borderRadius: theme.shape.borderRadius / 2,
        })}
      >
        <SearchCompanyForm form={form} dunsField="optional" />
      </Card>
    </StepContainer>
  );
};
