import { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@stenngroup/ui-kit';

interface IConfirmationDialogContentProps {
  onContinue: () => void;
  onCancel: () => void;
  isLoading: boolean;
}

export const ConfirmationDialogContent: FC<IConfirmationDialogContentProps> = ({ isLoading, onContinue, onCancel }) => {
  return (
    <>
      <DialogTitle>
        <Typography.H5>
          <FormattedMessage id="createDeal.documentsRequired.skipDialog.title" />
        </Typography.H5>
      </DialogTitle>
      <DialogContent>
        <Typography.TextMd.Regular>
          <FormattedMessage id="createDeal.documentsRequired.skipDialog.description" />
        </Typography.TextMd.Regular>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onContinue} disabled={isLoading} isLoading={isLoading}>
          <FormattedMessage id="createDeal.documentsRequired.skipDialog.skip" />
        </Button>
        <Button variant="contained" onClick={onCancel} disabled={isLoading}>
          <FormattedMessage id="createDeal.documentsRequired.skipDialog.uploadDocuments" />
        </Button>
      </DialogActions>
    </>
  );
};
