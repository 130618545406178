import { useQuery } from 'react-query';

import { ERequestKey } from '@app/types/request';

import { useConfiguredFetch } from '@app/api/lib/useConfiguredFetch';
import { getCompanySignatoryInfoView } from '../requests/companySignatory';

export const useGetCompanySignatoryInfoView = () => {
  const configuredFetch = useConfiguredFetch();
  return useQuery(ERequestKey.GetCompanySignatoryInfoView, () => getCompanySignatoryInfoView({ configuredFetch }), {
    cacheTime: 0,
  });
};
