import { FC, useEffect, useState } from 'react';

import { LogoutOutlined, PersonOutlineRounded } from '@mui/icons-material';
import { MenuItem, Stack, Theme, useMediaQuery, useTheme } from '@mui/material';
import { DrawerContent, DrawerHeader, DrawerV2, Typography, UserMenu } from '@stenngroup/ui-kit';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { LanguageSelectorWrapper } from '@app/components/features/LanguageSelectorWrapper';
import Notifications from '@app/components/features/Notifications';
import { TEST_IDS } from '@app/core/constants';
import { PersonalSpace } from './PersonalSpace';

import { useApplicationState } from '@app/context/ApplicationStateContext/hooks/useApplicationState';
import { useLogout } from '@app/hooks/auth/useLogout';
import { BreadcrumbOutlet } from '../Crumbs';

export const DashboardHeader: FC = () => {
  const { email } = useApplicationState();
  const { handleLogout } = useLogout();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleOpenPersonalSpace = (): void => {
    // set hash to personal-space to open drawer
    navigate(`${location.pathname}#personal-space`);
  };

  const theme = useTheme();
  const minBreakpoint = theme.breakpoints.values.sm;

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setIsDrawerOpen(location.hash === '#personal-space');
  }, [location.hash]);

  const handleDrawerClose = (): void => {
    navigate(location.pathname);
  };

  return (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      gap={({ spacing }): string => spacing(2)}
      minHeight={({ spacing }): string => spacing(7)}
      borderBottom={({ palette }): string => `1px solid ${palette.secondary.main08}`}
      bgcolor={({ palette }): string => palette.background.paper}
      boxShadow={({ palette }): string => `0px 6px 15px -2px ${palette.secondary.main08}`}
      padding={({ spacing }): string => `${spacing(1)} ${spacing(2)}}`}
      position="sticky"
      top={0}
      zIndex={1100}
      sx={{
        paddingInline: `${({ theme }: { theme: Theme }): string => theme.spacing(2)}`,
        paddingBlock: `${({ theme }: { theme: Theme }): string => theme.spacing(1)}`,
      }}
    >
      <Stack flexDirection="row">
        <BreadcrumbOutlet />
      </Stack>
      <Stack flexDirection="row" alignItems="center">
        <Notifications isMobile={isMobile} />

        <UserMenu email={email ?? ''} popperProps={{ sx: { zIndex: (theme) => theme.zIndex.appBar } }}>
          <MenuItem
            sx={{
              width: '100%',
              padding: 0,
            }}
          >
            <Stack direction="row" alignItems="center" gap={1} width="100%">
              <LanguageSelectorWrapper />
            </Stack>
          </MenuItem>
          <MenuItem onClick={handleOpenPersonalSpace} data-testid={TEST_IDS.personalSpaceMenuItem}>
            <Stack direction="row" alignItems="center" gap={1}>
              <PersonOutlineRounded fontSize="inherit" />
              <Typography.Body2>
                <FormattedMessage id="constants.universalMessages.personalSpace" />
              </Typography.Body2>
            </Stack>
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <Stack direction="row" alignItems="center" gap={1}>
              <LogoutOutlined fontSize="inherit" />
              <Typography.Body2>
                <FormattedMessage id="constants.universalMessages.logOut" />
              </Typography.Body2>
            </Stack>
          </MenuItem>
        </UserMenu>
      </Stack>
      <DrawerV2
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        desktopAnchor="right"
        desktopWidth={minBreakpoint}
        sx={{
          zIndex: 1200,
        }}
      >
        <DrawerHeader onClose={handleDrawerClose}>
          <Typography.H5 data-testid={TEST_IDS.personalSpaceTitle}>
            <FormattedMessage id="constants.universalMessages.personalSpace" />
          </Typography.H5>
        </DrawerHeader>
        <DrawerContent>
          <PersonalSpace />
        </DrawerContent>
      </DrawerV2>
    </Stack>
  );
};
