import { useQuery } from 'react-query';

import { useHttpClient } from '@app/api/lib/useHttpClient';
import { useLangContext } from '@app/context/LangContext/hooks/useLangContext';
import { apiRoutes } from '@app/core/__generated__/apiRoutes';
import { TLang } from '@app/core/internationalization';
import { useAuth } from '@stenngroup/auth0-sdk';

export const useGetProfile = () => {
  const httpClient = useHttpClient();
  const { isAuthenticated, userInfo } = useAuth();
  const { setLangId } = useLangContext();

  return useQuery([apiRoutes.Profile], () => httpClient(apiRoutes.Profile, 'get', {}), {
    cacheTime: 5000,
    staleTime: 5000,
    enabled: isAuthenticated && !!userInfo,
    onSuccess(data) {
      setLangId(data.webBrowserLanguage as TLang);
    },
  });
};
