import { defineMessages } from 'react-intl';

import getMessageKeysForTable from '@app/utils/getMessageKeysForTable';

import tableData from './tableData';

const scope = 'components.FinancingInfoPopup.popups.LatePaymentFeesNoticePopup';
const messageKeysForTable = getMessageKeysForTable(tableData, scope);

export default defineMessages({
  firstParagraph: {
    id: `${scope}.firstParagraph`,
    defaultMessage:
      'If the relevant invoice is settled by your debtor after the Grace Period 7 days since due date - the Late Payment fee is applied.',
  },
  ...messageKeysForTable,
});
