import { ELocalStorageKey } from '@app/storage-keys';
import { EDevFeature } from '../types';
import { getDevFeaturesFromStorage } from './getDevFeaturesFromStorage';

export const toggleDevFeature = (feature: EDevFeature, shouldEnable: boolean): void => {
  const config = getDevFeaturesFromStorage();
  if (shouldEnable) {
    config[feature] = true;
    localStorage.setItem(ELocalStorageKey.DevFeatures, JSON.stringify(config));
  } else {
    config[feature] = false;
    localStorage.setItem(ELocalStorageKey.DevFeatures, JSON.stringify(config));
  }
};
