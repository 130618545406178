import { useIntl } from 'react-intl';

import SimpleTable from '@app/components/ui/SimpleTable';
import getMessageKeysForTable from '@app/utils/getMessageKeysForTable';
import { Stack } from '@mui/material';
import { Typography } from '@stenngroup/ui-kit';
import PropTypes from 'prop-types';
import * as SC from '../styles';

import messages from './messages';
import tableData from './tableData';

const VolumeAdjustmentFeesNoticePopup = () => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const messageKeysForTable = getMessageKeysForTable(tableData);

  return (
    <Stack>
      <Typography.Caption color={(t) => t.palette.text.secondary}>
        {formatMessage({
          id: 'components.FinancingInfoPopup.popups.VolumeAdjustmentFeesNoticePopup.firstParagraph',
        })}
      </Typography.Caption>
      <SimpleTable
        messageKeysForTable={messageKeysForTable}
        render={(preparedTableRows) => (
          <SC.Table>
            <tbody>
              {Object.keys(preparedTableRows).map((rowName) => (
                <SC.TableRow key={rowName}>
                  {preparedTableRows[rowName].map((cell) => (
                    <SC.VolumeAdjustmentChargeTableHead isHeadCell={cell.cellType === 'head'} key={cell.key}>
                      {formatMessage(messages[cell.key])}
                    </SC.VolumeAdjustmentChargeTableHead>
                  ))}
                </SC.TableRow>
              ))}
            </tbody>
          </SC.Table>
        )}
      />

      <Typography.Caption color={(t) => t.palette.text.secondary}>
        {formatMessage({
          id: 'components.FinancingInfoPopup.popups.VolumeAdjustmentFeesNoticePopup.secondParagraph',
        })}
      </Typography.Caption>
    </Stack>
  );
};

VolumeAdjustmentFeesNoticePopup.propTypes = {
  options: PropTypes.shape({
    shouldShowLinkToCalculator: PropTypes.bool,
    calculatorLinkTo: PropTypes.object,
  }),
};

export default VolumeAdjustmentFeesNoticePopup;
