import { IErrorResponse } from '@app/core/logger/types';
import { hasStrCodeProperty } from '@app/core/utilities';

export const getParsedBackendErrorData = (errorData?: IErrorResponse | string) => {
  if (hasStrCodeProperty(errorData)) {
    return errorData;
  }

  if (typeof errorData === 'string') {
    try {
      const parsedErrorData = JSON.parse(errorData);

      if (hasStrCodeProperty(parsedErrorData)) {
        return parsedErrorData;
      }
    } catch {
      return errorData || {};
    }
  }

  return errorData || {};
};
