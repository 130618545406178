import { type FC, useState } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, FormCheckboxField, FormTextField, Typography } from '@stenngroup/ui-kit';

import { yupResolver } from '@hookform/resolvers/yup';

import { useConfiguredFetch } from '@app/api/lib/useConfiguredFetch';
import { FormContainer } from '@app/components/features/FlowTransitions/screens/AddCompanySignatory/Form/FormContainer';
import { isPublicEmailRequest } from '@app/components/features/FlowTransitions/screens/AddCompanySignatory/Form/mutations/isPublicEmailRequest';
import { SignatoryTypeSelector } from '@app/components/features/FlowTransitions/screens/AddCompanySignatory/SignatoryTypeSelector';
import { EFlowStep } from '@app/context/FlowTransitionsContext';
import { TEST_IDS } from '@app/core/constants';
import { useFlowTransitionsContext } from '@app/hooks/useFlowTransitionsContext';
import { ArrowForward } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { useMutation } from 'react-query';
import { ESignatoryType, IContactForm } from '../../types';
import OtpConfirmationForm from './OtpConfirmationForm';
import { startVerifyCorporateContactDetailRequest } from './OtpConfirmationForm/mutations/startVerifyCorporateContactDetailRequest';
import { becomeSignerRequest } from './mutations/becomeSignerRequst';
import { authorizedSignatorySchema } from './schema';

interface IProps {
  defaultValues: Partial<IContactForm>;
  isCorporate: boolean;
  onSignatoryTypeChange: (signatoryType: ESignatoryType) => void;
  signatoryType: ESignatoryType;
  onRefetchProfile: () => void;
}

export const AuthorizedSignatoryForm: FC<IProps> = ({
  defaultValues,
  isCorporate,
  onSignatoryTypeChange,
  signatoryType,
  onRefetchProfile,
}) => {
  const { formatMessage } = useIntl();
  const { handleNextStep, getStateByStep } = useFlowTransitionsContext();

  const authorizedSignatoryFormState = getStateByStep<{ form: IContactForm; signatoryType: ESignatoryType } | null>(
    EFlowStep.ADD_COMPANY_SIGNATORY
  );

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const { mutateAsync: isPublicEmail, isLoading: isPublicEmailLoading } = useMutation({
    mutationFn: (email: string) => isPublicEmailRequest({ email, configuredFetch }),
  });

  const isStepCompleted = !!authorizedSignatoryFormState;

  const isFormDisabled = isStepCompleted;

  const { control, handleSubmit, watch, getValues, setError } = useForm<IContactForm>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    resolver: isFormDisabled ? undefined : yupResolver(authorizedSignatorySchema(formatMessage)),
    defaultValues: { checkbox: isFormDisabled, ...defaultValues, ...authorizedSignatoryFormState?.form },
  });

  const configuredFetch = useConfiguredFetch();

  const { mutate: verifyCorporateContact, isLoading: isVerifyCorporateContactLoading } = useMutation(
    () => startVerifyCorporateContactDetailRequest({ email: watch('email'), configuredFetch }),
    {
      onSuccess: () => {
        setIsDialogOpen(true);
      },
    }
  );
  const { mutate: becomeSigner, isLoading: isBecomeSignerLoading } = useMutation({
    mutationFn: (data: IContactForm) => becomeSignerRequest({ signer: data, configuredFetch }),
    onSuccess: (_, values) => {
      handleNextStep({ stepState: { form: values, signatoryType } });
      onRefetchProfile();
    },
  });

  const handleFormSubmit = handleSubmit(async (data) => {
    if (isStepCompleted) {
      return handleNextStep({ stepState: { form: data, signatoryType } });
    }
    if (defaultValues.email !== data.email) {
      const isPublic = await isPublicEmail(data.email);
      if (isPublic) {
        setError(
          'email',
          {
            message: formatMessage({ id: 'constants.frontendErrors.wrongEmailFormat' }),
          },
          { shouldFocus: true }
        );
        return null;
      }
    }
    if (!isCorporate) {
      verifyCorporateContact();
      return null;
    }
    becomeSigner(data);
  });

  const isLoading = isPublicEmailLoading || isVerifyCorporateContactLoading || isBecomeSignerLoading;

  return (
    <FormContainer
      radioGroup={
        <SignatoryTypeSelector
          signatoryType={signatoryType}
          setSignatoryType={onSignatoryTypeChange}
          disabled={isFormDisabled}
        />
      }
      actions={
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          onClick={handleFormSubmit}
          variant="contained"
          size="medium"
          endIcon={<ArrowForward />}
          data-testid={TEST_IDS.submitButton}
        >
          <FormattedMessage id="constants.universalMessages.next" />
        </Button>
      }
    >
      <form id="signatoryForm" onSubmit={handleFormSubmit}>
        <Stack spacing={3}>
          <Typography.TextMd.Medium>
            <FormattedMessage id="createDeal.addTradePartnerSignatory.form.description" />
          </Typography.TextMd.Medium>

          <Stack spacing={2}>
            <FormTextField
              name="firstName"
              control={control}
              label={<FormattedMessage id="createDeal.addTradePartnerSignatory.form.firstNameField" />}
              disabled={isFormDisabled}
              data-testid={TEST_IDS.firstNameInput}
            />
            <FormTextField
              name="lastName"
              control={control}
              label={<FormattedMessage id="createDeal.addTradePartnerSignatory.form.lastNameField" />}
              disabled={isFormDisabled}
              data-testid={TEST_IDS.lastNameInput}
            />
            <FormTextField
              name="position"
              control={control}
              label={<FormattedMessage id="createDeal.addTradePartnerSignatory.form.positionField" />}
              disabled={isFormDisabled}
              data-testid={TEST_IDS.positionInput}
            />
          </Stack>
          <Stack spacing={2}>
            <Typography.TextMd.Medium>
              <FormattedMessage id="createDeal.addTradePartnerSignatory.form.emailField.description" />
            </Typography.TextMd.Medium>
            <Stack spacing={2}>
              <FormTextField
                name="email"
                control={control}
                disabled={isCorporate || isFormDisabled}
                label={<FormattedMessage id="createDeal.addTradePartnerSignatory.form.emailField" />}
                data-testid={TEST_IDS.emailInput}
              />

              <FormTextField
                name="website"
                control={control}
                label={<FormattedMessage id="containers.SupplierFlow.steps.YourAuthorisedSignatory.website" />}
                disabled={isFormDisabled}
                data-testid={TEST_IDS.websiteInput}
              />
            </Stack>
          </Stack>
          <FormCheckboxField
            name="checkbox"
            control={control}
            label={
              <FormattedMessage id="containers.SupplierFlow.steps.YourAuthorisedSignatory.iAmAuthorizedSingatory" />
            }
            disabled={isFormDisabled}
            data-testid={TEST_IDS.confirmCheckbox}
          />
        </Stack>
      </form>
      <OtpConfirmationForm
        onClose={() => setIsDialogOpen(false)}
        isOpen={isDialogOpen}
        email={watch('email')}
        onSubmit={() => {
          setIsDialogOpen(false);
          handleNextStep({ stepState: { form: getValues(), signatoryType } });
          onRefetchProfile();
        }}
        contact={getValues()}
      />
    </FormContainer>
  );
};
