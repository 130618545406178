import * as appActionTypes from '../actionTypes/app';

export const showServiceModalAction = (options) => (dispatch) => {
  dispatch({
    type: appActionTypes.SHOW_SERVICE_MODAL,
    payload: options,
  });
};

export const hideServiceModalAction = (options) => (dispatch) => {
  dispatch({
    type: appActionTypes.HIDE_SERVICE_MODAL,
    payload: options,
  });
};
