import { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import { TextSkeleton } from '@app/components/ui/TextSkeleton';
import { TEST_IDS } from '@app/core/constants/testIds';
import { calculateTypographyHeight } from '@app/utils/calculateTypographyHeight';
import { useTheme } from '@mui/material';
import { Stack, Typography } from '@stenngroup/ui-kit';
import { QueryStatus } from 'react-query';

interface IProps {
  name: string;
  address: string;
  duns: string;
  queryStatus: QueryStatus;
}

export const CompanyDetails: FC<IProps> = ({ name, address, duns, queryStatus }) => {
  const theme = useTheme();
  return (
    <Stack spacing={1} data-testid={TEST_IDS.companyDetails}>
      <TextSkeleton
        status={queryStatus}
        style={{
          width: '50%',
          height: calculateTypographyHeight(theme.typography.body2SemiBold),
        }}
      >
        <Typography.Body2SemiBold>{name}</Typography.Body2SemiBold>
      </TextSkeleton>

      <Stack spacing={0.5}>
        <TextSkeleton
          status={queryStatus}
          style={{
            width: '50%',
            height: calculateTypographyHeight(theme.typography.body2SemiBold),
          }}
        >
          <Typography.TextSm.Regular color="text.secondary">{address}</Typography.TextSm.Regular>
        </TextSkeleton>
        <TextSkeleton
          status={queryStatus}
          style={{
            width: '50%',
            height: calculateTypographyHeight(theme.typography.body2SemiBold),
          }}
        >
          <Typography.TextSm.Regular color="text.secondary">
            <FormattedMessage
              id="createDeal.requestLimit.companyDetails.duns"
              values={{
                duns,
              }}
            />
          </Typography.TextSm.Regular>
        </TextSkeleton>
      </Stack>
    </Stack>
  );
};
