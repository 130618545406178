import { type FC } from 'react';

import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

import { EDealTradeRole, EFlowStep, EFlowType } from '@app/context/FlowTransitionsContext';
import { TEST_IDS } from '@app/core/constants';
import { useFlowTransitionsContext } from '@app/hooks/useFlowTransitionsContext';
import ControlPointRoundedIcon from '@mui/icons-material/ControlPointRounded';
import { Stack } from '@mui/material';
import {
  SideNavFabButton,
  SideNavItemV2,
  SideNavItemsListV2,
  SideNavSectionV2,
  SideNavSectionsV2,
  SideNavV2,
} from '@stenngroup/ui-kit';

import { useApplicationState } from '@app/context/ApplicationStateContext/hooks/useApplicationState';
import { DashboardHeader } from './Header';
import { DashboardNavItems } from './NavItems';
import { BreadcrumbContainerProvider } from './providers/BreadcrumbContainerProvider';
import { IDashboardLayoutProps } from './types';

export const DashboardLayout: FC<IDashboardLayoutProps> = ({ children }) => {
  const { startFlow } = useFlowTransitionsContext();
  const currentLocation = useLocation();
  const { hasBuyerRole, hasSupplierRole, tradeRole, isReadOnly } = useApplicationState();
  const handleClick = () => {
    startFlow({
      initialStep: tradeRole === 'Unknown' ? EFlowStep.TRADE_ROLE : EFlowStep.SELECT_TRADE_PARTNER,
      initialState: {
        tradeRole: tradeRole as EDealTradeRole,
        flowType: EFlowType.CreateDeal,
        trId: '',
        counterpartyCompanyId: '',
        dealId: null,
      },
    });
    return;
  };

  const currentPath = currentLocation.pathname;

  return (
    <BreadcrumbContainerProvider>
      <Stack direction="row" flexGrow={1} height="100%" flexDirection="row">
        <SideNavV2
          expandOnHover={false}
          isExpandedDefault={false}
          fab={
            <>
              <SideNavFabButton
                onClick={handleClick}
                size="medium"
                fullWidth
                variant="secondary"
                startIcon={<ControlPointRoundedIcon />}
                data-testid={TEST_IDS.dashboardLayoutAddDealButton}
                disabled={isReadOnly}
              >
                <FormattedMessage id="components.AddDealButton.newDeal" />
              </SideNavFabButton>
            </>
          }
        >
          <SideNavSectionsV2>
            <SideNavSectionV2>
              <SideNavItemsListV2>
                {DashboardNavItems.filter((item) => item.visible({ hasBuyerRole, hasSupplierRole })).map(
                  ({ path, title, Icon }) => (
                    <SideNavItemV2
                      key={path}
                      component={Link}
                      to={path}
                      isActive={currentPath.startsWith(path)}
                      title={title}
                      icon={<Icon fontSize="inherit" />}
                      data-testid={path.replace(/\//g, '')}
                    />
                  )
                )}
              </SideNavItemsListV2>
            </SideNavSectionV2>
          </SideNavSectionsV2>
        </SideNavV2>
        <Stack marginLeft={-2} height="100%" minHeight="100vh" component="main" flex="1 1 auto" maxWidth="100%">
          <DashboardHeader />
          <Stack paddingY={2} paddingX={2} bgcolor={(t) => t.palette.background.level1} flexGrow={1}>
            {children}
          </Stack>
        </Stack>
      </Stack>
    </BreadcrumbContainerProvider>
  );
};
