import { FC } from 'react';

import { useApplicationState } from '@app/context/ApplicationStateContext/hooks/useApplicationState';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';

import { useHttpClient } from '@app/api/lib/useHttpClient';
import { UploadInvoiceForm } from '@app/components/features/Invoice/UploadInvoiceForm';
import { baseInvoiceSchema } from '@app/components/features/Invoice/UploadInvoiceForm/baseSchema';
import { TStennClientsServiceApplicationPortModelsInvoiceInvoiceResponseDto } from '@app/core/__generated__';
import { apiRoutes } from '@app/core/__generated__/apiRoutes';
import { yupResolver } from '@hookform/resolvers/yup';
import { DialogContent, DialogTitle } from '@mui/material';
import { Button, DialogActions, Typography } from '@stenngroup/ui-kit';

import { IEditInvoiceForm } from '../types';

import { formatToDto } from './helpers/formatToDto';
import { getFormData } from './helpers/getFormData';

interface IEditInvoiceInnerProps {
  trId: string;
  onClose: () => void;
  onSuccess: () => void;
  invoice: Required<TStennClientsServiceApplicationPortModelsInvoiceInvoiceResponseDto>;
}

export const EditInvoiceInner: FC<IEditInvoiceInnerProps> = ({ trId, invoice, onClose, onSuccess }) => {
  const { formatMessage } = useIntl();

  const { isReadOnly } = useApplicationState();

  const form = useForm<IEditInvoiceForm>({
    mode: 'onSubmit',
    defaultValues: getFormData(invoice),
    resolver: yupResolver(baseInvoiceSchema(formatMessage)),
  });

  const httpClient = useHttpClient();

  const editInvoiceMutation = useMutation({
    mutationFn: (data: IEditInvoiceForm) =>
      httpClient(apiRoutes.InvoiceUpdateInvoice, 'post', {
        data: formatToDto(invoice.id, trId, data),
      }),
    onSuccess,
  });

  const { watch } = form;

  const hasInvoiceDocuments = !!watch('invoiceDocument');
  const hasTransportDocuments = !!watch('transportDocument');

  const isCategorySelected = watch('isCommodity') || watch('isPerishable') || watch('isUnknownCategory');

  const isSubmitDisabled = !hasInvoiceDocuments || !hasTransportDocuments || !isCategorySelected;

  const handleFormSubmit = form.handleSubmit((data) => {
    editInvoiceMutation.mutate(data);
  });
  return (
    <>
      <DialogTitle sx={{ px: 5 }}>
        <Typography.H5 color={(t) => t.palette.text.primary}>
          <FormattedMessage id="containers.SupplierFlow.steps.UploadInvoicesSuccess.editInvoiceTitle" />
        </Typography.H5>
      </DialogTitle>
      <DialogContent sx={{ px: 5 }}>
        <FormProvider {...form}>
          <UploadInvoiceForm tradeRelationId={trId} />
        </FormProvider>
      </DialogContent>
      <DialogActions sx={{ px: 5 }}>
        <Button variant="outlined" onClick={onClose}>
          <FormattedMessage id="constants.universalMessages.cancel" />
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          isLoading={editInvoiceMutation.isLoading}
          disabled={editInvoiceMutation.isLoading || isSubmitDisabled || isReadOnly}
          onClick={handleFormSubmit}
        >
          <FormattedMessage id="constants.universalMessages.save" />
        </Button>
      </DialogActions>
    </>
  );
};
