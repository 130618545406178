import { FC } from 'react';

import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { UPLOAD_STEP_INVOICE_FILE_TYPES } from '@app/components/features/Invoice/UploadInvoiceForm/UploadStep/constants';
import { Divider, Stack } from '@mui/material';
import { Typography } from '@stenngroup/ui-kit';

import { UploadStep } from '@app/components/features/Invoice/UploadInvoiceForm/UploadStep';
import { IUploadedDocument } from '@app/components/features/Invoice/UploadInvoiceForm/UploadStep/types';
import { ILoadedDocument } from '@app/components/features/Invoice/UploadInvoiceForm/types';
import { EFlowStep } from '@app/context/FlowTransitionsContext';
import { useFlowTransitionsContext } from '@app/hooks/useFlowTransitionsContext';

import { messages, titles } from '../constants';
import { DocumentTypes, IDocumentsRequiredForm, TAvailableDocumentTypes } from '../types';

interface IDocumentsRequiredFormProps {
  supplierName: string;
  buyerName: string;
}

type FormDocumentKeys = keyof IDocumentsRequiredForm;

export const DocumentsRequiredForm: FC<IDocumentsRequiredFormProps> = ({ supplierName, buyerName }) => {
  const { setValue, getValues } = useFormContext<IDocumentsRequiredForm>();

  const { getStateByStep } = useFlowTransitionsContext();
  const state = getStateByStep<DocumentTypes | null>(EFlowStep.INVOICES_LIST);

  const getDocuments = (documentType: TAvailableDocumentTypes) => getValues(documentType) ?? [];

  const handleUploadSingle = (type: FormDocumentKeys, newDocument: IUploadedDocument) => {
    setValue(type, [newDocument]);
  };

  const handleUploadMultiple = (type: FormDocumentKeys, newDocument: IUploadedDocument) => {
    const currentDocuments = getDocuments(type);
    const isExists = currentDocuments.findIndex((document) => document.uid === newDocument.uid) !== -1;

    let updatedDocuments: ILoadedDocument[];

    if (isExists) {
      updatedDocuments = currentDocuments.map((document) =>
        document.uid === newDocument.uid ? newDocument : document
      );
    } else {
      updatedDocuments = [...currentDocuments, newDocument];
    }

    setValue(type, updatedDocuments);
  };

  const handleUploadDocument = (type: FormDocumentKeys, isMultiple: boolean) => (newDocument: IUploadedDocument) => {
    if (isMultiple) {
      handleUploadMultiple(type, newDocument);
    } else {
      handleUploadSingle(type, newDocument);
    }
  };

  const handleDeleteDocument = (type: FormDocumentKeys, isMultiple: boolean) => (fileUid: string) => {
    if (isMultiple) {
      const currentDocuments = getDocuments(type);
      const updatedDocuments = currentDocuments.filter((document) => document.uid !== fileUid);

      setValue(type, updatedDocuments);
    } else {
      setValue(type, null);
    }
  };

  const requiredDocumentTypes = state?.requiredDocumentTypes;
  const atLeastOneDocumentTypes = state?.atLeastOneDocumentTypes?.flat();

  const isInfoTextVisible = atLeastOneDocumentTypes?.some(
    (document) => document.documentType === 'PurchaseOrder' || document.documentType === 'Contract'
  );

  return (
    <Stack gap={3}>
      <Stack gap={2}>
        {requiredDocumentTypes?.map(({ documentType, multiUpload }) => (
          <UploadStep
            key={documentType}
            title={titles[documentType]}
            subTitle={messages[documentType]}
            accept={UPLOAD_STEP_INVOICE_FILE_TYPES}
            documentType={documentType}
            allowMultipleFiles={multiUpload}
            onChange={handleUploadDocument(documentType, multiUpload)}
            onDelete={handleDeleteDocument(documentType, multiUpload)}
            values={getDocuments(documentType)}
          />
        ))}

        {(atLeastOneDocumentTypes?.length ?? 0) > 0 && (requiredDocumentTypes?.length ?? 0) > 0 && (
          <Divider
            orientation="horizontal"
            style={{
              height: 1,
            }}
          />
        )}
      </Stack>

      {(atLeastOneDocumentTypes ?? []).length > 0 && (
        <Typography.Body1SemiBold color={(t) => t.palette.text.primary}>
          <FormattedMessage id="createDeal.documentsRequired.form.required" />
        </Typography.Body1SemiBold>
      )}

      {atLeastOneDocumentTypes?.map(({ documentType, multiUpload }) => (
        <UploadStep
          key={documentType}
          title={titles[documentType]}
          subTitle={messages[documentType]}
          accept={UPLOAD_STEP_INVOICE_FILE_TYPES}
          documentType={documentType}
          allowMultipleFiles={multiUpload}
          onChange={handleUploadDocument(documentType, multiUpload)}
          onDelete={handleDeleteDocument(documentType, multiUpload)}
          values={getDocuments(documentType)}
        />
      ))}

      {isInfoTextVisible && (
        <Typography.TextSm.Regular color={(t) => t.palette.text.secondary}>
          <FormattedMessage id="createDeal.documentsRequired.form.inform" values={{ supplierName, buyerName }} />
        </Typography.TextSm.Regular>
      )}
    </Stack>
  );
};
