import { ComponentType, FC, ReactElement } from 'react';

import { useApplicationState } from '@app/context/ApplicationStateContext/hooks/useApplicationState';
import { routes } from '@app/routes/config';
import { Navigate } from 'react-router-dom';

export const withRoleRequired = <P extends object>(Component: ComponentType<P>, role: 'Buyer' | 'Supplier'): FC<P> => {
  return function WithRoleRequired(props: P): ReactElement | null {
    const { tradeRole } = useApplicationState();

    if (tradeRole !== role && tradeRole !== 'Unknown') {
      return <Navigate to={routes.dashboard.path} />;
    }

    return <Component {...props} />;
  };
};
