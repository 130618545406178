import { useEffect } from 'react';

import { useGetProfile } from '@app/api/hooks/useGetProfile';
import { setHeapIdentityIfLoaded, setHotjarIdentity } from '@app/hooks/useSetAnalyticsIdentity/helpers';

export const useSetAnalitycsIdentity = (): void => {
  const { data: profile } = useGetProfile();

  useEffect(() => {
    if (!profile?.userStennId) return;
    setHeapIdentityIfLoaded(profile.userStennId);
    setHotjarIdentity(profile.userStennId);
  }, [profile?.userId]);
};
