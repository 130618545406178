import { FC } from 'react';

import { ITradePartnersListProps } from '@app/components/features/FlowTransitions/screens/SelectTradePartner/TradePartnersList/types';
import { ITradePartner } from '@app/components/features/FlowTransitions/screens/SelectTradePartner/types';
import { CompanyStatus } from '@app/components/ui/CompanyStatus';
import { RequestLimitText } from '@app/components/ui/RequestLimitText';
import { TextSkeleton } from '@app/components/ui/TextSkeleton';
import { EDealTradeRole } from '@app/context/FlowTransitionsContext';
import { TEST_IDS } from '@app/core/constants';
import { calculateTypographyHeight } from '@app/utils/calculateTypographyHeight';
import { truncateText } from '@app/utils/truncateText';
import { Box, Stack, useTheme } from '@mui/material';
import { ChoiceBox, Typography } from '@stenngroup/ui-kit';

const CONTAINER_HEIGHT = 450;

interface ITradePartnerDetailsProps {
  status: ITradePartner['status'];
  totalLimit: ITradePartner['totalLimit'];
  requestedLimit: ITradePartner['requestedLimit'];
  tradeRole: EDealTradeRole | null;
  isLoading?: boolean;
}

const TradePartnerDetails: FC<ITradePartnerDetailsProps> = ({
  isLoading,
  status,
  totalLimit,
  requestedLimit,
  tradeRole,
}) => {
  const theme = useTheme();
  return (
    <Stack gap={0.5}>
      <Stack direction="row" gap={0.5}>
        <TextSkeleton
          status={isLoading ? 'loading' : 'idle'}
          style={{
            height: calculateTypographyHeight(theme.typography.body2),
          }}
        >
          <CompanyStatus status={status ?? 'Unknown'} />
          {tradeRole === EDealTradeRole.Buyer && (
            <RequestLimitText
              status={status ?? 'Unknown'}
              totalLimit={totalLimit ?? 0}
              requestedLimit={requestedLimit ?? 0}
              declineReason="Other"
            />
          )}
        </TextSkeleton>
      </Stack>
    </Stack>
  );
};

export const TradePartnersList: FC<ITradePartnersListProps> = ({
  tradePartners,
  selectedTradePartner,
  onTradePartnerSelect,
  tradeRole,
  disabled,
}) => {
  return (
    <Stack data-testid={TEST_IDS.companiesListContainer} gap={1}>
      {tradePartners.map((tradePartner) => (
        <Box
          key={tradePartner.counterpartyCompanyId}
          sx={{
            contentVisibility: 'auto',
            containIntrinsicHeight: 'auto 80px',
          }}
        >
          <ChoiceBox
            label={truncateText(tradePartner.counterpartyCompanyName ?? '', 30)}
            text={
              <TradePartnerDetails
                status={tradePartner.status}
                totalLimit={tradePartner.totalLimit}
                requestedLimit={tradePartner.requestedLimit}
                tradeRole={tradeRole}
              />
            }
            type="radio"
            name="company"
            size="medium"
            value={tradePartner.counterpartyCompanyId}
            checked={selectedTradePartner?.counterpartyCompanyId === tradePartner.counterpartyCompanyId}
            onChange={() => onTradePartnerSelect?.(tradePartner)}
            fullWidth
            data-testid={TEST_IDS.companyItem}
            disabled={tradePartner.status === 'FinancingDenied' || disabled}
          />
        </Box>
      ))}
    </Stack>
  );
};

export const TradePartnersListSkeleton = () => {
  return (
    <Stack data-testid={TEST_IDS.companiesListContainer} gap={1} height={CONTAINER_HEIGHT} overflow="auto">
      {Array.from({ length: 5 }).map((_, index) => (
        <ChoiceBox
          key={index}
          label={
            <TextSkeleton status="loading">
              <Typography.Body1 />
            </TextSkeleton>
          }
          text={<TradePartnerDetails isLoading status="Unknown" tradeRole={null} totalLimit={0} requestedLimit={0} />}
          type="radio"
          name="company"
          value=""
          fullWidth
          data-testid={TEST_IDS.companyItem}
          disabled
        />
      ))}
    </Stack>
  );
};
