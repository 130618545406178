import { DEFAULT_DATE_LOCALE, DEFAULT_DATE_OPTIONS } from '@app/core/constants';

import { toDate } from './date';

/**
 *  Formats date by locale
 *  @param {string|object } date - ISO date string or Date object
 *  @param {string} locale - formatting locale
 *  @param {object} options - formatting options
 *  @return {string} - formatted string
 *  */

export const formatDate = ({ date, locale = DEFAULT_DATE_LOCALE, options = DEFAULT_DATE_OPTIONS }) => {
  const normalizedDate = toDate(date);

  if (!normalizedDate) {
    return 'Invalid date';
  }

  return normalizedDate.toLocaleDateString(locale, options);
};

export const parseStringValue = (value) =>
  // eslint-disable-next-line no-useless-escape
  parseFloat(String(value).replace(/\,+/g, ''));

export const escapeRegExp = (string) =>
  // eslint-disable-next-line no-useless-escape
  string.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
