import { ILoadedDocument } from '@app/components/features/Invoice/UploadInvoiceForm/types';
import {
  TStennClientsServiceApplicationOperationsModelsUpdateInvoiceRequestDto,
  TStennClientsServiceApplicationPortModelsInvoiceInvoiceAttachmentResponseDto,
  TStennClientsServiceApplicationPortModelsInvoiceInvoiceResponseDto,
} from '@app/core/__generated__';
import { IInvoiceDocumentDTO } from '@app/core/models';
import { Nullable } from '@app/core/utilities';
import { IDocumentsRequiredForm } from '../types';

interface IUpdateInvoiceDocumentsProps {
  formDocuments: IDocumentsRequiredForm;
  initialFormDocuments: ILoadedDocument[];
  invoice: TStennClientsServiceApplicationPortModelsInvoiceInvoiceResponseDto;
}

const createDocument = (documentType: string, formDocument: any): IInvoiceDocumentDTO | null => {
  if (!formDocument || typeof formDocument === 'boolean') return null;

  return {
    id: formDocument.documentId || '',
    fileName: formDocument.name || '',
    type: documentType,
  };
};

const getBaseDocuments = (
  documents: Nullable<TStennClientsServiceApplicationPortModelsInvoiceInvoiceAttachmentResponseDto[]>,
  initialFormDocuments: ILoadedDocument[]
): TStennClientsServiceApplicationPortModelsInvoiceInvoiceAttachmentResponseDto[] => {
  if (!documents) return [];
  const initialFormDocumentIds = new Set(initialFormDocuments.map((doc) => doc.documentId));

  return documents.filter((doc) => !initialFormDocumentIds.has(doc.id!));
};

const getUpdatedFormDocuments = (formDocuments: IDocumentsRequiredForm): IInvoiceDocumentDTO[] => {
  const updatedFormDocuments = Object.entries(formDocuments).flatMap(([documentType, formDocument]) => {
    const documents = Array.isArray(formDocument)
      ? formDocument.map((doc) => createDocument(documentType, doc))
      : [createDocument(documentType, formDocument)];

    return documents.filter(Boolean);
  }) as IInvoiceDocumentDTO[];

  return updatedFormDocuments;
};

const getFilteredUpdatedFormDocuments = (
  invoice: TStennClientsServiceApplicationPortModelsInvoiceInvoiceResponseDto,
  documents: IInvoiceDocumentDTO[]
): IInvoiceDocumentDTO[] => {
  const currentDocumentIds = new Set((invoice.attachments || []).map((doc) => doc.id));

  return documents.filter((doc) => !currentDocumentIds.has(doc.id));
};

const getExistingDocuments = (documents: ILoadedDocument[], updatedDocuments: IInvoiceDocumentDTO[]) => {
  const updatedDocumentIds = new Set(updatedDocuments.map((doc) => doc.id));

  return documents.filter((doc) => updatedDocumentIds.has(doc.documentId));
};

const getOldDocuments = (
  baseDocuments: TStennClientsServiceApplicationPortModelsInvoiceInvoiceAttachmentResponseDto[],
  existingDocuments: ILoadedDocument[]
): TStennClientsServiceApplicationPortModelsInvoiceInvoiceAttachmentResponseDto[] => {
  const formattedExistingDocuments = existingDocuments.map((doc) => ({
    id: doc.documentId,
    fileName: doc.name || '',
    type: doc.documentType,
  }));

  return [...baseDocuments, ...formattedExistingDocuments];
};

export const updateInvoiceDocuments = ({
  invoice,
  formDocuments,
  initialFormDocuments,
}: IUpdateInvoiceDocumentsProps): TStennClientsServiceApplicationOperationsModelsUpdateInvoiceRequestDto => {
  const baseDocuments = getBaseDocuments(invoice.attachments, initialFormDocuments);
  const updatedFormDocuments = getUpdatedFormDocuments(formDocuments);
  const newFormDocuments = getFilteredUpdatedFormDocuments(invoice, updatedFormDocuments);
  const existingDocuments = getExistingDocuments(initialFormDocuments, updatedFormDocuments);
  const oldDocuments = getOldDocuments(baseDocuments, existingDocuments);

  const oldDocumentIds = oldDocuments.map((doc) => doc.id!);
  const documents = newFormDocuments.map((doc) => ({
    documentId: doc.id,
    documentType: doc.type,
  })) as TStennClientsServiceApplicationOperationsModelsUpdateInvoiceRequestDto['documents'];

  return {
    invoiceId: invoice.id,
    invoice: {
      number: invoice.number,

      /**
       * tradeRelationId in invoice response cannot be null
       * because invoice can't be created without tradeRelationId
       */
      tradeRelationId: invoice.tradeRelationId!,
      dueDate: invoice.dueDate,
      amount: invoice.totalValue,
      currencyIsoNumericCode: invoice.currencyIsoNumericCode,
      isCommodity: invoice.isCommodity,
      isPerishable: invoice.isPerishable,
    },
    documents,
    oldDocumentIds,
  };
};
