import { FC, useEffect } from 'react';

import { FormattedMessage } from 'react-intl';

import { useAvailableCurrencies } from '@app/api/hooks/useAvailableCurrencies';
import { StepContainer } from '@app/components/features/StepContainer';
import { EDealType } from '@app/core/constants';
import { useFlowTransitionsContext } from '@app/hooks/useFlowTransitionsContext';
import { Card } from '@stenngroup/ui-kit';

import { useBankDetailsScreen } from '@app/components/features/FlowTransitions/screens/AddBankDetails/hooks/useBankDetailsScreen';
import { useBankDetailsValidationSchema } from '@app/components/features/FlowTransitions/screens/AddBankDetails/schema';
import { EDealTradeRole, EFlowStep } from '@app/context/FlowTransitionsContext';
import { TStennClientsServiceApplicationPortModelsBankDetailsBankDetailCurrency } from '@app/core/__generated__';
import { apiRoutes } from '@app/core/__generated__/apiRoutes';
import { assert } from '@app/utils/assert';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { AddBankDetailsForm } from './AddBankDetailsForm';
import type { TFormValues } from './types';

interface IAddBankDetailsProps {}

export const AddBankDetails: FC<IAddBankDetailsProps> = () => {
  const { handleNextStep, getFlowState, getStateByStep } = useFlowTransitionsContext();

  const queryClient = useQueryClient();
  const addBankDetailsState = getStateByStep(EFlowStep.ADD_BANK_DETAILS);

  const isStepCompleted = !!addBankDetailsState;

  const { trId: tradeRelationId, counterpartyCompanyId, tradeRole } = getFlowState();
  assert(tradeRelationId, 'tradeRelationId is required');
  assert(counterpartyCompanyId, 'counterpartyCompanyId is required');

  const { data, isLoading: isLoadingCurrencies } = useAvailableCurrencies({
    tradeRelationId,
    isEnabled: !!tradeRelationId,
    invoiceProductType: EDealType.Goods,
    cacheTime: 0,
  });

  const schema = useBankDetailsValidationSchema();
  const form = useForm<TFormValues>({
    defaultValues: addBankDetailsState
      ? addBankDetailsState
      : {
          swift: '',
          currency: data?.[0]?.isoNumericCode || 0, // need to pass the right type, otherwise it won't work
        },
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const { canCreate, createBankDetails, isLoading, isFetching } = useBankDetailsScreen({
    tradeRole,
    tradeRelationId,
    counterpartyCompanyId,
    onSuccess: () => {
      handleNextStep({ stepState: form.getValues() });
      queryClient.refetchQueries([apiRoutes.Profile]);
    },
  });

  const handleSubmit = () => {
    if (isStepCompleted) {
      handleNextStep({ stepState: addBankDetailsState });
      return;
    }

    if (!canCreate) {
      handleNextStep({ stepState: addBankDetailsState });
      return;
    }

    if (form.getFieldState('swift').invalid) {
      return;
    }

    return form.handleSubmit((data) => {
      const currency = currencyOptions.find((item) => item.value === data.currency);

      createBankDetails({
        ...data,
        currency: currency?.title as TStennClientsServiceApplicationPortModelsBankDetailsBankDetailCurrency,
      });
    })();
  };

  const currencyOptions =
    data?.map((currency) => ({
      value: currency.isoNumericCode,
      title: currency.description,
    })) ?? [];

  useEffect(() => {
    if (currencyOptions?.[0]) {
      form.setValue('currency', currencyOptions?.[0]?.value);
    }
  }, [currencyOptions, form.setValue]);

  const isPending = isLoadingCurrencies || isFetching;

  return (
    <StepContainer
      title={
        <FormattedMessage
          id={
            tradeRole === EDealTradeRole.Buyer
              ? 'containers.Flow.steps.AddBankDetails.addBankDetailsBuyer'
              : 'containers.Flow.steps.AddBankDetails.addBankDetails'
          }
        />
      }
      isNextDisabled={isLoading || isPending}
      isNextLoading={isLoading}
      onNext={handleSubmit}
    >
      <Card
        sx={(theme) => ({
          padding: 2,
          boxShadow: 'none',
          border: `1px solid ${theme.palette.grey['200']}`,
          borderRadius: theme.shape.borderRadius / 2,
        })}
      >
        <FormProvider {...form}>
          <AddBankDetailsForm currencyOptions={currencyOptions} disabled={!canCreate || isStepCompleted} />
        </FormProvider>
      </Card>
    </StepContainer>
  );
};
