import { IntlFormatters } from 'react-intl';

import { EDealType } from '@app/core/constants';
import * as yup from 'yup';

import { isFutureDate, isValidPositiveNumber } from '@app/core/utilities';
import { ILoadedDocument, IUploadInvoiceForm } from './types';

const documentSchema = yup.object().shape<Record<keyof ILoadedDocument, yup.AnySchema>>({
  uid: yup.string().optional(),
  documentId: yup.string().required(),
  documentType: yup.string().required(),
  name: yup.string().optional(),
});

export const baseInvoiceSchema = (formatMessage: IntlFormatters['formatMessage']) =>
  yup.object().shape<Record<keyof IUploadInvoiceForm, yup.AnySchema>>({
    number: yup.string().required(
      formatMessage({
        id: 'constants.frontendErrors.enterInvoiceNumber',
      })
    ),
    currency: yup.string().required(
      formatMessage({
        id: 'constants.frontendErrors.selectCurrency',
      })
    ),
    dueDate: yup
      .date()
      .typeError(
        formatMessage({
          id: 'constants.frontendErrors.enterDueDate',
        })
      )
      .required(
        formatMessage({
          id: 'constants.frontendErrors.enterDueDate',
        })
      )
      .test({
        name: 'dueDate',
        message: formatMessage({
          id: 'constants.frontendErrors.futureDueDate',
        }),
        test: (value) => isFutureDate(value.toISOString()),
      }),
    faceValueNationalCurrency: yup
      .string()
      .test({
        name: 'faceValueNationalCurrency',
        message: formatMessage({
          id: 'constants.frontendErrors.enterInvoiceValue',
        }),
        test: (value) => isValidPositiveNumber(value),
      })
      .required(
        formatMessage({
          id: 'constants.frontendErrors.enterInvoiceValue',
        })
      ),
    prepaidAmount: yup.string().test({
      name: 'prepaidAmount',
      message: formatMessage({
        id: 'constants.frontendErrors.invalidPrepaidAmount',
      }),
      test: function (value = '') {
        return (
          !value ||
          (isValidPositiveNumber(value) &&
            parseFloat(value) <= parseFloat(this.parent.faceValueNationalCurrency || '0'))
        );
      },
    }),
    dealType: yup
      .string()
      .oneOf(
        Object.values(EDealType),
        formatMessage({
          id: 'constants.frontendErrors.selectDealType',
        })
      )
      .required(
        formatMessage({
          id: 'constants.frontendErrors.selectDealType',
        })
      ),
    otherDocuments: yup
      .array()
      .of(documentSchema)
      .required(
        formatMessage({
          id: 'constants.frontendErrors.addDocumentsForGoods',
        })
      ),
    invoiceDocument: documentSchema.required(
      formatMessage({
        id: 'constants.frontendErrors.addInvoiceDocument',
      })
    ),
    transportDocument: documentSchema.required(
      formatMessage({
        id: 'constants.frontendErrors.addTransportDocument',
      })
    ),
    isUnknownCategory: yup.boolean(),
    isCommodity: yup.boolean(),
    isPerishable: yup.boolean(),
    hasPurchaseOrderDocument: yup.boolean(),
  });
