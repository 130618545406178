export const enum ERequestKey {
  GetInvoices = 'GetInvoices',
  GetCountries = 'GetCountries',
  GetStates = 'GetStates',
  GetDeals = 'getDeals',
  DownloadAttachment = 'DownloadAttachment',
  GetSuppliers = 'GetSuppliers',
  GetSupplier = 'GetSupplier',
  GetBuyer = 'GetBuyer',
  GetBuyersList = 'GetBuyersList',
  PersonalSpaceContacts = 'PersonalSpaceContacts',
  GetCompanyBankDetails = 'GetCompanyBankDetails',
  GetDashboardDeals = 'GetDashboardDeals',
  GetDashboardDueInvoices = 'GetDashboardDueInvoices',
  GetDashboardOverdueInvoices = 'GetDashboardOverdueInvoices',
  GetCompanySignatoryInfoView = 'GetCompanySignatoryInfoView',
}
