import { FC, ReactNode, useState } from 'react';

import { LangContext } from '@app/context/LangContext/context';
import { TLang } from '@app/core/internationalization';

export const LangContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [langId, setLangId] = useState<TLang>('en');

  return (
    <LangContext.Provider
      value={{
        langId,
        setLangId,
      }}
    >
      {children}
    </LangContext.Provider>
  );
};
