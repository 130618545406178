import { FC } from 'react';

import { FormattedMessage } from 'react-intl';
import { QueryStatus } from 'react-query';

import LatePaymentFeesNoticePopup from '@app/components/ui/FinancingInfoPopup/LatePaymentFeesNoticePopup';
import VolumeAdjustmentFeesNoticePopup from '@app/components/ui/FinancingInfoPopup/VolumeAdjustmentFeesNoticePopup';
import { EDealTradeRole } from '@app/context/FlowTransitionsContext';
import { formatCurrency } from '@app/utils/formatCurrency';
import { Divider, Stack } from '@mui/material';

import { AmountText } from '@app/components/ui/AmountText';
import { CardRow } from './CardRow';
import { Header } from './Header';

interface IReviewDealCardProps {
  invoicesCount: number;
  tradeRole: EDealTradeRole | null;
  totalInvoicesValue: number;
  supplierReceives: number;
  firstPayment: number;
  secondPayment: number;
  totalFees: number;
  volumeAdjustmentFees: number;
  insuranceCharges: number;
  discountFee: number;
  latePaymentFees: number;
  currencyCode: string | undefined;
  loadingState: QueryStatus;
  buyerName: string | undefined;
  supplierName: string | undefined;
}

const INSURANCE_PERCENTAGE = 7.9;

export const ReviewDealCard: FC<IReviewDealCardProps> = ({
  invoicesCount,
  tradeRole,
  totalInvoicesValue,
  supplierReceives,
  firstPayment,
  secondPayment,
  totalFees,
  volumeAdjustmentFees,
  insuranceCharges,
  latePaymentFees,
  discountFee,
  loadingState,
  buyerName,
  supplierName,
  currencyCode = 'USD',
}) => {
  return (
    <Stack width="100%">
      <Header
        loadingState={loadingState}
        buyerName={buyerName}
        supplierName={supplierName}
        invoicesCount={invoicesCount}
        tradeRole={tradeRole}
      />
      <Divider sx={{ my: 2 }} />
      <CardRow
        loadingState={loadingState}
        text={<FormattedMessage id="constants.universalMessages.totalInvoiceFaceValue" />}
        value={formatCurrency(totalInvoicesValue, {
          currency: currencyCode,
        })}
        expandedContent={
          <FormattedMessage id="components.FinancingInfoPopup.popups.TotalInvoiceFaceValueNoticePopup.firstParagraph" />
        }
      />
      <CardRow
        loadingState={loadingState}
        text={
          tradeRole === EDealTradeRole.Supplier ? (
            <FormattedMessage id="constants.universalMessages.youReceive" />
          ) : (
            <FormattedMessage id="constants.universalMessages.supplierReceive" />
          )
        }
        value={<AmountText amount={supplierReceives} currency={currencyCode} isEstimated />}
        expanded
      />
      <CardRow
        loadingState={loadingState}
        text={<FormattedMessage id="constants.universalMessages.firstPayment" />}
        value={<AmountText amount={firstPayment} currency={currencyCode} isEstimated />}
        expandedContent={
          <FormattedMessage id="components.FinancingInfoPopup.popups.FirstPaymentNoticePopup.firstParagraphForCommissionVersion2" />
        }
        thin
      />
      <CardRow
        loadingState={loadingState}
        text={<FormattedMessage id="pages.dealPage.dealCard.secondPayment" />}
        value={<AmountText amount={secondPayment} currency={currencyCode} isEstimated />}
        expandedContent={
          <FormattedMessage id="components.FinancingInfoPopup.popups.SecondPaymentNoticePopup.secondParagraph" />
        }
        thin
      />

      <Divider sx={{ my: 2 }} />

      <CardRow
        loadingState={loadingState}
        text={<FormattedMessage id="createDeal.reviewDeal.totalFees" />}
        value={<AmountText amount={totalFees} currency={currencyCode} isEstimated />}
        expandedContent={<FormattedMessage id="components.FinancingInfoPopup.popups.EstimatedFeesNoticePopup" />}
      />
      <CardRow
        loadingState={loadingState}
        text={<FormattedMessage id="createDeal.reviewDeal.discount" values={{ Percentage: INSURANCE_PERCENTAGE }} />}
        value={<AmountText amount={discountFee} currency={currencyCode} isEstimated />}
        expandedContent={
          <FormattedMessage id="components.FinancingInfoPopup.popups.DiscountNoticePopup.firstParagraph" />
        }
        thin
      />
      <CardRow
        loadingState={loadingState}
        text={<FormattedMessage id="createDeal.reviewDeal.volumeAdjustmentFees" />}
        value={<AmountText amount={volumeAdjustmentFees} currency={currencyCode} isEstimated />}
        expandedContent={<VolumeAdjustmentFeesNoticePopup />}
        thin
      />
      <CardRow
        loadingState={loadingState}
        text={<FormattedMessage id="createDeal.reviewDeal.insuranceCharges" />}
        value={<AmountText amount={insuranceCharges} currency={currencyCode} isEstimated />}
        expandedContent={
          <FormattedMessage id="components.FinancingInfoPopup.popups.InsuranceChargesNoticePopup.notDecidedFirstParagraph" />
        }
        thin
      />
      <CardRow
        loadingState={loadingState}
        text={<FormattedMessage id="createDeal.reviewDeal.latePaymentFees" />}
        value={<AmountText amount={latePaymentFees} currency={currencyCode} isEstimated />}
        expandedContent={<LatePaymentFeesNoticePopup />}
        thin
      />
    </Stack>
  );
};
