import { defineMessages } from 'react-intl';

import getMessageKeysForTable from '@app/utils/getMessageKeysForTable';

import tableData from './tableData';

const scope = 'components.FinancingInfoPopup.popups.VolumeAdjustmentFeesNoticePopup';
const messageKeysForTable = getMessageKeysForTable(tableData, scope);

export default defineMessages({
  ...messageKeysForTable,
});
