import { ChipColor } from '@stenngroup/ui-kit';
import { ICompanyStatusProps } from '../types';

export const StatusColor: Record<ICompanyStatusProps['status'], ChipColor> = {
  Available: 'success',
  FinancingDenied: 'error',
  Unknown: 'error',
  Overdue: 'error',
  UnderReview: 'primary',
  RequestingLimit: 'primary',
};
