import {
  TStennClientsServiceApplicationOperationsModelsInvoiceDocumentDto,
  TStennClientsServiceApplicationOperationsModelsUpdateInvoiceRequestDto,
} from '@app/core/__generated__';

import { assert } from '@app/utils/assert';
import { fixDateToUTCZero } from '@app/utils/fixDateToUTCZero';
import { IEditInvoiceForm } from '../../types';

interface IOldDocument {
  id: string;
  isUploaded: boolean;
}

interface INewDocument extends TStennClientsServiceApplicationOperationsModelsInvoiceDocumentDto {
  isUploaded: boolean;
}

export const formatToDto = (
  invoiceId: string,
  trId: string,
  params: IEditInvoiceForm
): TStennClientsServiceApplicationOperationsModelsUpdateInvoiceRequestDto => {
  const oldDocuments: IOldDocument[] = [
    {
      id: params.invoiceDocument?.documentId || '',
      isUploaded: !!params.invoiceDocument?.isUploaded,
    },
    {
      id: params.transportDocument?.documentId || '',
      isUploaded: !!params.transportDocument?.isUploaded,
    },
    ...params.otherDocuments.map((doc) => ({
      id: doc.documentId,
      isUploaded: doc.isUploaded,
    })),
    ...params.additionalRequiredDocuments.map((doc) => ({
      id: doc.documentId,
      isUploaded: doc.isUploaded,
    })),
  ];

  const documents: INewDocument[] = [
    {
      documentType: 'CommercialInvoice',
      documentId: params.invoiceDocument?.documentId ?? '',
      isUploaded: !!params.invoiceDocument?.isUploaded,
    },
    {
      documentType: 'TransportDocument',
      documentId: params.transportDocument?.documentId ?? '',
      isUploaded: !!params.transportDocument?.isUploaded,
    },
    ...params.otherDocuments.map<INewDocument>((doc) => ({
      documentType: 'Other',
      documentId: doc.documentId,
      isUploaded: doc.isUploaded,
    })),
  ];

  assert(params.dueDate, 'Due date is required');

  return {
    invoiceId,
    invoice: {
      number: params.number,
      tradeRelationId: trId,
      dueDate: fixDateToUTCZero(params.dueDate).toISOString(),
      amount: parseFloat(params.faceValueNationalCurrency),
      currencyIsoNumericCode: Number(params.currency),
      isCommodity: params.isCommodity,
      isPerishable: params.isPerishable,
      prepaidAmount: params.prepaidAmount ? parseFloat(params.prepaidAmount) : 0,
    },
    documents: documents
      .filter((doc) => !doc.isUploaded)
      .map<TStennClientsServiceApplicationOperationsModelsInvoiceDocumentDto>(
        ({ isUploaded, ...document }) => document
      ),
    oldDocumentIds: oldDocuments.filter((doc) => doc.isUploaded && doc.id).map((doc) => doc.id),
  };
};
