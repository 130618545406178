import { FC } from 'react';

import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';

import { useGetProfile } from '@app/api/hooks/useGetProfile';
import { useConfiguredFetch } from '@app/api/lib/useConfiguredFetch';
import { InfoBlock } from '@app/components/layouts/DashboardLayout/Header/PersonalSpace/sections/CompanyInformation/InfoBlock';
import { getCompanyBankDetailsRequest } from '@app/components/layouts/DashboardLayout/Header/PersonalSpace/sections/CompanyInformation/queries/getCompanyBankDetailsRequest';
import { TEST_IDS } from '@app/core/constants/testIds';
import { ERequestKey } from '@app/types/request';
import { Divider, Stack } from '@mui/material';
import { Typography } from '@stenngroup/ui-kit';

const BLOCK_MAX_WIDTH = '254px';

export const CompanyInformation: FC = () => {
  const configuredFetch = useConfiguredFetch();
  const { data: profile } = useGetProfile();
  const { data: bankDetails, status } = useQuery(
    [ERequestKey.GetCompanyBankDetails],
    () =>
      getCompanyBankDetailsRequest({
        configuredFetch,
      }),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  return (
    <Stack data-testid={TEST_IDS.personalSpaceCompanyInfo}>
      <Stack direction="row" justifyContent="space-between">
        <Stack gap={3} maxWidth={BLOCK_MAX_WIDTH}>
          <InfoBlock
            loadingState="success"
            titleId="constants.universalMessages.website"
            value={profile?.companyInfo?.website}
          />
          <InfoBlock
            loadingState="success"
            titleId="constants.universalMessages.duns"
            value={profile?.companyInfo?.duns}
          />
          <InfoBlock
            loadingState="success"
            titleId="constants.universalMessages.registrationNumber"
            value={profile?.companyInfo?.companyRegistrationNumber}
          />
        </Stack>
        <Stack gap={3} maxWidth={BLOCK_MAX_WIDTH}>
          <InfoBlock
            loadingState="success"
            titleId="constants.universalMessages.jurisdiction"
            value={profile?.companyInfo?.companyAddress?.countryName}
          />
          <InfoBlock
            loadingState="success"
            titleId="constants.universalMessages.legalAddress"
            value={profile?.companyInfo?.legalAddress}
          />
        </Stack>
      </Stack>
      <Stack paddingY={2}>
        <Divider />
      </Stack>
      <Stack gap={2}>
        <Typography.Body1SemiBold>
          <FormattedMessage id="constants.universalMessages.bankDetails" />
        </Typography.Body1SemiBold>
        <Stack direction="row" justifyContent="space-between">
          <Stack gap={3} maxWidth={BLOCK_MAX_WIDTH}>
            <InfoBlock
              loadingState={status}
              titleId="constants.universalMessages.currencyOfAccount"
              value={bankDetails?.currency?.toUpperCase()}
            />
            <InfoBlock
              loadingState={status}
              titleId="constants.universalMessages.bankName"
              value={bankDetails?.bankName}
            />
            <InfoBlock loadingState={status} titleId="constants.universalMessages.swift" value={bankDetails?.swift} />
            <InfoBlock
              loadingState={status}
              titleId="constants.universalMessages.beneficiary"
              value={bankDetails?.beneficiaryName}
            />
          </Stack>
          <Stack gap={3} maxWidth={BLOCK_MAX_WIDTH}>
            <InfoBlock
              loadingState={status}
              titleId="constants.universalMessages.beneficiaryAccount"
              value={bankDetails?.beneficiaryAccountNumber}
            />
            <InfoBlock
              loadingState={status}
              titleId="constants.universalMessages.beneficiaryCountry"
              value={bankDetails?.beneficiaryCountryCode}
            />
            <InfoBlock
              loadingState={status}
              titleId="constants.universalMessages.beneficiaryAddress"
              value={bankDetails?.beneficiaryAddress1}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
