import { useHttpClient } from '@app/api/lib/useHttpClient';
import { apiRoutes } from '@app/core/__generated__/apiRoutes';
import { EMPTY_UUID } from '@app/core/constants/uuid';
import { useQuery } from 'react-query';

export const useGetInvoiceById = (invoiceId: string | null) => {
  const httpClient = useHttpClient();

  const { data, status, error, isLoading } = useQuery(
    [apiRoutes.InvoiceGetInvoice, invoiceId],
    ({ signal }) =>
      invoiceId
        ? httpClient(apiRoutes.InvoiceGetInvoice, 'post', {
            data: {
              id: invoiceId,
              actual: true,
            },
            signal,
          })
        : null,
    {
      enabled: Boolean(invoiceId),
      cacheTime: 0,
      staleTime: 0,
    }
  );

  return { data, status, error, isLoading, isNotFound: data?.id === EMPTY_UUID };
};
