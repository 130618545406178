import { useHttpClient } from '@app/api/lib/useHttpClient';
import { apiRoutes } from '@app/core/__generated__/apiRoutes';
import { useQuery } from 'react-query';

interface UseGetDealByIdParams {
  id: string | null;
  isActual: boolean;
}

export const useGetDealById = ({ id, isActual }: UseGetDealByIdParams) => {
  const httpClient = useHttpClient();

  const { data, status, refetch, isRefetching, error } = useQuery(
    [apiRoutes.DealsGetDeal, id, isActual],
    () =>
      id
        ? httpClient(apiRoutes.DealsGetDeal, 'post', {
            data: {
              actual: isActual,
              id,
            },
          })
        : null,
    {
      enabled: Boolean(id),
      cacheTime: 0,
    }
  );

  return { data, status, refetch, isRefetching, error };
};
