export const apiRoutes = {
  AdminGetStatusCommToolsReplications: '/Admin/GetStatusCommToolsReplications',
  AdminGetStatusOnboardingReplications: '/Admin/GetStatusOnboardingReplications',
  AdminGetStatusOperationsReplications: '/Admin/GetStatusOperationsReplications',
  AdminGrantRoleSet: '/Admin/GrantRoleSet',
  AdminRevokeRoleSet: '/Admin/RevokeRoleSet',
  BankDetailsCanCreate: '/BankDetails/CanCreate',
  BankDetailsCanCreateForSupplierSupplierId: '/BankDetails/CanCreateForSupplier/:supplierId',
  BankDetailsCreate: '/BankDetails/Create',
  BankDetailsCreateForSupplierSupplierId: '/BankDetails/CreateForSupplier/:supplierId',
  BankDetailsEdit: '/BankDetails/Edit',
  BankDetailsGet: '/BankDetails/Get',
  BankDetailsGetSwiftInfo: '/BankDetails/GetSwiftInfo',
  BuyerGetBuyer: '/Buyer/GetBuyer',
  BuyerGetBuyersForCurrentSupplier: '/Buyer/GetBuyersForCurrentSupplier',
  BuyerGetBuyerStatusLimits: '/Buyer/GetBuyerStatusLimits',
  CommunicationRequestGetCommunicationRequestToken: '/CommunicationRequest/GetCommunicationRequestToken',
  CompanyAddOwnCompany: '/Company/AddOwnCompany',
  CounterpartyAddCounterparty: '/Counterparty/AddCounterparty',
  DealsCreateDraftDealsFromDraftInvoices: '/Deals/CreateDraftDealsFromDraftInvoices',
  DealsGetAdditionalDocumentsTypes: '/Deals/GetAdditionalDocumentsTypes',
  DealsGetDeal: '/Deals/GetDeal',
  DealsGetDeals: '/Deals/GetDeals',
  DealsGetDealsCount: '/Deals/GetDealsCount',
  DealsGetSignatureLinkForDealAttachment: '/Deals/GetSignatureLinkForDealAttachment',
  DealsSubmitDeal: '/Deals/SubmitDeal',
  DealsSubmitNewDeal: '/Deals/SubmitNewDeal',
  DocumentsDownloadInvoiceAttachmentDocumentId: '/Documents/DownloadInvoiceAttachment/:documentId',
  InfoGetUserCountryInfo: '/Info/GetUserCountryInfo',
  InvitationCancelInvitation: '/Invitation/CancelInvitation',
  InvitationGetInvitationCode: '/Invitation/GetInvitationCode',
  InvitationInvitationInfo: '/Invitation/InvitationInfo',
  InvitationInviteContact: '/Invitation/InviteContact',
  InvitationInviteCounterpartyContact: '/Invitation/InviteCounterpartyContact',
  InvitationResendInvitation: '/Invitation/ResendInvitation',
  InvitationSendInvitation: '/Invitation/SendInvitation',
  InvoiceCreateInvoice: '/Invoice/CreateInvoice',
  InvoiceDeleteOverdueInvoices: '/Invoice/DeleteOverdueInvoices',
  InvoiceGetInvoice: '/Invoice/GetInvoice',
  InvoiceGetInvoices: '/Invoice/GetInvoices',
  InvoiceGetInvoicesCount: '/Invoice/GetInvoicesCount',
  InvoiceUpdateInvoice: '/Invoice/UpdateInvoice',
  LimitRequestLimit: '/Limit/RequestLimit',
  LinkToCompanyRequestApproveByCompany: '/LinkToCompanyRequest/ApproveByCompany',
  LinkToCompanyRequestApproveByStenn: '/LinkToCompanyRequest/ApproveByStenn',
  LinkToCompanyRequestCreateByUser: '/LinkToCompanyRequest/CreateByUser',
  LinkToCompanyRequestDeclineByCompany: '/LinkToCompanyRequest/DeclineByCompany',
  LinkToCompanyRequestDeclineByStenn: '/LinkToCompanyRequest/DeclineByStenn',
  LinkToCompanyRequestGetForCompany: '/LinkToCompanyRequest/GetForCompany',
  LinkToCompanyRequestGetForUser: '/LinkToCompanyRequest/GetForUser',
  NotificationDeleteDeleteId: '/Notification/Delete/Delete/:id',
  NotificationDeleteAllDeleteAll: '/Notification/DeleteAll/DeleteAll',
  NotificationGetNotificationsGetNotifications: '/Notification/GetNotifications/GetNotifications',
  NotificationNotificationsInfoNotificationsInfo: '/Notification/NotificationsInfo/NotificationsInfo',
  NotificationReadReadId: '/Notification/Read/Read/:id',
  NotificationReadAllReadAll: '/Notification/ReadAll/ReadAll',
  Profile: '/Profile',
  ProfileChangeWebBrowserLanguageLanguageCode: '/Profile/ChangeWebBrowserLanguage/:languageCode',
  ProfileChooseCompanyRoleCompanyRole: '/Profile/ChooseCompanyRole/:companyRole',
  SearchSearchDuns: '/Search/SearchDuns',
  SecurityAcceptLegalPoliciesAcceptLegalPolicies: '/Security/AcceptLegalPolicies/AcceptLegalPolicies',
  SecurityActivateSocialAccount: '/Security/ActivateSocialAccount',
  SecurityCompleteEmailVerification: '/Security/CompleteEmailVerification',
  SecurityFinishRegistration: '/Security/FinishRegistration',
  SecuritySendEmailVerification: '/Security/SendEmailVerification',
  SupplierGetSupplier: '/Supplier/GetSupplier',
  SupplierGetSuppliersForCurrentBuyer: '/Supplier/GetSuppliersForCurrentBuyer',
  TestGetAnonymous: '/Test/GetAnonymous',
  TestGetAuthorized: '/Test/GetAuthorized',
  TestGetForAdministrator: '/Test/GetForAdministrator',
  TestGetForCustomer: '/Test/GetForCustomer',
  TestGetForRepresentative: '/Test/GetForRepresentative',
  TestGetResources: '/Test/GetResources',
  TradeRelationGetTradeRelationInfo: '/TradeRelation/GetTradeRelationInfo',
  WizardCompleteWizard: '/Wizard/CompleteWizard',
  WizardGetClientWizardInfo: '/Wizard/GetClientWizardInfo',
  WizardSaveStep: '/Wizard/SaveStep',
} as const;
