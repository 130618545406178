import { StepContainer } from '@app/components/features/StepContainer';
import { ListContainer } from '@app/components/ui/PageListContainer/ListContainer';
import { EFlowStep } from '@app/context/FlowTransitionsContext';
import { TStennClientsServiceApplicationPortModelsDealDealInvoiceResponseDto } from '@app/core/__generated__';
import { apiRoutes } from '@app/core/__generated__/apiRoutes';
import { TEST_IDS } from '@app/core/constants/testIds';
import { isFutureDate } from '@app/core/utilities/validators';
import { useFlowTransitionsContext } from '@app/hooks/useFlowTransitionsContext';
import { useGetDealById } from '@app/hooks/useGetDealById';
import { formatCurrency } from '@app/utils/formatCurrency';
import { Stack } from '@mui/material';
import { Button, Typography } from '@stenngroup/ui-kit';
import { FC, useCallback, useMemo, useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { QueryStatus, useQueryClient } from 'react-query';
import { match } from 'ts-pattern';
import { useAdditionalDocumentsTypes } from '../DocumentsRequired/mutations';
import { InvoiceDetails } from './InvoiceDetails';
import { InvoicesListSkeleton } from './InvoicesListSkeleton';

interface IInvoicesListProps {}

export const InvoicesList: FC<IInvoicesListProps> = () => {
  const queryClient = useQueryClient();
  const { handleNextStep, goTo, getFlowState } = useFlowTransitionsContext();
  const { trId, tradeRole, dealId } = getFlowState();

  const { data, status, refetch, isRefetching } = useGetDealById({ id: dealId, isActual: true });

  const [errors, setErrors] = useState<Record<string, JSX.Element>>({});

  const invoiceIds = useMemo(() => data?.invoices?.map((invoice) => invoice.id!), [data?.invoices]);

  const { mutateAsync: getAdditionalDocumentsTypes, isLoading: isLoadingAdditionalDocumentsTypes } =
    useAdditionalDocumentsTypes({ invoiceIds, tradeRole });

  const handleNext = useCallback(async () => {
    const overdueInvoices = data?.invoices?.filter((invoice) => !isFutureDate(invoice.dueDate || null));
    if (overdueInvoices?.length) {
      setErrors(
        overdueInvoices.reduce<Record<string, JSX.Element>>(
          (acc, invoice) => ({
            ...acc,
            [invoice.id as string]: (
              <Typography.Caption color={(t) => t.palette.error.main}>
                <FormattedMessage id="constants.frontendErrors.futureDueDate" />
              </Typography.Caption>
            ),
          }),
          {}
        )
      );
      return;
    }

    const result = await getAdditionalDocumentsTypes();

    if (result?.requiredDocumentTypes?.length || result?.atLeastOneDocumentTypes?.length) {
      goTo(EFlowStep.DOCUMENTS_REQUIRED, 'forward', {
        stepState: {
          requiredDocumentTypes: result.requiredDocumentTypes,
          atLeastOneDocumentTypes: result.atLeastOneDocumentTypes,
        },
      });
    } else {
      handleNextStep();
    }
  }, [handleNextStep, goTo, getAdditionalDocumentsTypes, data]);

  const queryStatus = match<QueryStatus, QueryStatus>(status)
    .with('loading', () => 'loading')
    .with('error', () => 'error')
    .with('success', () => (isRefetching ? 'loading' : 'success'))
    .otherwise(() => 'idle');

  const addAnotherInvoiceParams = useMemo(() => ({ hasInvoices: Boolean(data?.invoices?.length) }), [data]);

  const handleUpdate = (invoiceId: string) => () => {
    refetch();
    queryClient.invalidateQueries([apiRoutes.InvoiceGetInvoice, invoiceId]);
    setErrors({});
  };

  return (
    <StepContainer
      title={<FormattedMessage id="createDeal.invoicesList.title" />}
      onNext={handleNext}
      isNextDisabled={!data || !data.invoices || data.invoices.length === 0 || isLoadingAdditionalDocumentsTypes}
      isNextLoading={isLoadingAdditionalDocumentsTypes}
    >
      <Stack gap={2} data-testid={TEST_IDS.invoicesList}>
        <ListContainer
          items={
            (data?.invoices as Required<TStennClientsServiceApplicationPortModelsDealDealInvoiceResponseDto>[]) ??
            undefined
          }
          status={queryStatus}
          emptyMessageId="createDeal.invoicesList.emptyList"
          renderRow={(invoice) => (
            <InvoiceDetails
              key={invoice.id}
              trId={trId}
              invoiceId={invoice.id}
              invoiceNumber={invoice.number}
              invoiceValue={formatCurrency(invoice.totalValue, {
                currency: invoice.currencyIso3LetterCode ?? 'USD',
              })}
              onUpdate={handleUpdate(invoice.id)}
              dueDate={<FormattedDate value={invoice.dueDate} dateStyle="full" />}
              isOverdue={!isFutureDate(invoice.dueDate || null)}
              error={errors[invoice.id]}
            />
          )}
          LoadingIndicator={<InvoicesListSkeleton />}
        />
        <Button
          size="medium"
          variant="tertiary"
          disabled={queryStatus === 'loading'}
          data-testid={TEST_IDS.uploadAnotherInvoiceBtn}
          onClick={() =>
            goTo(EFlowStep.UPLOAD_INVOICE, 'backward', {
              stepState: addAnotherInvoiceParams,
            })
          }
        >
          <FormattedMessage id="constants.universalMessages.addAnotherInvoice" />
        </Button>
        <Typography.Body2 color={(t) => t.palette.text.secondary}>
          <FormattedMessage id="createDeal.invoicesList.helpMessage" />
        </Typography.Body2>
      </Stack>
    </StepContainer>
  );
};
