/** all FCG local storage keys or key prefixes */
export const enum ELocalStorageKey {
  ThemeModeSetting = 'theme-mode-setting',
  SideNavCollapsed = 'side-nav-collapsed',
  AuthData = 'stenn_app_auth0',
  DevFeatures = 'stenn_app_dev_features',
}

export const enum ECookiesStorageKey {
  AuthData = 'stenn_app_auth0',
}
