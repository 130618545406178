import { ILoadedDocument } from '@app/components/features/Invoice/UploadInvoiceForm/types';
import { TStennClientsServiceApplicationPortModelsInvoiceInvoiceResponseDto } from '@app/core/__generated__';

export const getInvoicesDocuments = (
  invoice: TStennClientsServiceApplicationPortModelsInvoiceInvoiceResponseDto
): ILoadedDocument[] =>
  (invoice.attachments || []).map((document) => ({
    uid: document.id,
    name: document.fileName || '',
    documentType: document.documentType || 'Other',
    documentId: document.id as string,
  }));
