import { FC, useEffect } from 'react';

import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAvailableCurrencies } from '@app/api/hooks/useAvailableCurrencies';
import { UPLOAD_STEP_INVOICE_FILE_TYPES } from '@app/components/features/Invoice/UploadInvoiceForm/UploadStep/constants';
import { EDealType, INVOICE_NUMBER_MAX_VALUE, TEST_IDS } from '@app/core/constants';
import { Grid, MenuItem, Stack } from '@mui/material';
import {
  FormCheckboxField,
  FormControl,
  FormDatePickerField,
  FormNumericField,
  FormRadioGroupField,
  FormTextField,
  Informer,
  Typography,
} from '@stenngroup/ui-kit';

import { DealTypeSelectorTitle } from './DealTypeSelectorTitle';
import { ServiceBasedDealAlert } from './ServiceBasedDealAlert';
import { UploadStep } from './UploadStep';
import { IUploadedDocument } from './UploadStep/types';
import { getDealTypes } from './helpers/getDealTypes';
import { ILoadedDocument, IUploadInvoiceForm, IUploadInvoiceFormProps } from './types';

export const UploadInvoiceForm: FC<IUploadInvoiceFormProps> = ({ tradeRelationId }) => {
  const { formatMessage } = useIntl();

  const { control, watch, setValue, getValues } = useFormContext<IUploadInvoiceForm>();

  const handleUploadSingle = (type: 'invoiceDocument' | 'transportDocument') => (newDocument: IUploadedDocument) => {
    setValue(type, newDocument);
  };

  const handleUploadMultiple = (newDocument: IUploadedDocument) => {
    const currentDocuments = getValues('otherDocuments');
    const isExists = currentDocuments.findIndex((document) => document.uid === newDocument.uid) !== -1;
    let updatedDocuments: ILoadedDocument[] = [];
    if (isExists) {
      updatedDocuments = currentDocuments.map((document) => {
        if (document.uid === newDocument.uid) {
          return newDocument;
        }
        return document;
      });
    } else {
      updatedDocuments = [...currentDocuments, newDocument];
    }

    setValue('otherDocuments', updatedDocuments);
  };

  const handleDeleteOtherDocument = (fileUid: string) => {
    const currentDocuments = getValues('otherDocuments');

    const updatedDocuments = currentDocuments.filter((document) => document.uid !== fileUid);

    setValue('otherDocuments', updatedDocuments);
  };

  const { data } = useAvailableCurrencies({
    tradeRelationId,
    invoiceProductType: EDealType.Goods,
    cacheTime: 0,
  });

  const currencies = data ?? [];

  useEffect(() => {
    if (currencies.length === 1) {
      setValue('currency', String(currencies[0].isoNumericCode));
    }
  }, [currencies, setValue]);

  const dealTypes = getDealTypes(formatMessage);

  const invoiceDocument = watch('invoiceDocument');
  const transportDocument = watch('transportDocument');
  const otherDocuments = watch('otherDocuments');

  return (
    <Stack gap={3}>
      <UploadStep
        title={<FormattedMessage id="constants.universalMessages.invoice" />}
        documentType="CommercialInvoice"
        onChange={handleUploadSingle('invoiceDocument')}
        allowMultipleFiles={false}
        onDelete={() => setValue('invoiceDocument', null)}
        values={invoiceDocument ? [invoiceDocument] : []}
        accept={UPLOAD_STEP_INVOICE_FILE_TYPES}
      />

      <Stack gap={3}>
        <Typography.TextMd.Medium color={(t) => t.palette.text.primary}>
          <FormattedMessage id="components.Flow.InvoiceForm.invoiceDetailsTitle" />
        </Typography.TextMd.Medium>
        <Stack gap={3}>
          <FormTextField
            control={control}
            label={<FormattedMessage id="constants.universalMessages.invoiceNumber" />}
            name="number"
            data-testid={TEST_IDS.invoiceNumberInput}
          />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <FormTextField
                label={<FormattedMessage id="constants.universalMessages.currency" />}
                name="currency"
                control={control}
                disabled={currencies.length === 1}
                select
                data-testid={TEST_IDS.currencySelect}
              >
                {currencies.map((currency) => (
                  <MenuItem
                    key={currency.isoNumericCode}
                    data-testid={TEST_IDS.currencySelectItem}
                    value={String(currency.isoNumericCode)}
                  >
                    {currency.description}
                  </MenuItem>
                ))}
              </FormTextField>
            </Grid>
            <Grid item xs={12} sm={9}>
              <FormNumericField
                control={control}
                label={<FormattedMessage id="constants.universalMessages.invoiceValue" />}
                name="faceValueNationalCurrency"
                data-testid={TEST_IDS.faceValueNationalCurrencyInput}
                allowLeadingZeros={false}
                allowNegative={false}
                decimalSeparator="."
                thousandSeparator=","
                decimalScale={2}
                min={1}
                max={INVOICE_NUMBER_MAX_VALUE}
                suffix=""
              />
            </Grid>
          </Grid>
          <FormNumericField
            control={control}
            label={<FormattedMessage id="constants.universalMessages.prepaidAmount" />}
            name="prepaidAmount"
            data-testid={TEST_IDS.prepaidAmountInput}
            allowLeadingZeros={false}
            allowNegative={false}
            decimalSeparator="."
            thousandSeparator=","
            decimalScale={2}
            min={0}
            max={INVOICE_NUMBER_MAX_VALUE}
            suffix=""
          />
          <FormDatePickerField
            control={control}
            label={<FormattedMessage id="constants.universalMessages.dueDate" />}
            name="dueDate"
            data-testid={TEST_IDS.dueDateInput}
          />
        </Stack>
      </Stack>

      <Stack gap={1}>
        <Stack gap={3}>
          <FormControl>
            <FormRadioGroupField
              defaultValue={EDealType.Goods}
              name="dealType"
              options={dealTypes}
              control={control}
              label={<DealTypeSelectorTitle />}
              data-testid={TEST_IDS.dealTypeRadioGroup}
              row
              direction="row"
            />
          </FormControl>
        </Stack>
        {watch('dealType') === EDealType.Services && <ServiceBasedDealAlert />}
      </Stack>
      <Stack gap={1}>
        <Typography.TextMd.Medium color={(t) => t.palette.text.primary}>
          <FormattedMessage id="createDeal.uploadInvoices.categoryTitle" />
        </Typography.TextMd.Medium>
        <Typography.TextSm.Regular color={(t) => t.palette.text.primary}>
          <FormattedMessage id="components.Flow.InvoiceForm.categoryHint" />
        </Typography.TextSm.Regular>
        <Stack>
          <Stack direction="row" alignItems="center">
            <FormCheckboxField
              name="isCommodity"
              label={<FormattedMessage id="components.Flow.InvoiceForm.commodities" />}
              control={control}
              onChange={() => {
                setValue('isUnknownCategory', false);
              }}
              data-testid={TEST_IDS.commoditiesCheckbox}
            />
            <Informer
              trigger="click"
              size="medium"
              placement="top"
              testId={TEST_IDS.commoditiesTooltip}
              info={<FormattedMessage id="components.Flow.InvoiceForm.commoditiesHint" />}
            />
          </Stack>
          <Stack direction="row" alignItems="center">
            <FormCheckboxField
              name="isPerishable"
              label={<FormattedMessage id="components.Flow.InvoiceForm.perishables" />}
              control={control}
              onChange={() => {
                setValue('isUnknownCategory', false);
              }}
              data-testid={TEST_IDS.perishablesCheckbox}
            />
            <Informer
              trigger="click"
              placement="top"
              size="medium"
              testId={TEST_IDS.perishablesTooltip}
              info={<FormattedMessage id="components.Flow.InvoiceForm.perishablesHint" />}
            />
          </Stack>

          <FormCheckboxField
            name="isUnknownCategory"
            label={<FormattedMessage id="components.Flow.InvoiceForm.noneOfThese" />}
            control={control}
            onChange={() => {
              setValue('isCommodity', false);
              setValue('isPerishable', false);
            }}
            data-testid={TEST_IDS.noneOfTheseCheckbox}
          />
        </Stack>
      </Stack>

      <UploadStep
        title={<FormattedMessage id="createDeal.uploadInvoices.transportDocument" />}
        documentType="TransportDocument"
        onChange={handleUploadSingle('transportDocument')}
        allowMultipleFiles={false}
        onDelete={() => setValue('transportDocument', null)}
        values={transportDocument ? [transportDocument] : []}
        accept={UPLOAD_STEP_INVOICE_FILE_TYPES}
      />

      <UploadStep
        title={<FormattedMessage id="createDeal.uploadInvoices.supportingDocuments" />}
        documentType="Other"
        onChange={handleUploadMultiple}
        allowMultipleFiles={true}
        onDelete={handleDeleteOtherDocument}
        values={otherDocuments}
      />
    </Stack>
  );
};
