import { useConfiguredFetch } from '@app/api/lib/useConfiguredFetch';
import { HttpClientError } from '@app/utils/errors';
import {
  ApiRequestParams,
  ExtendedResponse,
  IApiProviderErrorLogger,
  TErrorHandler,
  TRequestHeadersExtender,
} from './types';

const fallback = () => {
  console.error('ApiProvider should be initialized with "configure" and "extendRequestHeaders" methods before usage');
};

const errorLoggerFallbackInstance: IApiProviderErrorLogger = { send: fallback };

class ApiProviderClass {
  private _errorLoggerInstance: IApiProviderErrorLogger = errorLoggerFallbackInstance;
  private _requestHeadersExtender: TRequestHeadersExtender = fallback as TRequestHeadersExtender;
  private _errorHandler: TErrorHandler = fallback;

  /**
   * Initial ApiProvider configurator
   */
  public configure({
    errorLogger,
    errorHandler,
  }: {
    errorLogger: IApiProviderErrorLogger;
    errorHandler: TErrorHandler;
  }) {
    this._errorLoggerInstance = errorLogger;
    this._errorHandler = errorHandler;
  }

  public extendRequestHeaders = (extenderFunction: TRequestHeadersExtender) => {
    this._requestHeadersExtender = extenderFunction;
  };

  /**
   * For private API calls with access token
   */
  public async apiRequest<T = any>(url: string, requestOptions: RequestInit, params?: ApiRequestParams): Promise<T>;
  public async apiRequest<T = any>(url: string, requestOptions: RequestInit): Promise<T | ExtendedResponse<T>> {
    return this.apiQueryRequest(url, requestOptions);
  }

  private configuredFetch?: ReturnType<typeof useConfiguredFetch>;

  public setConfiguredFetch = (fetch: ReturnType<typeof useConfiguredFetch>) => (this.configuredFetch = fetch);

  // For private API calls with access token (react query)
  public apiQueryRequest = async <R>(url: string, requestOptions: RequestInit = {}): Promise<R> => {
    if (!this.configuredFetch) throw new Error('Configured fetch is not set');

    const response = await this.configuredFetch(url, {
      ...requestOptions,
      headers: { 'Content-Type': 'application/json' },
    });

    if (!response.ok) {
      throw await HttpClientError.fromResponse(response);
    }

    const contentType = response.headers.get('Content-Type');

    if (contentType?.includes('application/json')) {
      return response.json();
    }

    return response.text() as unknown as R;
  };
}

export const ApiProvider = new ApiProviderClass();
