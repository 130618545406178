import { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import { ISearchCompanyForm } from '@app/components/features/SearchCompanyForm/types';
import { Dialog } from '@stenngroup/ui-kit';
import { match } from 'ts-pattern';

import { CompanyNotFoundDialogContent } from './CompanyNotFoundDialogContent';
import { ConfirmCompanyDialogContent } from './ConfirmCompanyDialogContent';
import { IneligibleCompanyDialogContent } from './IneligibleCompanyDialogContent';

type TDialogType = 'companyNotListed' | 'dnbError' | 'ineligibleCompany';

interface ISelectCompanyDialogsContainerProps {
  currentDialog: TDialogType | null;
  onClose: () => void;
  currentRole: 'Buyer' | 'Supplier' | null;
  handleLogout: () => void;
  handleSubmitNewCompany: (data: ISearchCompanyForm) => void;
  handleSearchAgain: () => void;
  defaultFormValues?: Partial<ISearchCompanyForm>;
  showLogoutButton?: boolean;
}

export const SelectCompanyDialogsContainer: FC<ISelectCompanyDialogsContainerProps> = ({
  currentDialog,
  currentRole,
  handleSearchAgain,
  handleLogout,
  handleSubmitNewCompany,
  defaultFormValues,
  onClose,
  showLogoutButton,
}) => {
  return (
    <>
      <Dialog open={currentDialog === 'companyNotListed'} onClose={onClose} fullWidth size="medium">
        {match(currentRole)
          .with('Buyer', () => (
            <CompanyNotFoundDialogContent
              onNextClick={handleSearchAgain}
              onLogoutClick={handleLogout}
              showLogoutButton={showLogoutButton}
            />
          ))
          .with('Supplier', () => (
            <ConfirmCompanyDialogContent
              onCancelClick={onClose}
              onNextClick={handleSubmitNewCompany}
              defaultFormValues={defaultFormValues}
            />
          ))
          .otherwise(() => null)}
      </Dialog>
      <Dialog open={currentDialog === 'dnbError'} onClose={onClose} fullWidth size="medium">
        <ConfirmCompanyDialogContent
          onCancelClick={onClose}
          onNextClick={handleSubmitNewCompany}
          isDunsRequired
          defaultFormValues={defaultFormValues}
        />
      </Dialog>
      <Dialog open={currentDialog === 'ineligibleCompany'} onClose={onClose} fullWidth size="medium">
        <IneligibleCompanyDialogContent
          closeLabel={<FormattedMessage id="constants.universalMessages.logOut" />}
          actionLabel={
            <FormattedMessage id="containers.BuyerSupplierFlow.steps.AddYourCompany.ineligibleCompany.yourCompany.chooseAnotherCompany" />
          }
          onClose={handleLogout}
          onAction={handleSearchAgain}
        />
      </Dialog>
    </>
  );
};
