import { FC } from 'react';

import { FormattedMessage } from 'react-intl';
import { QueryStatus } from 'react-query';

import { TCompany } from '@app/components/features/SelectCompany/CompaniesListContainer/CompaniesList/types';
import { Search } from '@app/core/__generated__';
import { Stack } from '@mui/material';
import { Typography } from '@stenngroup/ui-kit';
import { match } from 'ts-pattern';

import { CompaniesList, CompaniesListSkeleton } from './CompaniesList';

interface ICompaniesListContainerProps {
  companies: Search.SearchDunsCreate.ResponseBody | undefined;
  selectedCompany: TCompany | null;
  setSelectedCompany: React.Dispatch<React.SetStateAction<TCompany | null>>;
  newCompany: TCompany | null;
  status: QueryStatus;
}

export const CompaniesListContainer: FC<ICompaniesListContainerProps> = ({
  companies,
  selectedCompany,
  setSelectedCompany,
  newCompany,
  status,
}) => {
  return !newCompany ? (
    <Stack>
      {match(status)
        .with('loading', () => <CompaniesListSkeleton />)
        .with('error', () => null)
        .with('success', () =>
          !!companies && !!companies.length ? (
            <CompaniesList
              companies={companies}
              selectedCompany={selectedCompany}
              onCompanySelect={(company) => setSelectedCompany(company)}
            />
          ) : (
            <Typography.Body1>
              <FormattedMessage id="constants.universalMessages.noCompaniesFound" />
            </Typography.Body1>
          )
        )
        .otherwise(() => null)}
    </Stack>
  ) : (
    <CompaniesList companies={[newCompany]} selectedCompany={newCompany} />
  );
};
