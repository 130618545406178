import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode } from 'react';

type TFlowDirection = 'forward' | 'backward';

const variants = {
  enter: (direction: TFlowDirection) => ({
    x: direction === 'forward' ? '100%' : '-100%',
    opacity: 0,
  }),
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (direction: TFlowDirection) => ({
    x: direction === 'forward' ? '-100%' : '100%',
    opacity: 0,
  }),
};

const transition = {
  duration: 0.2,
  ease: 'easeInOut',
};

export const FlowTransitionsWrapper = ({
  direction,
  currentStep,
  children,
}: {
  direction: TFlowDirection;
  currentStep: string;
  children: ReactNode;
}) => {
  return (
    <AnimatePresence mode="popLayout" custom={direction} initial={false}>
      <motion.div
        key={currentStep}
        custom={direction}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={transition}
        style={{
          flexGrow: 1,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};
