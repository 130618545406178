import { TSignalrMessageType, TSubscriptionCallback } from '@app/context/signalr/types';

export const subscribeFactory =
  (subscriptions: Map<TSignalrMessageType, Map<string, TSubscriptionCallback>>) =>
  (type: TSignalrMessageType, id: string, callback: TSubscriptionCallback): void => {
    let typeSubscriptions = subscriptions.get(type);
    if (!typeSubscriptions) {
      typeSubscriptions = new Map();
      subscriptions.set(type, typeSubscriptions);
    }
    typeSubscriptions.set(id, callback);
  };
