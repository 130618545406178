import { FC } from 'react';

import { formatCurrency } from '@app/utils/formatCurrency';
import { Stack } from '@mui/material';
import { Typography } from '@stenngroup/ui-kit';

import { FormattedMessage } from 'react-intl';
import { match } from 'ts-pattern';
import { IRequestLimitTextProps } from './types';

export const RequestLimitText: FC<IRequestLimitTextProps> = ({
  status,
  totalLimit,
  requestedLimit,
  declineReason,
  fontSize = 'sm',
}) => {
  const value = match(status)
    .with('UnderReview', 'RequestingLimit', () => (requestedLimit > 0 ? requestedLimit : null))
    .with('FinancingDenied', () => null)
    .otherwise(() => totalLimit);

  const text = match(status)
    .with('Available', 'Overdue', () => <FormattedMessage id="pages.buyersPage.limit.totalLimit" />)
    .with('UnderReview', 'RequestingLimit', () =>
      value ? <FormattedMessage id="pages.buyersPage.limit.totalLimit" /> : null
    )
    .with('FinancingDenied', () =>
      match(declineReason)
        .with('FinancialMetricCheckFailed', () => (
          <FormattedMessage id="pages.buyersPage.declineReason.FinancialMetricCheckFailed" />
        ))
        .with('ActivityDurationIneligible', () => (
          <FormattedMessage id="pages.buyersPage.declineReason.ActivityDurationIneligible" />
        ))
        .with('EligibilityCriteriaNotMet', () => (
          <FormattedMessage id="pages.buyersPage.declineReason.EligibilityCriteriaNotMet" />
        ))
        .with('OrganisationalSizeIneligible', () => (
          <FormattedMessage id="pages.buyersPage.declineReason.OrganisationalSizeIneligible" />
        ))
        .with('ExternalCreditCheckFailed', () => (
          <FormattedMessage id="pages.buyersPage.declineReason.ExternalCreditCheckFailed" />
        ))
        .with('ProfitabilityMetricCheckFailed', () => (
          <FormattedMessage id="pages.buyersPage.declineReason.ProfitabilityMetricCheckFailed" />
        ))
        .with('LiquidityMetricCheckFailed', () => (
          <FormattedMessage id="pages.buyersPage.declineReason.LiquidityMetricCheckFailed" />
        ))
        .with('ExternalCreditMetricCheckFailed', () => (
          <FormattedMessage id="pages.buyersPage.declineReason.ExternalCreditMetricCheckFailed" />
        ))
        .with('PaymentBehaviourMetricCheckFailed', () => (
          <FormattedMessage id="pages.buyersPage.declineReason.PaymentBehaviourMetricCheckFailed" />
        ))
        .with('TradeRiskMetricCheckFailed', () => (
          <FormattedMessage id="pages.buyersPage.declineReason.TradeRiskMetricCheckFailed" />
        ))
        .with('CountryRiskMetricCheckFailed', () => (
          <FormattedMessage id="pages.buyersPage.declineReason.CountryRiskMetricCheckFailed" />
        ))
        .with('IndustryRiskMetricCheckFailed', () => (
          <FormattedMessage id="pages.buyersPage.declineReason.IndustryRiskMetricCheckFailed" />
        ))
        .with('CreditRiskAssessmentFailed', () => (
          <FormattedMessage id="pages.buyersPage.declineReason.CreditRiskAssessmentFailed" />
        ))
        .with('FinancingUnavailable', () => (
          <FormattedMessage id="pages.buyersPage.declineReason.FinancingUnavailable" />
        ))
        .otherwise(() => <FormattedMessage id="pages.buyersPage.declineReason.Other" />)
    )
    .otherwise(() => null);

  return (
    <Stack gap={0.5} alignItems="center" direction="row">
      {text &&
        (fontSize === 'sm' ? (
          <Typography.TextSm.Medium color="text.secondary">{text}</Typography.TextSm.Medium>
        ) : (
          <Typography.TextXs.Regular color="text.secondary">{text}</Typography.TextXs.Regular>
        ))}
      {value && (
        <Typography.Overline color="text.primary">
          {formatCurrency(value, {
            currency: 'USD',
          })}
        </Typography.Overline>
      )}
    </Stack>
  );
};
