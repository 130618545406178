import { TStennClientsServiceApplicationPortModelsDealDealResponseDto } from '@app/core/__generated__';

interface IInvoicesTotals {
  ids: string[];
  value: number;
  youWillReceive: number;
  fees: number;
  firstPayment: number;
  secondPayment: number;
  discountTotal: number;
  insuranceCharges: number;
  latePaymentFees: number;
  volumeAdjustmentFees: number;
  currencyIsoNumericCode: number;
  currencyCode: string;
  buyerName: string;
  supplierName: string;
}

export const formatDealData = (
  dealData: TStennClientsServiceApplicationPortModelsDealDealResponseDto | null | undefined
): IInvoicesTotals => {
  const isBuyer = dealData?.myCompanyRole === 'Buyer';
  const myCompanyName = dealData?.myCompanyName ?? '';
  const counterpartyCompanyName = dealData?.counterpartyCompanyName ?? '';
  const invoices = dealData?.invoices;

  return {
    ids: invoices?.map((invoice) => invoice.id!) ?? [],
    value: invoices?.reduce((amount, invoice) => amount + (invoice.totalValue ?? 0), 0) ?? 0,
    youWillReceive: dealData?.supplierReceives ?? 0,
    fees: dealData?.totalFees ?? 0,
    firstPayment: dealData?.firstPayment ?? 0,
    secondPayment: dealData?.secondPayment ?? 0,
    discountTotal: dealData?.discount ?? 0,
    insuranceCharges: dealData?.insuranceCharges ?? 0,
    latePaymentFees: dealData?.latePaymentFees ?? 0,
    volumeAdjustmentFees: dealData?.volumeAdjustmentFees ?? 0,
    currencyIsoNumericCode: dealData?.currencyIsoNumericCode ?? 0,
    currencyCode: dealData?.currencyIso3LetterCode ?? '',
    buyerName: isBuyer ? myCompanyName : counterpartyCompanyName,
    supplierName: isBuyer ? counterpartyCompanyName : myCompanyName,
  };
};
