import { CircularProgress, Stack } from '@mui/material';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { DashboardLayout } from '../DashboardLayout';

export const DashboardRoutesLayout = () => (
  <DashboardLayout>
    <Suspense
      fallback={
        <Stack flexGrow={1} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      }
    >
      <Outlet />
    </Suspense>
  </DashboardLayout>
);
