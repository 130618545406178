import { IntlFormatters } from 'react-intl';

import { searchCompanyBaseSchema } from '@app/components/features/SearchCompanyForm/baseSchema';
import { ISearchCompanyForm } from '@app/components/features/SearchCompanyForm/types';
import { isDUNSNumber } from '@stenngroup/utilities';
import * as yup from 'yup';

interface IConfirmCompanySchemaParams {
  formatMessage: IntlFormatters['formatMessage'];
  isDunsRequired?: boolean;
}

export const confirmCompanySchema = ({ formatMessage, isDunsRequired = false }: IConfirmCompanySchemaParams) =>
  searchCompanyBaseSchema(formatMessage).shape<Record<keyof Pick<ISearchCompanyForm, 'duns'>, yup.AnySchema>>({
    duns: yup
      .string()
      .test('duns', formatMessage({ id: 'components.NotListedCompany.dunsNumberMustBeNumerical' }), (value) => {
        if (isDunsRequired) {
          return !!value && isDUNSNumber(value);
        }
        return value ? isDUNSNumber(value) : true;
      }),
  });
