import { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import { Typography } from '@stenngroup/ui-kit';

export const DealTypeSelectorTitle: FC = () => {
  return (
    <Typography.TextMd.Medium color={(t) => t.palette.text.primary} marginRight={1}>
      <FormattedMessage id="components.Flow.InvoiceForm.DealType.dealTypeTitle" />
    </Typography.TextMd.Medium>
  );
};
