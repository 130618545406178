import { FC, useEffect } from 'react';
import { match } from 'ts-pattern';

import { FlowTransitionsWrapper } from '@app/components/ui/FlowTransitionsWrapper';

import { EFlowStep } from '@app/context/FlowTransitionsContext';
import { useFlowTransitionsContext } from '@app/hooks/useFlowTransitionsContext';
import { AddBankDetails } from './screens/AddBankDetails';
import { AddCompanySignatory } from './screens/AddCompanySignatory';
import { AddTradePartnerSignatory } from './screens/AddTradePartnerSignatory';
import { DealSubmitted } from './screens/DealSubmitted';
import { DocumentsRequired } from './screens/DocumentsRequired';
import { InviteTradePartner } from './screens/InviteTradePartner';
import { InvoicesList } from './screens/InvoicesList';
import { RequestLimit } from './screens/RequestLimit';
import { ReviewDeal } from './screens/ReviewDeal';
import { SearchTradePartner } from './screens/SearchTradePartner';
import { SelectNewTradePartner } from './screens/SelectNewTradePartner';
import { SelectTradePartner } from './screens/SelectTradePartner';
import { SelectTradeRole } from './screens/SelectTradeRole';
import { TradePartnerCreated } from './screens/TradePartnerCreated';
import { TradePartnerInvited } from './screens/TradePartnerInvited';
import { UploadInvoice } from './screens/UploadInvoice';

export const FlowTransitions: FC = () => {
  const { currentStep, direction, reset } = useFlowTransitionsContext();

  useEffect(() => {
    return () => reset();
  }, [reset]);

  return (
    <FlowTransitionsWrapper direction={direction} currentStep={currentStep ?? ''}>
      {match(currentStep)
        .with(EFlowStep.TRADE_ROLE, () => <SelectTradeRole />)
        .with(EFlowStep.SEARCH_TRADE_PARTNER, () => <SearchTradePartner />)
        .with(EFlowStep.SELECT_NEW_TRADE_PARTNER, () => <SelectNewTradePartner />)
        .with(EFlowStep.REQUEST_LIMIT, () => <RequestLimit />)
        .with(EFlowStep.TRADE_PARTNER_CREATED, () => <TradePartnerCreated />)
        .with(EFlowStep.SELECT_TRADE_PARTNER, () => <SelectTradePartner />)
        .with(EFlowStep.UPLOAD_INVOICE, () => <UploadInvoice />)
        .with(EFlowStep.INVOICES_LIST, () => <InvoicesList />)
        .with(EFlowStep.DOCUMENTS_REQUIRED, () => <DocumentsRequired />)
        .with(EFlowStep.ADD_COMPANY_SIGNATORY, () => <AddCompanySignatory />)
        .with(EFlowStep.ADD_TRADE_PARTNER_SIGNATORY, () => <AddTradePartnerSignatory />)
        .with(EFlowStep.ADD_BANK_DETAILS, () => <AddBankDetails />)
        .with(EFlowStep.REVIEW_DEAL, () => <ReviewDeal />)
        .with(EFlowStep.DEAL_SUBMITTED, () => <DealSubmitted />)
        .with(EFlowStep.INVITE_TRADE_PARTNER, () => <InviteTradePartner />)
        .with(EFlowStep.TRADE_PARTNER_INVITED, () => <TradePartnerInvited />)
        .otherwise(() => null)}
    </FlowTransitionsWrapper>
  );
};
