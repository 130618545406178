import { type FC } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, FormCheckboxField, FormTextField, Typography } from '@stenngroup/ui-kit';

import { yupResolver } from '@hookform/resolvers/yup';
import { ESignatoryType, IContactForm } from '../../types';

import { useConfiguredFetch } from '@app/api/lib/useConfiguredFetch';
import { FormContainer } from '@app/components/features/FlowTransitions/screens/AddCompanySignatory/Form/FormContainer';
import { isPublicEmailRequest } from '@app/components/features/FlowTransitions/screens/AddCompanySignatory/Form/mutations/isPublicEmailRequest';
import { SignatoryTypeSelector } from '@app/components/features/FlowTransitions/screens/AddCompanySignatory/SignatoryTypeSelector';
import { EFlowStep } from '@app/context/FlowTransitionsContext';
import { TEST_IDS } from '@app/core/constants/testIds';
import { useFlowTransitionsContext } from '@app/hooks/useFlowTransitionsContext';
import { ArrowForward } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { useMutation } from 'react-query';
import { inviteNewContactAndMakeSignerRequest } from './mutations/inviteNewContactAndMakeSignerRequest';
import { anotherPersonSignatorySchema } from './schema';

interface IProps {
  onSignatoryTypeChange: (signatoryType: ESignatoryType) => void;
  signatoryType: ESignatoryType;
  onRefetchProfile: () => void;
}

export const AnotherPersonForm: FC<IProps> = ({ onSignatoryTypeChange, signatoryType, onRefetchProfile }) => {
  const { formatMessage } = useIntl();
  const configuredFetch = useConfiguredFetch();
  const { handleNextStep, getStateByStep } = useFlowTransitionsContext();

  const anotherPersonFormState = getStateByStep<{ form: IContactForm; signatoryType: ESignatoryType } | null>(
    EFlowStep.ADD_COMPANY_SIGNATORY
  );

  const isStepCompleted = !!anotherPersonFormState;

  const isFormDisabled = isStepCompleted;

  const { mutateAsync: isPublicEmail, isLoading: isPublicEmailLoading } = useMutation({
    mutationFn: (email: string) => isPublicEmailRequest({ email, configuredFetch }),
  });

  const { mutate: makeSigner, isLoading: isMakeSignerLoading } = useMutation({
    mutationFn: (data: IContactForm) => {
      return inviteNewContactAndMakeSignerRequest({
        configuredFetch,
        signer: {
          ...data,
          emailAddress: data.email,
          invite: data.checkbox,
        },
      });
    },
    onSuccess: (_, values) => {
      handleNextStep({ stepState: { form: values, signatoryType } });
      onRefetchProfile();
    },
  });

  const { control, handleSubmit, setError } = useForm<IContactForm>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(anotherPersonSignatorySchema(formatMessage)),
    defaultValues: { checkbox: true, ...anotherPersonFormState?.form },
  });

  const handleFormSubmit = handleSubmit(async (data) => {
    if (isStepCompleted) {
      return handleNextStep({ stepState: { form: data, signatoryType } });
    }
    const isPublic = await isPublicEmail(data.email);
    if (isPublic) {
      setError(
        'email',
        {
          message: formatMessage({ id: 'constants.frontendErrors.wrongEmailFormat' }),
        },
        { shouldFocus: true }
      );
      return null;
    }
    makeSigner(data);
  });

  const isLoading = isPublicEmailLoading || isMakeSignerLoading;

  return (
    <FormContainer
      radioGroup={
        <SignatoryTypeSelector
          signatoryType={signatoryType}
          setSignatoryType={onSignatoryTypeChange}
          disabled={isFormDisabled}
        />
      }
      actions={
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          onClick={handleFormSubmit}
          variant="contained"
          size="medium"
          endIcon={<ArrowForward />}
          data-testid={TEST_IDS.submitButton}
        >
          <FormattedMessage id="constants.universalMessages.next" />
        </Button>
      }
    >
      <form>
        <Stack spacing={3}>
          <Typography.TextMd.Medium>
            <FormattedMessage id="createDeal.addTradePartnerSignatory.form.description" />
          </Typography.TextMd.Medium>

          <Stack spacing={2}>
            <FormTextField
              name="firstName"
              control={control}
              label={<FormattedMessage id="createDeal.addTradePartnerSignatory.form.firstNameField" />}
              disabled={isFormDisabled}
              data-testid={TEST_IDS.firstNameInput}
            />
            <FormTextField
              name="lastName"
              control={control}
              label={<FormattedMessage id="createDeal.addTradePartnerSignatory.form.lastNameField" />}
              disabled={isFormDisabled}
              data-testid={TEST_IDS.lastNameInput}
            />
            <FormTextField
              name="position"
              control={control}
              label={<FormattedMessage id="createDeal.addTradePartnerSignatory.form.positionField" />}
              disabled={isFormDisabled}
              data-testid={TEST_IDS.positionInput}
            />
          </Stack>
          <Stack spacing={2}>
            <Typography.TextMd.Medium>
              <FormattedMessage id="createDeal.addTradePartnerSignatory.form.emailField.description" />
            </Typography.TextMd.Medium>
            <Stack spacing={2}>
              <FormTextField
                name="email"
                control={control}
                label={<FormattedMessage id="createDeal.addTradePartnerSignatory.form.emailField" />}
                disabled={isFormDisabled}
                data-testid={TEST_IDS.emailInput}
              />

              <FormTextField
                name="website"
                control={control}
                label={<FormattedMessage id="containers.SupplierFlow.steps.YourAuthorisedSignatory.website" />}
                disabled={isFormDisabled}
                data-testid={TEST_IDS.websiteInput}
              />
            </Stack>
          </Stack>
          <FormCheckboxField
            name="checkbox"
            control={control}
            label={
              <FormattedMessage id="containers.SupplierFlow.steps.YourAuthorisedSignatory.inviteAuthorizedSignatory" />
            }
            disabled={isFormDisabled}
            data-testid={TEST_IDS.confirmCheckbox}
          />
        </Stack>
      </form>
    </FormContainer>
  );
};
