export const enum TEST_IDS {
  button = 'button',
  loginSignupButton = 'loginSignup_button',
  signupButton = 'signup_button',
  signupPhoneButton = 'signupPhone_button',
  signupGoogleButton = 'signupGoogle_button',
  signupFacebookButton = 'signupFacebook_button',
  loginButton = 'login_button',
  logoutButton = 'logout_button',
  loginPhoneButton = 'loginPhone_button',
  closeButton = 'close_button',
  cancelButton = 'cancel_button',
  submitButton = 'submit_button',
  addButton = 'add_button',
  nextButton = 'next_button',
  backButton = 'back_button',
  unknownButton = 'unknown_button',
  notListedButton = 'not_listed_button',
  uploadButton = 'upload_button',
  uploadLaterButton = 'upload_later_button',
  convertIntoCacheButton = 'convertIntoCache_button',
  inviteSupplierButton = 'inviteSupplier_button',
  learnMoreButton = 'learn_more_button',
  doneButton = 'done_button',
  addUserButton = 'add_user_button',
  switchButton = 'switch_button',
  toggleButton = 'toggle_button',
  decrementButton = 'decrement_button',
  incrementButton = 'increment_button',
  infoButton = 'info_button',
  clickHereButton = 'click_here_button',
  editButton = 'edit_button',
  acceptButton = 'accept_button',
  manageButton = 'manage_button',
  redirectButton = 'redirect_button',
  linkButton = 'link_button',
  flowNoCompanyLink = 'flow_no_company_link',
  triggerButton = 'trigger_button',
  dropdownButton = 'dropdown_button',
  resetButton = 'reset_button',
  removeButton = 'remove_button',
  langButton = 'lang_button',
  previewButton = 'preview_button',
  filterButton = 'filter_button',
  selectButton = 'select_button',
  sortingButton = 'sorting_button',
  logoButton = 'logo_button',
  declineButton = 'decline_button',
  stepButton = 'step_button',
  langSelectorButton = 'lang_selector_button',
  shiftButton = 'shift_button',
  hideButton = 'hide_button',
  pageButton = 'page_button',
  openButton = 'open_button',
  tabButton = 'tab_button',
  modalOkButton = 'modal_ok_button',
  modalAddButton = 'modal_add_button',
  modalBackButton = 'modal_back_button',
  dialogModalButtons = 'dialog_modal_button',
  dialogModal = 'dialog_modal',
  googleSocial = 'google_social',
  socialsContainer = 'socials_container',
  facebookSocial = 'facebook_social',
  backToLoginButton = 'back_to_login_button',
  changePasswordButton = 'change_password_button',
  resendCodeButton = 'resend_code_button',
  backToSignupButton = 'back_to_signup_button',
  forgotPasswordButton = 'forgot_password_button',
  forgotPasswordDigitCodeDesc = 'forgot_password_digit_code_desc',
  changeYourCompanyInfo = 'change_your_company_info',
  passwordErrorMessage = 'password_error_message',
  newDealMainButton = 'new_deal_main_button',
  newDealSupplierButton = 'new_deal_supplier_button',
  newDealBuyerButton = 'new_deal_buyer_button',
  addAnotherSupplierButton = 'add_another_supplier_button',

  formAddEditCompany = 'form_add_edit_company',

  emailInput = 'email_input',
  firstNameInput = 'firstName_input',
  lastNameInput = 'lastName_input',
  passwordInput = 'password_input',
  companyNameInput = 'companyName_input',
  countryCodeInput = 'countryCode_input',
  stateInput = 'state_input',
  websiteInput = 'website_input',
  noWebsiteInput = 'noWebsite_input',
  searchInput = 'search_input',
  positionInput = 'position_input',
  invoiceNumberInput = 'invoiceNumber_input',
  ammountInvoiceValue = 'amount_invoice_value',
  phoneNumberInput = 'phoneNumber_input',
  phoneMaskInput = 'phoneMask_input',
  beneficiaryAccountInput = 'beneficiaryAccount_input',
  swiftInput = 'swift_input',
  bankNameInput = 'bankName_input',
  beneficiaryNameInput = 'beneficiaryName_input',
  beneficiaryAddress1Input = 'beneficiaryAddress1_input',
  beneficiaryAddress2Input = 'beneficiaryAddress2_input',
  dateInput = 'date_input',
  faceValueInput = 'faceValue_input',
  fileSelectInput = 'file_select_input',
  rangeInput = 'range_input',
  otpCodeInput = 'otp_code_input',
  textInput = 'text_input',

  accountingsSoftwareItem = 'accountings_software_item',
  bddBuyersListItem = 'bdd_buyers_list_item',
  bddSuppliersListItem = 'bdd_suppliers_list_item',
  breadcrumbItem = 'breadcrumb_item',
  buyersListItem = 'buyers_list_item',
  buyersNotificationsListItem = 'buyers_notifications_list_item',
  companyItem = 'company_item',
  contactsRepresentativeListItem = 'contacts_representative_list_item',
  dealInvoicesListItem = 'deal_invoices_list_item',
  dealsListItem = 'deal_list_item',
  listItem = 'list_item',

  goToDashboardBtn = 'go_to_dashboard_btn',
  copyLinkBtn = 'copy_link_btn',
  sendInvitationBtn = 'send_invitation_btn',

  companiesListHeader = 'companies_list_header',

  dropdownBlock = 'dropdown_block',

  templateLink = 'template_link',
  privacyPolicyLink = 'privacy_policy_link',

  agreementCheckbox = 'agreement_checkbox',
  beSignerChecbkox = 'beSigner_checkbox',
  authCheckbox = 'auth_checkbox',

  dealTypeRadio = 'dealType_radio',
  companyRadio = 'company_radio',
  dealBranchLabel = 'dealBranchLabel',
  uploadFile = 'upload_file',
  modalContainer = 'modal_container',
  profile = 'profile',
  profileAddUserButton = 'profile_add_user_button',

  tab = 'tab',
  tabPanel = 'tab_panel',
  tabContent = 'tab_content',

  bankDetailsCard = 'bank_details_card',
  bankDetailsAddEditButton = 'bank_details_button',

  impersonateEmailInput = 'impersonate_email_input',
  impersonateEmailButton = 'impersonate_email_button',

  modal = 'modal',
  tooltip = 'tooltip',
  informer = 'informer',
  anotherPersonAuthorizedSignatory = 'another_person_authorized_signatory',
  iAmAuthorizedSignatory = 'i_am_authorized_signatory',
  isWorkEmail = 'is_work_email',
  isNotWorkEmail = 'is_not_work_email',
  iAmNextButton = 'i_am_next_button',
  anotherPersonNextButton = 'another_person_next_button',
  sendSignatoryInvitation = 'send_signatory_invitation',
  titleSelectInput = 'title_selectInput',
  fullNameInput = 'fullName_input',
  birthdayDateInput = 'birthday_dateInput',
  ssnInput = 'ssn_input',
  positionInCompanySelectInput = 'positionInCompany_selectInput',
  cellPhoneNumberInput = 'cellPhoneNumber_input',
  isAuthorizedSeekFinancingCheckbox = 'isAuthorizedSeekFinancing_checkbox',
  isOnlyOneOwnerCheckbox = 'isOnlyOneOwner_checkbox',
  corporateEmailInput = 'corporateEmail_input',
  confirmCorporateEmailButton = 'confirmCorporateEmail_button',
  addressAutocomplete = 'address_autocomplete',
  zipCodeInput = 'zipCode_input',
  cityInput = 'city_input',
  digitCodeInput = 'digitCode_input',
  businessLegalNameInput = 'businessLegalName_input',
  dbaInput = 'dba_input',
  revenueSelectInput = 'revenue_selectInput',
  mailingAddressEqualsBillingCheckbox = 'mailingAddressEqualsBilling_checkbox',
  disconnectButton = 'disconnect_button',
  moreButton = 'more_button',
  abortButton = 'abort_button',
  applyButton = 'apply_button',
  youAreReadyToGoBackButton = 'youAreReadyToGoBack_button',
  youAreReadyToGoApplyButton = 'youAreReadyToGoApply_button',
  editInfoAboutCompanyButton = 'editInfoAboutCompany_button',
  addSignatoryInfoButton = 'addSignatoryInfo_button',
  editSignatoryInfoButton = 'editSignatoryInfo_button',
  connectButton = 'connect',
  mailingAddress = 'mailingAddress',
  businessAddress = 'businessAddress',
  letsGoButton = 'letsGo_button',
  businessTypeSelectInput = 'businessType_selectInput',
  repeatPasswordInput = 'repeatPassword_input',
  inviteTeamMemberButton = 'inviteTeamMember_button',
  companyNotFoundModal = 'company_not_found_modal',
  dunsInput = 'duns_input',
  emailSentPopup = 'email_sent_popup',
  roleSelectCardSupplierRadio = 'role_select_card_supplier_radio',
  roleSelectCardSupplierViaBtn = 'role_select_card_supplier_via_btn',
  roleSelectCardBuyerRadio = 'role_select_card_buyer_radio',
  roleSelectCardBuyerViaBtn = 'role_select_card_buyer_via_btn',

  searchCompanyTitle = 'search_company_title',
  searchCompanyDescription = 'search_company_description',
  companyNameInChineseInput = 'company_name_in_chinese_input',
  unifiedSocialCreditCodeInput = 'unified_social_credit_code_input',
  corporateIdentificationNumber = 'corporate_identification_number',
  localityInput = 'locality_input',
  selectCompanyTitle = 'select_company_title',
  selectCompanySubtitle = 'select_company_subtitle',
  providedCompanyInfoTitle = 'provided_company_info_title',
  providedCompanyInfoLocation = 'provided_company_info_location',
  providedCompanyInfoCountry = 'provided_company_info_country',
  providedCompanyInfoButton = 'provided_company_info_button',
  companiesListContainer = 'companies_list_container',
  companiesListContainerSkeleton = 'companies_list_container_skeleton',

  addYourCompanyAlreadyRegisteredTitle = 'add_your_company_already_registered_title',
  addYourCompanyAlreadyRegisteredCompanyName = 'add_your_company_already_registered_company_name',
  addYourCompanyAlreadyRegisteredDuns = 'add_your_company_already_registered_duns',
  addYourCompanyAlreadyRegisteredLocation = 'add_your_company_already_registered_location',
  addYourCompanyAlreadyRegisteredStreet = 'add_your_company_already_registered_street',
  addYourCompanyAlreadyRegisteredAlert = 'add_your_company_already_registered_alert',
  addYourCompanyAlreadyRegisteredReturnBtn = 'add_your_company_already_registered_return_btn',
  addYourCompanyAlreadyRegisteredSubmitBtn = 'add_your_company_already_registered_submit_btn',
  addYourCompanyAlreadyRegisteredCancelBtn = 'add_your_company_already_registered_cancel_btn',
  availableCompaniesList = 'available-companies-list',
  desiredInformation = 'desired_information',
  companiesGeneralInfoCompanyName = 'companies_general_info_company_name',
  faceValueNationalCurrencyInput = 'face_value_national_currency_input',
  prepaidAmountInput = 'prepaid_amount_input',
  currencySelect = 'currency_select',
  currencySelectItem = 'currency_select_item',
  dueDateInput = 'due_date_input',
  dealTypeRadioGroup = 'deal_type_radio_group',
  discardChangesDialog = 'discard_changes_dialog',
  discardBtn = 'discard_btn',
  dropzone = 'dropzone',
  serviceBasedAlert = 'service_based_alert',
  cancelDiscardChangesBtn = 'cancel_discard_changes_btn',
  discardChangesBtn = 'discard_changes_btn',
  dealTypeSelectorTitleTooltip = 'deal_type_selector_title_tooltip',
  invoiceDetailsNumber = 'invoice_details_number',
  invoiceDetailsDeleteButton = 'invoice_details_delete_button',
  invoiceDetailsEditButton = 'invoice_details_edit_button',
  invoiceDetailsAccordionSummary = 'invoice_details_accordion_summary',
  invoiceDetailsExpandIcon = 'invoice_details_expand_icon',
  invoiceDetailsFilename = 'invoice_details_filename',
  invoiceDetailsAccordionDetails = 'invoice_details_accordion_details',
  invoiceDetailsInvoiceFaceValue = 'invoice_details_invoice_face_value',
  invoiceDetailsDueDate = 'invoice_details_due_date',
  invoiceDetailsInvoiceNumber = 'invoice_details_invoice_number',
  invoiceDetailsOtherDocumentsFilename = 'invoice_details_other_documents_filename',
  invoiceDetailsDeleteModal = 'invoice_details_delete_modal',
  invoiceDetailsEditModal = 'invoice_details_edit_modal',
  invoiceDetailsContainer = 'invoice_details_container',
  deleteInvoiceModalContentTitle = 'delete_invoice_modal_content_title',
  deleteInvoiceModalContentTitleText = 'deleteInvoiceModalContentTitleText',
  deleteInvoiceModalContentOtherDocumentsFilename = 'deleteInvoiceModalContentOtherDocumentsFilename',
  deleteInvoiceModalContentCancelBtn = 'deleteInvoiceModalContentCancelBtn',
  deleteInvoiceModalContentDeleteBtn = 'deleteInvoiceModalContentDeleteBtn',
  deleteInvoiceModalContentOtherDocument = 'deleteInvoiceModalContentOtherDocument',
  invoiceDetailsOtherDocument = 'invoice_details_other_document',
  invoicesList = 'invoices_list',
  invoiceDetailsOtherDocumentsContainer = 'invoice_details_other_documents_container',
  uploadAnotherInvoiceBtn = 'upload_another_invoice_btn',
  preofferWarning = 'preoffer_warning',
  financingInfoPopup = 'financing_info_popup',
  dealInfoRowTitle = 'deal_info_row_title',
  dealInfoRowTitleHint = 'deal_info_row_title_hint',
  dealInfoRowSubtitle = 'deal_info_row_subtitle',
  dealInfoRowValue = 'deal_info_row_value',
  youWillReceiveWidgetReceiveAmountTitle = 'you_will_receive_widget_receive_amount_title',
  youWillReceiveWidgetFirstPaymentTitle = 'you_will_receive_widget_first_payment_title',
  youWillReceiveWidgetFirstPaymentValue = 'you_will_receive_widget_first_payment_value',
  youWillReceiveWidgetSecondPaymentTitle = 'you_will_receive_widget_second_payment_title',
  youWillReceiveWidgetSecondPaymentValue = 'you_will_receive_widget_second_payment_value',
  feesWidgetSecondaryValue = 'fees_widget_secondary_value',
  feesWidgetTitle = 'fees_widget_title',
  feesWidgetFeesAmountTitle = 'fees_widget_fees_amount_title',
  feesWidgetDiscountExperimentalTitle = 'fees_widget_discount_experimental_title',
  feesWidgetDiscountTitle = 'fees_widget_discount_title',
  feesWidgetDiscountValue = 'fees_widget_discount_value',
  feesWidgetVolumeAdjustmentFeesTitle = 'fees_widget_volume_adjustment_fees_title',
  feesWidgetVolumeAdjustmentFeesValue = 'fees_widget_volume_adjustment_fees_value',
  feesWidgetInsuranceChargesTitle = 'fees_widget_insurance_charges_title',
  feesWidgetInsuranceChargesValue = 'fees_widget_insurance_charges_value',
  feesWidgetLatePaymentFeesTitle = 'fees_widget_late_payment_fees_title',
  feesWidgetLatePaymentFeesValue = 'fees_widget_late_payment_fees_value',
  beSignerChecbkoxError = 'beSignerChecbkox_error',
  authorisedSignatoryHint = 'authorised_signatory_hint',
  bankNameText = 'bank_name_text',
  inviteOrContinuePageRadioGroup = 'invite_or_continue_page_radio_group',
  inviteOrContinuePageNextButton = 'invite_or_continue_page_next_button',
  inviteSupplierPageTitle = 'invite_supplier_page_title',
  inviteSupplierPageSubtitle = 'invite_supplier_page_subtitle',
  inviteSupplierPageNextButton = 'invite_supplier_page_next_button',
  supplierInvitationPageEmail = 'supplier_invitation_page_email',
  supplierInvitationPageSendInvitationButton = 'supplier_invitation_page_send_invitation_button',
  supplierInvitationPageLink = 'supplier_invitation_page_link',
  supplierInvitationPageCopyLinkButton = 'supplier_invitation_page_copy_link_button',
  supplierInvitationPageGoToDashboardButton = 'supplier_invitation_page_go_to_dashboard_button',
  supplierInvitationPageLinkCopiedAlert = 'supplier_invitation_page_link_copied_alert',
  supplierInvitationPageInvitationSentAlert = 'supplier_invitation_page_invitation_sent_alert',
  saveButton = 'save_button',
  ineligibleCompanyDialog = 'ineligible_company_dialog',
  ineligibleCompanyDialogTitle = 'ineligible_company_dialog_title',
  ineligibleCompanyDialogDescription = 'ineligible_company_dialog_description',
  ineligibleCompanyDialogButtonCancel = 'ineligible_company_dialog_button_cancel',
  ineligibleCompanyDialogButton = 'ineligible_company_dialog_button',
  commoditiesTooltip = 'commodities_tooltip',
  perishablesTooltip = 'perishables_tooltip',
  commoditiesCheckbox = 'commodities_checkbox',
  perishablesCheckbox = 'perishables_checkbox',
  noneOfTheseCheckbox = 'none_of_these_checkbox',
  onboardingLayoutLogoutButton = 'onboarding_layout_logout_button',
  loginPage = 'login_page',
  loginErrorText = 'login_error_text',
  signupPage = 'signup_page',
  termsCheckbox = 'terms_checkbox',
  authForm = 'auth_form',
  userAlreadyExistsError = 'user_already_exists_error',
  signupErrorText = 'signup_error_text',
  forgotPasswordPage = 'forgot_password_page',
  forgotPasswordPageEmailConfirmation = 'forgot_password_page_email_confirmation',
  acceptInvitationPage = 'accept_invitation_page',
  emailConfirmationPage = 'email_confirmation_page',
  emailConfirmationPageEmail = 'email_confirmation_page_email',
  socialAccountActivationPage = 'social_account_activation_page',
  changePasswordPage = 'change_password_page',
  confirmPasswordInput = 'confirm_password_input',
  passwordChangeSuccessfulPage = 'password_change_successfull_page',
  signButton = 'sign_button',
  dealStatusExtended = 'deal_status_extended',
  dealStatusMain = 'deal_status_main',
  additionalInfoRequired = 'additional_info_required',

  //=InvoiceList
  invoiceAccordionSummary = 'invoice_accordion_details_summary',
  invoiceAccordionDetails = 'invoice_accordion_details_details',

  //=personal space
  personalSpaceMenuItem = 'personal_space_menu_item',
  personalSpaceTitle = 'personal_space_title',
  personalSpaceCloseButton = 'personal_space_close_button',
  personalSpaceCompanyName = 'personal_space_company_name',
  personalSpaceCompanyStatus = 'personal_space_company_status',
  personalSpaceAddUserButton = 'personal_space_add_user_button',
  personalSpaceAddUserDialog = 'personal_space_add_user_dialog',
  teamRequestsTitle = 'team_requests_title',
  resendInvitationButton = 'resend_invitation_button',
  personalSpaceEditUserDialog = 'personal_space_edit_user_dialog',
  teamMemberRequestCard = 'team_member_request_card',
  teamMemberCard = 'team_member_card',
  //=sidebar
  dashboardLayoutAddDealButton = 'dashboard_layout_add_deal_button',
  dashboardLayoutAsABuyerMenuItem = 'dashboard_layout_as_a_buyer_menu_item',
  dashboardLayoutAsASupplierMenuItem = 'dashboard_layout_as_a_supplier_menu_item',

  //=suppliers page
  supplierPageAddSupplierButton = 'supplier_page_add_supplier_button',

  addDealButton = 'add_deal_button',
  counterpartyCompanyName = 'counterparty_company_name',
  dashboardActionRequiredTitle = 'dashboard_action_required_title',
  dealStatus = 'deal_status',
  invoiceStatus = 'invoice_status',
  dealAttachmentDocument = 'deal_attachment_document',
  dealAttachmentStatus = 'deal_attachment_status',

  invoiceCard = 'invoice_card',
  invoiceDetails = 'invoice_details',
  invoiceDetailsCol = 'invoice_details_col',

  dealCard = 'deal_card',
  dealDetails = 'deal_card_details',
  dealDetailsCol = 'deal_card_details_col',

  companyName = 'company_name',
  companyStatus = 'company_status',
  roleSelection = 'role_selection',
  termsLink = 'terms_link',
  socialAccountActivationForm = 'social_account_activation_form',
  dealFlow = 'deal_flow',
  selectTradePartner = 'select_trade_partner',
  selectTradePartnerRecentlyAdded = 'select_trade_partner_recently_added',
  dealFlowLimitInput = 'deal_flow_limit_input',
  invoiceForm = 'invoice_form',
  personalSpace = 'personal_space',
  tradePartnerCreated = 'trade_partner_created',
  companyNameLoading = 'company_name_loading',
  suppliersPage = 'suppliers_page',
  textSkeleton = 'text_skeleton',
  tabsFinancingDenied = 'tabs_financingdenied_tab',
  supplierDetailsLayout = 'supplier_details_layout',
  supplierDetailsInformationTab = 'tabs_/suppliers/:id/information_tab',
  supplierDetailsInvoicesTab = 'tabs_/suppliers/:id/invoices_tab',
  supplierDetailsDealsTab = 'tabs_/suppliers/:id/deals_tab',
  supplierDetailsInformationPage = 'supplier_details_information_page',
  dealsList = 'deals_list',
  invitationDate = 'invitation_date',
  invitationAcceptedDate = 'invitation_accepted_date',
  countryName = 'country_name',
  email = 'email',
  address = 'address',
  onboardingLayoutMainContent = 'onboarding_layout_main_content',
  personalSpaceFinanceData = 'personal_space_finance_data',
  requestedLimit = 'requested_limit',
  financeData = 'finance_data',
  financeGraph = 'finance_graph',
  financeStat = 'finance_stat',
  personalSpaceTeam = 'personal_space_team',
  personalSpaceTeamRequests = 'personal_space_team_requests',
  personalSpaceTeamMembers = 'personal_space_team_members',
  personalSpaceCompanyInfo = 'personal_space_company_info',
  addUserDialogContent = 'add_user_dialog_content',
  editUserDialogContent = 'edit_user_dialog_content',
  dealSubmitted = 'deal_submitted',
  viewDealsButton = 'view_deals_button',
  inviteTradePartner = 'invite_trade_partner',
  requestLimitInput = 'request_limit_input',
  requestLimitScreen = 'request_limit_screen',
  companyDetails = 'company_details',
  requestNewLimitForm = 'request_new_limit_form',
  requestLimitButton = 'request_limit_button',
  requestedLimitText = 'requested_limit_text',
  requestLimitForm = 'request_limit_form',
  suppliersPageNotAllowedToAddSupplierDialog = 'suppliers_page_not_allowed_to_add_supplier_dialog',
  addCompanySignatoryScreen = 'add_company_signatory_screen',
  addCompanySignatoryRadioGroup = 'add_company_signatory_radio_group',
  confirmCheckbox = 'confirm_checkbox',
  otpDialog = 'otp_dialog',
  companyNotListedButton = 'company_not_listed_button',

  documentSignedDocuSign = 'document_signed_DocuSign',
  documentCancelledDocuSign = 'document_cancelled_DocuSign',
  documentDeclinedDocuSign = 'document_declined_DocuSign',

  queryClientRequestError = 'query_client_request_error',
  acceptPoliciesRequestError = 'accept_policies_request_error',

  sendInvitationRequestSuccess = 'send_invitation_request_success',
  sendInvitationRequestError = 'send_invitation_request_error',

  addUserRequestError = 'add_user_request_error',

  acceptContactRequestSuccess = 'accept_contact_request_success',
  acceptContactRequestError = 'accept_contact_request_error',

  declineContactRequestSuccess = 'decline_contact_request_success',
  declineContactRequestError = 'decline_contact_request_error',

  editContactRequestSuccess = 'edit_contact_request_success',
  editContactRequestError = 'edit_contact_request_error',

  changePasswordOTPRequestError = 'change_password_otp_request_error',
}
