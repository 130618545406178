import { CircularProgress, Stack } from '@mui/material';
import { FC, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { OnboardingLayoutV3 } from '../OnboardingLayoutV3';

export const OnboardingRoutesLayout: FC<{ isLogoutButtonShown: boolean }> = ({ isLogoutButtonShown }) => (
  <OnboardingLayoutV3 isLogoutButtonShown={isLogoutButtonShown}>
    <Suspense
      fallback={
        <Stack flexGrow={1} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      }
    >
      <Outlet />
    </Suspense>
  </OnboardingLayoutV3>
);
