import { useCallback } from 'react';

import { FetchError } from '@app/utils/errors';
import { useAuth } from '@stenngroup/auth0-sdk';

export const useConfiguredFetch = (): ((...args: Parameters<typeof fetch>) => ReturnType<typeof fetch>) => {
  const { getAccessTokenSilently } = useAuth();

  return useCallback(
    async (...args: Parameters<typeof fetch>): ReturnType<typeof fetch> => {
      const [input, requestInit] = args;

      const headers: HeadersInit = {
        'X-Page-Referer': window.location.pathname,
      };
      let token: string | null = null;
      try {
        token = await getAccessTokenSilently();
      } catch (error) {
        console.error('Error getting access token', error);
      }

      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      }

      let res: Response;
      try {
        res = await fetch(input, {
          ...requestInit,
          headers: {
            ...requestInit?.headers,
            ...headers,
          },
        });
      } catch (error) {
        throw new FetchError(error as Error);
      }

      return res;
    },
    [getAccessTokenSilently]
  );
};
