import { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import { TEST_IDS } from '@app/core/constants';
import { Button, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@stenngroup/ui-kit';

interface IIneligibleTradeRelationDialogContentProps {
  onClose: () => void;
}

export const IneligibleTradeRelationDialogContent: FC<IIneligibleTradeRelationDialogContentProps> = ({ onClose }) => {
  return (
    <>
      <DialogTitle
        gap={2}
        sx={{
          py: 1,
        }}
        width="100%"
        alignItems="center"
        data-testid={TEST_IDS.ineligibleCompanyDialog}
      >
        <Typography.H5>
          <FormattedMessage
            data-testid={TEST_IDS.ineligibleCompanyDialogTitle}
            id="createDeal.selectTradePartner.ineligibleTradeRelationDialog.title"
          />
        </Typography.H5>
      </DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          <Typography.Body1 data-testid={TEST_IDS.ineligibleCompanyDialogDescription}>
            <FormattedMessage id="createDeal.selectTradePartner.ineligibleTradeRelationDialog.message" />
          </Typography.Body1>
          <Typography.Body1 data-testid={TEST_IDS.ineligibleCompanyDialogDescription}>
            <FormattedMessage
              id="universalMessages.ifYouBelieveThisToBeAnError"
              values={{
                mailTo: <a href="mailto:cs@stenn.com">cs@stenn.com</a>,
              }}
            />
          </Typography.Body1>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} data-testid={TEST_IDS.ineligibleCompanyDialogButtonCancel}>
          <FormattedMessage id="constants.universalMessages.close" />
        </Button>
      </DialogActions>
    </>
  );
};
