export enum EBuyerState {
  UnderReview = 'UnderReview',
  Available = 'Available',
  Overdue = 'Overdue',
  FinancingDenied = 'FinancingDenied',
  RequestingLimit = 'RequestingLimit',
  Unknown = 'Unknown',
}

export enum EBuyerStateFilter {
  All = 'All',
  UnderReview = 'UnderReview',
  Available = 'Available',
  Overdue = 'Overdue',
  FinancingDenied = 'FinancingDenied',
}

export enum EBuyerOrderBy {
  NameAsc = 'NameAsc',
  NameDesc = 'NameDesc',
}
