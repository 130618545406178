import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { QueryStatus } from 'react-query';

import { EmptyList } from '@app/components/ui/PageListContainer/EmptyList';
import { Alert } from '@mui/material';
import { Typography } from '@stenngroup/ui-kit';
import React from 'react';
import { match } from 'ts-pattern';

interface IListContainerProps<T extends { id: string }> {
  items: T[] | undefined;
  status: QueryStatus;
  emptyMessageId: MessageDescriptor['id'];
  renderRow: (item: T) => React.ReactNode;
  LoadingIndicator: React.ReactNode;
}

export const ListContainer = <T extends { id: string }>({
  items,
  status,
  emptyMessageId,
  renderRow,
  LoadingIndicator,
}: IListContainerProps<T>) => {
  return (
    <>
      {match(status)
        .with('loading', () => LoadingIndicator)
        .with('error', () => (
          <Alert severity="error">
            <Typography.Body1>
              <FormattedMessage id="constants.universalMessages.somethingWentWrongSupportTeamAlreadyNotified" />
            </Typography.Body1>
          </Alert>
        ))
        .with('success', () =>
          match(items)
            .with([], () => <EmptyList messageId={emptyMessageId} />)
            .otherwise(() => items?.map((item) => <React.Fragment key={item.id}>{renderRow(item)}</React.Fragment>))
        )
        .otherwise(() => null)}
    </>
  );
};
