import { useIntl } from 'react-intl';

import SimpleTable from '@app/components/ui/SimpleTable';
import getMessageKeysForTable from '@app/utils/getMessageKeysForTable';
import { Typography } from '@stenngroup/ui-kit';
import PropTypes from 'prop-types';
import * as SC from '../styles.js';

import messages from './messages';
import tableData from './tableData';

const LatePaymentFeesNoticePopup = () => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const messageKeysForTable = getMessageKeysForTable(tableData);

  return (
    <div>
      <Typography.Caption color={(t) => t.palette.text.secondary}>
        {formatMessage(messages.firstParagraph)}
      </Typography.Caption>

      <SimpleTable
        messageKeysForTable={messageKeysForTable}
        render={(preparedTableRows) => (
          <SC.Table>
            <tbody>
              {Object.keys(preparedTableRows).map((rowName) => (
                <SC.TableRow key={rowName}>
                  {preparedTableRows[rowName].map((cell) => (
                    <SC.LatePaymentFeesTableHead isHeadCell={cell.cellType === 'head'} key={cell.key}>
                      {formatMessage(messages[cell.key])}
                    </SC.LatePaymentFeesTableHead>
                  ))}
                </SC.TableRow>
              ))}
            </tbody>
          </SC.Table>
        )}
      />
    </div>
  );
};

LatePaymentFeesNoticePopup.propTypes = {
  options: PropTypes.shape({
    shouldShowLinkToCalculator: PropTypes.bool,
    calculatorLinkTo: PropTypes.object,
  }),
};

export default LatePaymentFeesNoticePopup;
