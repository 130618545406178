import { useMutation } from 'react-query';

import { useHttpClient } from '@app/api/lib/useHttpClient';
import { EDealTradeRole } from '@app/context/FlowTransitionsContext';
import {
  TOnboardingReplicationContractEnumsCompanyRole,
  TStennClientsServiceApplicationOperationsModelsUpdateInvoiceRequestDto,
} from '@app/core/__generated__';
import { apiRoutes } from '@app/core/__generated__/apiRoutes';

export const useAdditionalDocumentsTypes = ({
  tradeRole,
  invoiceIds,
}: { tradeRole: EDealTradeRole | null; invoiceIds?: string[] }) => {
  const httpClient = useHttpClient();

  return useMutation({
    mutationFn: () => {
      return httpClient(apiRoutes.DealsGetAdditionalDocumentsTypes, 'post', {
        data: {
          invoiceIds,
          companyRole: tradeRole as TOnboardingReplicationContractEnumsCompanyRole,
        },
      });
    },
  });
};

interface IUpdateInvoiceProps {
  onSuccess: () => void;
}

export const useUpdateInvoice = ({ onSuccess }: IUpdateInvoiceProps) => {
  const httpClient = useHttpClient();
  return useMutation({
    mutationFn: (data: TStennClientsServiceApplicationOperationsModelsUpdateInvoiceRequestDto) =>
      httpClient(apiRoutes.InvoiceUpdateInvoice, 'post', {
        data,
      }),
    onSuccess,
  });
};
