import { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { StepContainer } from '@app/components/features/StepContainer';
import { TextSkeleton } from '@app/components/ui/TextSkeleton';
import { EFlowStep } from '@app/context/FlowTransitionsContext';
import { useFlowTransitionsContext } from '@app/hooks/useFlowTransitionsContext';
import { routes } from '@app/routes/config';
import { calculateTypographyHeight } from '@app/utils/calculateTypographyHeight';
import { CheckCircle } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { Button, Card, Stack, Typography } from '@stenngroup/ui-kit';

interface ITradePartnerInvitedProps {}

export const TradePartnerInvited: FC<ITradePartnerInvitedProps> = () => {
  const { close, getStateByStep } = useFlowTransitionsContext();
  const { email = '' } = getStateByStep<{ email: string }>(EFlowStep.INVITE_TRADE_PARTNER) || {};
  const theme = useTheme();
  const navigate = useNavigate();

  const onViewDealsPageClick = useCallback(() => {
    close();
    navigate(routes.deals.path);
  }, [close, navigate]);

  return (
    <StepContainer title={<FormattedMessage id="createDeal.inviteSent" />} canGoBack={false} showActions={false}>
      <Card
        sx={(theme) => ({
          padding: 2,
          boxShadow: 'none',
          border: `1px solid ${theme.palette.grey['200']}`,
          borderRadius: theme.shape.borderRadius / 2,
        })}
      >
        <Stack gap={2} textAlign="center" alignItems="center">
          <CheckCircle
            fontSize="large"
            style={{
              fontSize: 80,
            }}
            color="success"
          />
          <Typography.TextMd.SemiBold color="text.primary">
            <FormattedMessage
              id="createDeal.inviteSent.description1"
              values={{
                email: email ? (
                  <span>{email}</span>
                ) : (
                  <TextSkeleton
                    status="loading"
                    style={{
                      height: calculateTypographyHeight(theme.typography.body1),
                      width: 100,
                    }}
                  />
                ),
              }}
            />
          </Typography.TextMd.SemiBold>
          <Typography.TextMd.Regular color="text.primary">
            <FormattedMessage id="createDeal.inviteSent.description2" />
          </Typography.TextMd.Regular>

          <Stack gap={2} direction="row" alignItems="center" justifyContent="stretch" sx={{ width: '100%' }}>
            <Button variant="tertiary" size="small" onClick={close} sx={{ flexGrow: 1 }}>
              <FormattedMessage id="createDeal.inviteSent.close" />
            </Button>
            <Button variant="secondary" size="small" onClick={onViewDealsPageClick} sx={{ flexGrow: 1 }}>
              <FormattedMessage id="createDeal.inviteSent.viewDetails" />
            </Button>
          </Stack>
        </Stack>
      </Card>
    </StepContainer>
  );
};
