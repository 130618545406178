import { FC, useEffect, useMemo, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';

import { useHttpClient } from '@app/api/lib/useHttpClient';
import { IneligibleTradeRelationDialogContent } from '@app/components/features/FlowTransitions/screens/SelectTradePartner/IneligibleTradeRelationDialog';
import {
  TradePartnersList,
  TradePartnersListSkeleton,
} from '@app/components/features/FlowTransitions/screens/SelectTradePartner/TradePartnersList';
import { StepContainer } from '@app/components/features/StepContainer';
import { EDealTradeRole, EFlowStep } from '@app/context/FlowTransitionsContext';
import { apiRoutes } from '@app/core/__generated__/apiRoutes';
import { TEST_IDS } from '@app/core/constants/testIds';
import { useFlowTransitionsContext } from '@app/hooks/useFlowTransitionsContext';
import { useInitialFlowState } from '@app/hooks/useInitialFlowState';
import { assert } from '@app/utils/assert';
import { Button, Dialog, Stack, Typography } from '@stenngroup/ui-kit';
import { match } from 'ts-pattern';
import { ITradePartner } from './types';

interface ISelectTradePartnerProps {}

export const SelectTradePartner: FC<ISelectTradePartnerProps> = () => {
  const { handleNextStep, goTo, getFlowState, getStateByStep } = useFlowTransitionsContext();
  const flowState = getFlowState();
  const httpClient = useHttpClient();
  const [tradePartner, selectTradePartner] = useState<ITradePartner | null>(null);
  const [showIneligibleCompanyDialog, setShowIneligibleCompanyDialog] = useState<boolean>(false);
  const path =
    flowState.tradeRole === EDealTradeRole.Supplier
      ? apiRoutes.BuyerGetBuyersForCurrentSupplier
      : apiRoutes.SupplierGetSuppliersForCurrentBuyer;

  const { data: companiesResponse, status } = useQuery({
    queryKey: [path],
    queryFn: () =>
      httpClient(path, 'post', {
        data: {
          pageSize: 100,
        },
      }),
    cacheTime: 0,
    staleTime: 0,
  });

  const { retrieveInitialFlowState, isLoading } = useInitialFlowState();

  const handleNext = async () => {
    assert(tradePartner, 'tradePartner is not defined');
    assert(tradePartner.tradeRelationId, 'tradePartner.tradeRelationId is not defined');
    assert(tradePartner.counterpartyCompanyId, 'tradePartner.counterpartyCompanyId is not defined');

    const newFlowState = await retrieveInitialFlowState({
      trId: tradePartner.tradeRelationId,
      counterpartyCompanyId: tradePartner.counterpartyCompanyId,
      tradeRole: flowState.tradeRole,
    });

    if (!newFlowState.isEligible) {
      setShowIneligibleCompanyDialog(true);
      return;
    }

    handleNextStep({
      newFlowState,
    });
  };

  const counterpartyTradeRole =
    flowState.tradeRole === EDealTradeRole.Supplier ? EDealTradeRole.Buyer : EDealTradeRole.Supplier;

  const Header = () => (
    <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
      <Typography.H6 color="text.primary">
        {match(counterpartyTradeRole)
          .with(EDealTradeRole.Buyer, () => <FormattedMessage id="components.SelectTradePartner.Buyer" />)
          .with(EDealTradeRole.Supplier, () => <FormattedMessage id="components.SelectTradePartner.Supplier" />)
          .otherwise(() => null)}
      </Typography.H6>
      <Button onClick={() => goTo(EFlowStep.SEARCH_TRADE_PARTNER)} variant="tertiary" size="small">
        {match(counterpartyTradeRole)
          .with(EDealTradeRole.Buyer, () => (
            <FormattedMessage id="components.SelectTradePartner.Buyer.addTradePartner" />
          ))
          .with(EDealTradeRole.Supplier, () => (
            <FormattedMessage id="components.SelectTradePartner.Supplier.addTradePartner" />
          ))
          .otherwise(() => null)}
      </Button>
    </Stack>
  );

  const data = getStateByStep<{ tradeRelationId: string } | null>(EFlowStep.SELECT_NEW_TRADE_PARTNER);
  const allCompanies = companiesResponse?.companies ?? [];

  const recentlyAddedTradePartners = useMemo<ITradePartner[]>(
    () => allCompanies.filter((company) => company.tradeRelationId === data?.tradeRelationId),
    [allCompanies, data]
  );

  useEffect(() => {
    if (recentlyAddedTradePartners.length) {
      selectTradePartner(recentlyAddedTradePartners[0]);
    }
  }, [recentlyAddedTradePartners]);

  const companies = allCompanies.filter((company) => company.tradeRelationId !== data?.tradeRelationId);

  const RecentlyAdded = () => (
    <Stack gap={1.5} data-testid={TEST_IDS.selectTradePartnerRecentlyAdded}>
      <Typography.H6 color="text.primary">
        <FormattedMessage id="components.SelectTradePartner.recentlyAdded" />
      </Typography.H6>
      <TradePartnersList
        tradePartners={recentlyAddedTradePartners}
        tradeRole={counterpartyTradeRole}
        selectedTradePartner={tradePartner}
        onTradePartnerSelect={(tradePartner) => selectTradePartner(tradePartner)}
      />
    </Stack>
  );

  return (
    <>
      <StepContainer
        title={<FormattedMessage id="createDeal.selectTradePartner" />}
        onNext={handleNext}
        isNextDisabled={!tradePartner || isLoading}
        isNextLoading={isLoading}
        dataTestId={TEST_IDS.selectTradePartner}
        headerContent={
          <Stack gap={1.5}>
            {recentlyAddedTradePartners.length > 0 && <RecentlyAdded />}
            <Header />
          </Stack>
        }
      >
        <Stack flexGrow={1}>
          {match(status)
            .with('loading', () => <TradePartnersListSkeleton />)
            .with('error', () => null)
            .with('success', () =>
              companies && companies.length > 0 ? (
                <TradePartnersList
                  tradePartners={companies}
                  tradeRole={counterpartyTradeRole}
                  selectedTradePartner={tradePartner}
                  onTradePartnerSelect={(tradePartner) => {
                    selectTradePartner(tradePartner);
                  }}
                  disabled={isLoading}
                />
              ) : (
                <Typography.Body1>
                  <FormattedMessage id="constants.universalMessages.noTradePartnersFound" />
                </Typography.Body1>
              )
            )
            .otherwise(() => null)}
        </Stack>
      </StepContainer>
      <Dialog
        open={showIneligibleCompanyDialog}
        onClose={() => setShowIneligibleCompanyDialog(false)}
        fullWidth
        size="medium"
      >
        <IneligibleTradeRelationDialogContent onClose={() => setShowIneligibleCompanyDialog(false)} />
      </Dialog>
    </>
  );
};
