import { TStennClientsServiceContractsEnumsCompanyRole } from '@app/core/__generated__';
import { ApiProvider, BASE_API_URLS } from '@app/core/api';
import { EContractorType } from '@app/types/flow';
import { omitUndefined } from '@app/utils/omitUndefined';

interface IGetElegibilityPayload {
  alpha2Code: string;
  companyRole: EContractorType;
}

export const getEligibilityMutation = async (payload: IGetElegibilityPayload): Promise<boolean> => {
  const query = new URLSearchParams(omitUndefined(payload)).toString();
  const response = await ApiProvider.apiRequest<boolean>(
    `${BASE_API_URLS.onboarding}/api/v1.2/Companies/IsCompanyEligible?${query}`,
    {
      method: 'GET',
    }
  );
  return response;
};

interface IGetPairElegibilityPayload {
  companyRole: TStennClientsServiceContractsEnumsCompanyRole;
  alpha2Code: string;
  counterPartyDuns?: string;
}

export enum EEligibilityStatus {
  Eligible = 'Eligible',
  IneligibleByDomestic = 'IneligibleByDomestic',
  IneligibleBySupplier = 'IneligibleBySupplier',
  IneligibleByBuyer = 'IneligibleByBuyer',
  IneligibleByTradeRelation = 'IneligibleByTradeRelation',
}

export const getPairEligibilityMutation = async (payload: IGetPairElegibilityPayload): Promise<EEligibilityStatus> => {
  const query = new URLSearchParams(omitUndefined(payload)).toString();
  const response = await ApiProvider.apiRequest<EEligibilityStatus>(
    `${BASE_API_URLS.onboarding}/api/v1.2/Companies/GetPotentialTradeRelationEligibilityStatus?${query}`,
    {
      method: 'GET',
    }
  );
  return response;
};
