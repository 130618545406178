interface IGetIsOnboardingFlowPayload {
  isEmailVerified: boolean;
  hasBuyerRole: boolean;
  hasSupplierRole: boolean;
}

export const getIsOnboardingFlow = ({
  hasBuyerRole,
  hasSupplierRole,
  isEmailVerified,
}: IGetIsOnboardingFlowPayload) => {
  const isOnboadringFinished = hasBuyerRole || hasSupplierRole;

  const isOnboardingFlowShow = !isOnboadringFinished && isEmailVerified;
  return isOnboardingFlowShow;
};
