import { FC } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { useGetProfile } from '@app/api/hooks/useGetProfile';
import { StepContainer } from '@app/components/features/StepContainer';
import { RoleSelectionForm } from '@app/components/ui/RoleSelectionForm';
import { baseRoleSelectionSchema } from '@app/components/ui/RoleSelectionForm/baseSchema';
import { IForm } from '@app/components/ui/RoleSelectionForm/types';
import { EDealTradeRole } from '@app/context/FlowTransitionsContext';
import { useFlowTransitionsContext } from '@app/hooks/useFlowTransitionsContext';
import { yupResolver } from '@hookform/resolvers/yup';

interface ISelectTradeRoleProps {}

export const SelectTradeRole: FC<ISelectTradeRoleProps> = () => {
  const { data: profile } = useGetProfile();
  const form = useForm<IForm>({
    resolver: yupResolver(baseRoleSelectionSchema),
  });

  const { handleNextStep } = useFlowTransitionsContext();

  const handleNext = form.handleSubmit((data) => {
    handleNextStep({
      newFlowState: { tradeRole: data.type as unknown as EDealTradeRole },
    });
  });
  return (
    <StepContainer
      title={<FormattedMessage id="createDeal.chooseTradeRole" />}
      onNext={handleNext}
      isNextDisabled={form.watch('type') === undefined}
    >
      <RoleSelectionForm form={form} isBuyerRoleDisabled={!profile?.companyInfo?.duns} />
    </StepContainer>
  );
};
