import { useEffect, useId } from 'react';

import { TSignalrMessageType, TSubscriptionCallback } from '../types';
import { useSignalrContext } from './useSignalrContext';

export const useSignalrSubscription = (types: TSignalrMessageType[], callback: TSubscriptionCallback): void => {
  const context = useSignalrContext();

  const id = useId();

  useEffect(() => {
    context.subscribe(types, id, callback);
    return (): void => context.unsubscribe(types, id);
  }, [callback, context, types, id]);
};
