export const routes = {
  root: {
    path: '/',
  },
  addCompanyInformation: {
    path: '/add-company-info',
    search: {
      path: '/add-company-info/search',
    },
    select: {
      path: '/add-company-info/select',
    },
    alreadyRegistered: {
      path: '/add-company-info/already-registered',
    },
  },
  logout: {
    path: '/logout',
  },
  roleSelection: {
    path: '/role-selection',
  },
  systemAdministrator: {
    path: '/system-administrator',
  },
  dashboard: {
    path: '/dashboard',
  },
  invoices: {
    path: '/invoices',
    invoiceDetails: {
      path: '/invoices/:invoiceId',
    },

    /**
     * @deprecated legacy route
     */
    legacyOfSuppliers: {
      path: '/invoices/of-suppliers',
    },
  },
  deals: {
    path: '/deals',
    dealDetails: {
      path: '/deals/:dealId',
    },
    /**
     * @deprecated Legacy route
     */
    legacyDealDetails: {
      path: '/deal/:dealId',
    },
    /**
     * @deprecated Legacy route
     */
    legacyOfSuppliers: {
      path: '/deals/of-suppliers',
    },
    fromDocuSign: {
      success: {
        path: '/thank-you-for-signing-the-document',
      },
      cancel: {
        path: '/you-will-sign-the-document-later',
      },
      declined: {
        path: '/you-declined-signing-the-document',
      },
    },
  },
  suppliers: {
    path: '/suppliers',
    supplierDetails: {
      path: '/suppliers/:id',
      invoices: {
        path: '/suppliers/:id/invoices',
      },
      deals: {
        path: '/suppliers/:id/deals',
      },
      information: {
        path: '/suppliers/:id/information',
      },
    },
  },
  buyers: {
    path: '/buyers',
    buyerDetails: {
      path: '/buyers/:id',
      invoices: {
        path: '/buyers/:id/invoices',
      },
      deals: {
        path: '/buyers/:id/deals',
      },
      information: {
        path: '/buyers/:id/information',
      },
    },
  },
  auth: {
    path: '/auth',
    login: {
      path: '/auth/login',
    },
    googleHandler: {
      path: '/auth/google-handler',
    },
    signUp: {
      path: '/auth/sign-up',
      socialAccountActivation: {
        path: '/auth/sign-up/social-account-activation',
      },
      accountNotCreated: {
        path: '/auth/account-not-created',
      },
    },
    forgotPassword: {
      path: '/auth/forgot-password-new',
      emailConfirmation: {
        path: '/auth/forgot-password-new/email-confirmation',
      },
      changePassword: {
        path: '/auth/forgot-password-new/change-password',
      },
      passwordWasSet: {
        path: '/auth/forgot-password-new/password-was-set',
      },
    },
    emailConfirmation: {
      path: '/auth/sign-up/email-confirmation',
    },
    acceptInvitation: {
      path: '/auth/accept-invitation',
    },
  },
  acceptInvitationExternal: {
    path: '/accept-invitation',
  },
} as const;
