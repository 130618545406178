import { TEST_IDS } from '@app/core/constants';
import { Radio, RadioGroup, Stack } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { ESignatoryType } from '../types';
import { RadioFormLabel } from './styles';

interface ISignatoryTypeSelectorProps {
  signatoryType: ESignatoryType;
  setSignatoryType: (signatoryType: ESignatoryType) => void;
  disabled: boolean;
}

export const SignatoryTypeSelector: FC<ISignatoryTypeSelectorProps> = ({
  signatoryType,
  setSignatoryType,
  disabled,
}) => {
  return (
    <RadioGroup
      value={signatoryType}
      onChange={(e: ChangeEvent<HTMLInputElement>) => setSignatoryType(e.target.value as ESignatoryType)}
    >
      <Stack direction="row" gap={1}>
        <RadioFormLabel
          value={ESignatoryType.AUTHORISED_PERSON}
          label={<FormattedMessage id="constants.universalMessages.iAmTheAuthorisedSignatory" />}
          control={<Radio />}
          data-testid={TEST_IDS.iAmAuthorizedSignatory}
          disabled={disabled}
        />
        <RadioFormLabel
          value={ESignatoryType.ANOTHER_PERSON}
          label={<FormattedMessage id="containers.SupplierFlow.steps.YourAuthorisedSignatory.anotherPerson" />}
          control={<Radio />}
          data-testid={TEST_IDS.anotherPersonAuthorizedSignatory}
          disabled={disabled}
        />
      </Stack>
    </RadioGroup>
  );
};
