import { useLangContext } from '@app/context/LangContext/hooks/useLangContext';
import { LANGUAGES, TLang } from '@app/core/internationalization';
import { useSetProfileLanguage } from '@app/hooks/useSetProfileLanguage';
import { useAuth } from '@stenngroup/auth0-sdk';
import { LanguageSelect, SelectChangeEvent } from '@stenngroup/ui-kit';
import { FC, useMemo, useState } from 'react';
import { flagsMap } from './constants/flags';

export const LanguageSelectorWrapper: FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const { isAuthenticated } = useAuth();
  const { langId, setLangId } = useLangContext();
  const langOptions = useMemo(
    () =>
      LANGUAGES.map((lang) => ({
        value: lang.id,
        label: lang.name,
        flag: flagsMap[lang.id],
      })),
    []
  );

  const { onChangeLanguage } = useSetProfileLanguage();

  const handleLanguageChange = (event: SelectChangeEvent): void => {
    setLangId(event.target.value as TLang);

    if (isAuthenticated) {
      onChangeLanguage(event.target.value);
    }
  };

  return (
    <LanguageSelect
      sx={{
        width: '100% !important',
        backgroundColor: ({ palette }) => `${palette.background.level1} !important`,
        color: ({ palette }) => `${palette.text.primary} !important`,
        '& .MuiSelect-icon': {
          color: ({ palette }) => `${palette.action.active} !important`,
        },
      }}
      options={langOptions}
      value={langId}
      open={open}
      onChange={handleLanguageChange}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        setOpen(!open);
      }}
    />
  );
};
