import { Button, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@stenngroup/ui-kit';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

interface IConfirmationDialogContentProps {
  onExit: () => void;
  onContinue: () => void;
}

export const ConfirmationDialogContent: FC<IConfirmationDialogContentProps> = ({ onExit, onContinue }) => {
  return (
    <>
      <DialogTitle>
        <Typography.H5>
          <FormattedMessage id="createDeal.confirmationDialog.title" />
        </Typography.H5>
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" gap={2}>
          <Typography.TextMd.Regular>
            <FormattedMessage id="createDeal.confirmationDialog.description" />
          </Typography.TextMd.Regular>
          <Typography.TextMd.Regular>
            <FormattedMessage id="createDeal.confirmationDialog.description2" />
          </Typography.TextMd.Regular>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="tertiary" onClick={onExit}>
          <FormattedMessage id="createDeal.confirmationDialog.exit" />
        </Button>
        <Button variant="contained" onClick={onContinue}>
          <FormattedMessage id="createDeal.confirmationDialog.continue" />
        </Button>
      </DialogActions>
    </>
  );
};
