import { ComponentType, FC, ReactElement } from 'react';

import { useApplicationState } from '@app/context/ApplicationStateContext/hooks/useApplicationState';

export const withExternalRedirect = <P extends object>(Component: ComponentType<P>): FC<P> => {
  return function WithExternalRedirect(props: P): ReactElement | null {
    const { mainProduct } = useApplicationState();

    const isBddRole = mainProduct === 'SalesPortal';
    if (isBddRole) {
      window.location.replace(window._STENN_.REPS_APP_URL);
      return null;
    }

    return <Component {...props} />;
  };
};
