import { BASE_API_URLS } from '@app/core/api';
import { TUnionApiResponse } from '@app/types/apiResponse';

interface IAddUserPayload {
  configuredFetch: typeof fetch;
  payload: {
    firstName: string;
    lastName: string;
    email: string;
    position: string;
    phone?: string;
  };
}

export const addUserRequest = async ({
  configuredFetch,
  payload,
}: IAddUserPayload): Promise<TUnionApiResponse<{ id: string }>> => {
  const response = await configuredFetch(`${BASE_API_URLS.onboarding}/api/v1.2/Contacts/AddNewContact`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  if (!response.ok) {
    return {
      type: 'error',
    };
  }
  const data = await response.json();
  return {
    type: 'success',
    data,
  };
};
