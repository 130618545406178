import { ComponentType, FC, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import { useApplicationState } from '@app/context/ApplicationStateContext/hooks/useApplicationState';
import { routes } from '@app/routes/config';
import { withExternalRedirect } from '@app/routes/hocs/withExternalRedirect';

export const withEmailNotVerified = <P extends object>(Component: ComponentType<P>): FC<P> => {
  return withExternalRedirect(function WithEmailNotVerified(props: P): ReactElement | null {
    const { isEmailVerified, isOnboardingFlow } = useApplicationState();
    if (isEmailVerified && isOnboardingFlow) {
      return <Navigate to={routes.roleSelection.path} />;
    }

    if (isEmailVerified && !isOnboardingFlow) {
      return <Navigate to={routes.dashboard.path} />;
    }

    return <Component {...props} />;
  });
};
