import { FC } from 'react';

import { FormattedMessage } from 'react-intl';
import { useMutation } from 'react-query';

import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@stenngroup/ui-kit';

import { ApiProvider, BASE_API_URLS } from '@app/core/api';
import { IDeleteInvoiceDialogContentProps } from './types';

import { useApplicationState } from '@app/context/ApplicationStateContext/hooks/useApplicationState';

const deleteInvoiceAction = (invoiceId: string) => {
  return ApiProvider.apiRequest(`${BASE_API_URLS.onboarding}/api/v1.2/Invoices/DeleteInvoice/?invoiceId=${invoiceId}`, {
    method: 'DELETE',
  });
};

export const DeleteInvoiceDialogContent: FC<IDeleteInvoiceDialogContentProps> = ({
  invoiceNumber,
  invoiceId,
  onClose,
  onDelete,
}) => {
  const { mutate: deleteInvoiceMutation, isLoading: isDeleteInvoiceLoading } = useMutation(deleteInvoiceAction, {
    onSuccess: () => {
      onDelete();
    },
  });

  const { isReadOnly } = useApplicationState();

  const handleDeleteClick = () => {
    deleteInvoiceMutation(invoiceId);
  };
  return (
    <>
      <DialogTitle>
        <FormattedMessage id="components.Invoice.DeleteInvoiceDialogContent.title" />
      </DialogTitle>
      <DialogContent>
        <Typography.TextMd.Regular>
          <FormattedMessage
            id="components.Invoice.DeleteInvoiceDialogContent.description"
            values={{ InvoiceNumber: invoiceNumber }}
          />
        </Typography.TextMd.Regular>
      </DialogContent>
      <DialogActions>
        <Button variant="tertiary" size="medium" onClick={onClose}>
          <FormattedMessage id="constants.universalMessages.cancel" />
        </Button>
        <Button
          variant="primary"
          size="medium"
          color="error"
          onClick={handleDeleteClick}
          isLoading={isDeleteInvoiceLoading}
          disabled={isDeleteInvoiceLoading || isReadOnly}
        >
          <FormattedMessage id="constants.universalMessages.delete" />
        </Button>
      </DialogActions>
    </>
  );
};
