import { COOKIE_KEYS } from '@app/core/constants';
import { safeJSONparse } from '@app/core/utilities';

import { LocalStorageService } from './LocalStorageService';

export const getCurrentLanguageFromStorage = () => {
  const value = LocalStorageService.getValue(COOKIE_KEYS.languageData);

  return safeJSONparse(value);
};
