import { FC, ReactElement, useEffect, useState } from 'react';

import { TEST_IDS } from '@app/core/constants';
import { Stack } from '@mui/material';
import { SideBlock } from './SideBlock';

import { Button } from '@stenngroup/ui-kit';
import { LocalStorageService } from '../../../core/storage/LocalStorageService';

const MD_WIDTH = 380;
const LG_WIDTH = 540;

export const OnboardingLayoutV3: FC<{ children: ReactElement; isLogoutButtonShown: boolean }> = (props) => {
  const { children, isLogoutButtonShown } = props;

  const [flagSet, setFlagSet] = useState(false);

  useEffect(() => {
    // Check if the flag is stored in localStorage
    const storedFlag = LocalStorageService.hasValue('accept_notice');
    if (storedFlag === true) {
      setFlagSet(true);
    }
  }, []);

  const handleClick = () => {
    LocalStorageService.setValue('accept_notice', 'true');
    setFlagSet(true);
  };

  if (!flagSet)
    return (
      <Stack alignItems="center" justifyContent="center" flexDirection="column">
        <Stack height="90%" width="90%">
          <div className="announcement">
            <h1 className="heading-style-h3  text-color-green-primary">Notice</h1>
            <div className="rte w-richtext">
              <p>
                Jim Tucker, Kristina Kicks and Joshua Dwyer from Interpath were appointed Joint Administrators to Stenn
                Assets UK Limited and Stenn International Limited (the ‘Companies’) on 4 December 2024, following an
                order made by the High Court of Justice in London.
              </p>
              <p> </p>
              <p>
                The Companies are part of Stenn Technologies, the London-headquartered global fintech group and digital
                trade finance provider to International SMEs. The group employs over 200 people globally and since 2016
                has deployed more than $10bn in capital to customers in over 70 countries.
              </p>
              <p>‍</p>
              <p>
                The Joint Administrators’ immediate focus is to engage with the Companies’ employees, customers (and
                their debtors), suppliers and key stakeholders in order to stabilise the Companies’ operations and take
                all necessary steps to preserve value. Please note that the Joint Administrators will not be seeking to
                fulfil additional lending requests for the time being. Obligors should continue to fulfil their payment
                obligations in the usual way.
              </p>
              <p> </p>
              <p>‍We have prepared a list of frequently asked questions (FAQs), which you can access here:</p>
            </div>
            <a
              href="https://cdn.prod.website-files.com/65f05291a79777009470a8e3/675ab78df6914ac2adaaa5e7_SAUK%20SIL%20FAQ%20Dec%2012%202024.pdf"
              className="text-style-link"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              FAQs Document
            </a>
            <div className="rte w-richtext">
              <p>
                If you have any further queries or questions on the administration of either Stenn Assets UK Limited or
                Stenn International Limited, then please direct these to the following mailboxes:
              </p>
              <p>‍</p>
              <p>
                Sellers / Suppliers:{' '}
                <a
                  href="mailto:stennsellers@interpath.com"
                  className="text-style-link"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  stennsellers@interpath.com
                </a>
              </p>
              <p>
                <a href="mailto:stennsellers@interpath.com" target="_blank" rel="noopener noreferrer nofollow">
                  ‍
                </a>
                Buyers / Obligors:{' '}
                <a
                  href="mailto:stennobligors@interpath.com"
                  className="text-style-link"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  stennobligors@interpath.com
                </a>
              </p>
              <p>
                <a href="mailto:stennobligors@interpath.com" target="_blank" rel="noopener noreferrer nofollow">
                  ‍
                </a>
                Creditors:{' '}
                <a
                  href="mailto:stenncreditors@interpath.com"
                  className="text-style-link"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  stenncreditors@interpath.com
                </a>
              </p>
              <p>
                Employees:{' '}
                <a
                  href="mailto:stennemployees@interpath.com"
                  className="text-style-link"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  stennemployees@interpath.com
                </a>
              </p>
              <p>‍</p>
              <p>
                <strong>Scams</strong>
              </p>
              <p> </p>
              <p>All buyers and sellers should remain alert to the possibility of fraud.</p>
              <p> </p>
              <p>
                We will provide updates to you through e-mail, in writing and via uploads to the Stenn website (
                <a href="http://www.stenn.com/" className="text-style-link">
                  www.stenn.com
                </a>
                ).
              </p>
              <p>‍</p>
              <p> </p>
              <p>
                <strong>Protect yourself from scams</strong>
              </p>
              <p> </p>
              <p>
                When financial firms enter insolvency, fraudsters may seek to take advantage by approaching you and
                claiming to be able to recover their funds in return for an advance fee. Sometimes the fraudsters claim
                to be from the Companies or the Joint Administrators’ office. You should treat with caution any
                unexpected call, e-mail or other contact which asks you for money in this manner and do not be pressured
                into acting quickly. FCA guidelines are available at{' '}
                <a
                  href="http://www.fca.org.uk/consumers/protect-yourself-scams"
                  target="_blank"
                  className="text-style-link"
                  rel="noopener noreferrer nofollow"
                >
                  www.fca.org.uk/consumers/protect-yourself-scams
                </a>
              </p>
              <p>‍</p>
              <p> </p>
              <p>
                <strong>Clone websites - beware</strong>
              </p>
              <p> </p>
              <p>
                Please note that the JA's webpage is{' '}
                <a
                  href="https://www.ia-insolv.com/interpathadvisoryinsolvency-portal.html"
                  target="_blank"
                  className="text-style-link"
                  rel="noopener noreferrer nofollow"
                >
                  https://www.ia-insolv.com/interpathadvisoryinsolvency-portal.html
                </a>{' '}
                and the Company’s website is{' '}
                <a href="https://www.stenn.com/" className="text-style-link">
                  https://www.stenn.com
                </a>
                . These are the only webpages that will be used to provide updates and relevant information.
              </p>
              <p>‍</p>
              <p className="text-size-xsmall">
                The affairs, business and property of the Companies are managed by the Joint Administrators. The Joint
                Administrators act as agents of the Companies and contract without personal liability.
              </p>
              <p>‍</p>
              <p> </p>
              <p className="text-size-xsmall">
                The Companies are FCA registered for supervised anti-money laundering purposes only. FCA registration
                numbers: Stenn Assets UK Ltd: 922432; Stenn International Ltd: 745987. Registered office: C/O Interpath
                Ltd, 10 Fleet Place, London, EC4M 7RB.
              </p>
              <p>‍</p>
              <p> </p>
              <p className="text-size-xsmall">
                James Robert Tucker, Kristina Kicks and Joshua James Dwyer are authorised to act as insolvency
                practitioners by the Institute of Chartered Accountants in England &amp; Wales. We are bound by the
                Insolvency Code of Ethics.
              </p>
              <p className="text-size-xsmall">‍</p>
            </div>
          </div>
          <Stack alignItems="center">
            <Button onClick={handleClick} size="small" variant="primary">
              OK
            </Button>
          </Stack>
        </Stack>
      </Stack>
    );

  return (
    <Stack flexGrow={1} height="100%">
      <Stack
        direction="column"
        flexGrow={1}
        sx={(t) => ({
          [t.breakpoints.up('md')]: {
            height: '100%',
            flexDirection: 'row',
          },
        })}
      >
        {/* Placeholder with the same widths to push content for screens above lg */}
        <Stack
          flexShrink={0}
          sx={(t) => ({
            [t.breakpoints.up('md')]: {
              width: MD_WIDTH,
            },
            [t.breakpoints.up('lg')]: {
              width: LG_WIDTH,
            },
          })}
        />
        <Stack
          flexShrink={0}
          sx={(t) => ({
            width: '100%',
            [t.breakpoints.up('md')]: {
              position: 'fixed',
              top: 0,
              left: 0,
              bottom: 0,
              width: MD_WIDTH,
            },
            [t.breakpoints.up('lg')]: { width: LG_WIDTH },
          })}
        >
          <SideBlock isLogoutButtonShown={isLogoutButtonShown} />
        </Stack>

        <Stack flexGrow={1} data-testid={TEST_IDS.onboardingLayoutMainContent}>
          {children}
        </Stack>
      </Stack>
    </Stack>
  );
};
