import { FC } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import { FormPlacesAutocomplete } from '@app/components/features/PlacesAutocomplete/FormPlacesAutocomplete';
import { TPlacesAutocompleteOption } from '@app/components/features/PlacesAutocomplete/types';
import { TEST_IDS } from '@app/core/constants';
import { Stack, useTheme } from '@mui/material';
import { FormTextField, Typography } from '@stenngroup/ui-kit';

import { ISearchCompanyFormProps } from './types';

const USA_OPTION_VALUE = 'US';
const CHINA_OPTION_VALUE = 'CN';
const INDIA_OPTION_VALUE = 'IN';

export const SearchCompanyForm: FC<ISearchCompanyFormProps> = ({ form, disabled, dunsField }) => {
  const { control, watch, setValue } = form;
  const theme = useTheme();

  const { formatMessage } = useIntl();

  const countryCode = watch('country')?.place;

  const handleChangeCountry = (option: TPlacesAutocompleteOption) => {
    if (option.place !== USA_OPTION_VALUE) {
      setValue('state', {
        label: '',
        value: '',
        place: '',
      });
    }

    if (option.place !== CHINA_OPTION_VALUE) {
      setValue('companyNameInChinese', '');
      setValue('unifiedSocialCreditCode', '');
    }

    if (option.place !== INDIA_OPTION_VALUE) {
      setValue('registrationNumber', '');
    }

    setValue('country', {
      label: option.label,
      value: option.value,
      place: option.place,
    });
  };

  const handleStateChange = (option: TPlacesAutocompleteOption) => {
    setValue('state', option);
  };

  return (
    <Stack gap={3} data-testid={TEST_IDS.formAddEditCompany}>
      <Stack gap={2}>
        <FormTextField
          control={control}
          label={<FormattedMessage id="constants.universalMessages.registeredCompanyName" />}
          name="companyName"
          data-testid={TEST_IDS.companyNameInput}
        />

        <FormPlacesAutocomplete
          loadOnMount
          control={control}
          name="country"
          onSelect={handleChangeCountry}
          type="country"
          disabled={disabled}
          clearOnBlur
          testId={TEST_IDS.countryCodeInput}
          label={<FormattedMessage id="constants.universalMessages.country" />}
        />

        {countryCode === CHINA_OPTION_VALUE && (
          <Stack gap={2}>
            <FormTextField
              control={control}
              label={<FormattedMessage id="constants.universalMessages.unifiedSocialCreditCode" />}
              name="unifiedSocialCreditCode"
              data-testid={TEST_IDS.unifiedSocialCreditCodeInput}
            />
            <FormTextField
              control={control}
              label={<FormattedMessage id="constants.universalMessages.registeredCompanyNameChinese" />}
              name="companyNameInChinese"
              data-testid={TEST_IDS.companyNameInChineseInput}
            />
          </Stack>
        )}

        {countryCode === INDIA_OPTION_VALUE && (
          <Stack gap={3}>
            <FormTextField
              control={control}
              label={
                <Typography.Body2
                  sx={{
                    wordWrap: 'break-word',
                    wordBreak: 'break-word',
                    whiteSpace: 'normal',
                  }}
                >
                  <FormattedMessage id="constants.universalMessages.corporateIdentificationNumber" />
                </Typography.Body2>
              }
              labelType="outside"
              placeholder={formatMessage({
                id: 'constants.universalMessages.CINLLPIN',
              })}
              name="registrationNumber"
              data-testid={TEST_IDS.corporateIdentificationNumber}
            />
          </Stack>
        )}

        {countryCode === USA_OPTION_VALUE && (
          <FormPlacesAutocomplete
            loadOnMount
            control={control}
            name="state"
            type="administrative_area_level_1"
            country={countryCode}
            fullWidth={true}
            clearOnBlur
            disabled={disabled}
            onSelect={handleStateChange}
            testId={TEST_IDS.stateInput}
            label={<FormattedMessage id="constants.universalMessages.state" />}
          />
        )}
        {dunsField === 'required' && (
          <Stack>
            <Typography.Body1 color={(t) => t.palette.text.secondary}>
              <FormattedMessage
                id="components.NotListedCompany.weRequireADuns"
                values={{
                  link: (
                    <a
                      style={{
                        color: theme.palette.primary.main,
                      }}
                      href="mailto:welcome@stenn.com"
                    >
                      welcome@stenn.com
                    </a>
                  ),
                }}
              />
            </Typography.Body1>
          </Stack>
        )}
        {(dunsField === 'optional' || dunsField === 'required') && (
          <FormTextField
            control={control}
            label={
              dunsField === 'required' ? (
                <FormattedMessage id="constants.universalMessages.duns" />
              ) : (
                <FormattedMessage id="constants.universalMessages.dunsOptional" />
              )
            }
            name="duns"
            data-testid={TEST_IDS.dunsInput}
          />
        )}
      </Stack>
    </Stack>
  );
};
