import { useHttpClient } from '@app/api/lib/useHttpClient';
import { apiRoutes } from '@app/core/__generated__/apiRoutes';
import { LANGUAGES } from '@app/core/internationalization';
import { assert } from '@app/utils/assert';
import { useMutation } from 'react-query';

interface IUseSetProfileLanguage {
  onSuccess: () => void;
}

export const useSetProfileLanguage = (params?: IUseSetProfileLanguage) => {
  const httpClient = useHttpClient();

  const { mutate } = useMutation({
    mutationFn: (languageCode: string) =>
      httpClient(apiRoutes.ProfileChangeWebBrowserLanguageLanguageCode, 'post', {
        routeParams: {
          languageCode,
        },
      }),
    onSuccess() {
      params?.onSuccess();
    },
  });

  const handleChangeLanguage = (langId: string) => {
    const languageCode = LANGUAGES.find((lang) => lang.id === langId)?.languageCode;
    assert(languageCode, 'Language not found');

    mutate(languageCode);
  };

  return {
    onChangeLanguage: handleChangeLanguage,
  };
};
