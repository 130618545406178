import { ComponentType, FC, ReactElement } from 'react';

import { useApplicationState } from '@app/context/ApplicationStateContext/hooks/useApplicationState';
import { routes } from '@app/routes/config';
import { Navigate } from 'react-router-dom';
import { match } from 'ts-pattern';

export const withOnboardingRequired = <P extends object>(Component: ComponentType<P>): FC<P> => {
  return function WithOnboardingRequired(props: P): ReactElement | null {
    const { isOnboardingFlow, onboardingRole } = useApplicationState();

    if (isOnboardingFlow) {
      return (
        <Navigate
          to={match(onboardingRole)
            .with('Supplier', () => routes.addCompanyInformation.path)
            .with('Buyer', () => routes.addCompanyInformation.path)
            .otherwise(() => routes.roleSelection.path)}
        />
      );
    }
    return <Component {...props} />;
  };
};
