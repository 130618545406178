import { ICompanyBankDetails } from '@app/components/layouts/DashboardLayout/Header/PersonalSpace/types';
import { BASE_API_URLS } from '@app/core/api/constants';

interface IGetCompanyCompanyBankDetailsPayload {
  configuredFetch: typeof fetch;
}

export const getCompanyBankDetailsRequest = async ({
  configuredFetch,
}: IGetCompanyCompanyBankDetailsPayload): Promise<ICompanyBankDetails | null> => {
  const response = await configuredFetch(`${BASE_API_URLS.clientsServiceWeb}/BankDetails/Get`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data[0] ?? null;
};
