import { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import { routes } from '@app/routes/config';
import DashboardRounded from '@mui/icons-material/DashboardRounded';
import FactoryRounded from '@mui/icons-material/FactoryRounded';
import HandshakeRounded from '@mui/icons-material/HandshakeRounded';
import MonetizationOnRounded from '@mui/icons-material/MonetizationOnRounded';
import ReceiptRounded from '@mui/icons-material/ReceiptRounded';

interface IDashboardNavItem {
  path: string;
  Icon: FC<{ fontSize: 'small' | 'medium' | 'large' | 'inherit' }>;
  title: JSX.Element;
  visible: ({ hasBuyerRole, hasSupplierRole }: { hasBuyerRole: boolean; hasSupplierRole: boolean }) => boolean;
}
export const DashboardNavItems: IDashboardNavItem[] = [
  {
    path: routes.dashboard.path,
    Icon: ({ fontSize }) => <DashboardRounded fontSize={fontSize} />,
    title: <FormattedMessage id="components.MainMenu.menuItemDashboard" />,
    visible: () => true,
  },
  {
    path: routes.invoices.path,
    Icon: ({ fontSize }) => <ReceiptRounded fontSize={fontSize} />,
    title: <FormattedMessage id="components.MainMenu.menuItemInvoicesTool" />,
    visible: () => true,
  },
  {
    path: routes.deals.path,
    Icon: ({ fontSize }) => <HandshakeRounded fontSize={fontSize} />,
    title: <FormattedMessage id="components.MainMenu.menuItemDealsTool" />,
    visible: () => true,
  },
  {
    path: routes.suppliers.path,
    Icon: ({ fontSize }) => <FactoryRounded fontSize={fontSize} />,
    title: <FormattedMessage id="constants.universalMessages.suppliers" />,
    visible: ({ hasBuyerRole }) => hasBuyerRole,
  },
  {
    path: routes.buyers.path,
    Icon: ({ fontSize }) => <MonetizationOnRounded fontSize={fontSize} />,
    title: <FormattedMessage id="constants.universalMessages.buyers" />,
    visible: ({ hasSupplierRole }) => hasSupplierRole,
  },
];
