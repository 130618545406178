import { CSSProperties, FC, ReactNode } from 'react';

import { QueryStatus } from 'react-query';

import { TEST_IDS } from '@app/core/constants';
import { Skeleton } from '@mui/material';
import { Stack } from '@mui/system';

export const TextSkeleton: FC<{
  children?: ReactNode;
  status: QueryStatus;
  style?: CSSProperties;
  containerStyle?: CSSProperties;
}> = ({ children, status, style, containerStyle }) => {
  return status === 'loading' ? (
    <Stack
      display="inline-flex"
      data-testid={TEST_IDS.textSkeleton}
      sx={{
        ...containerStyle,
      }}
    >
      <Skeleton style={style} variant="text" width={style?.width ?? 100} height={style?.height} />
    </Stack>
  ) : (
    <>{children}</>
  );
};
