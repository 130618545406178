import CheckCircle from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ErrorCircle from '@mui/icons-material/ErrorOutline';
import { IconButton, Stack, Tooltip, Typography } from '@stenngroup/ui-kit';
import { FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

interface IInvoiceListItemProps {
  invoiceNumber: ReactNode;
  invoiceValue: ReactNode;
  dueDate: ReactNode;
  isOverdue: boolean;
  onClickEditButton: () => void;
  onClickDeleteButton: () => void;
  error?: ReactNode;
}

export const InvoiceListItem: FC<IInvoiceListItemProps> = ({
  invoiceNumber,
  invoiceValue,
  dueDate,
  isOverdue,
  onClickDeleteButton,
  onClickEditButton,
  error,
}) => (
  <Stack gap={0.5}>
    <Stack
      borderRadius={1}
      border="1px solid"
      borderColor={(t) => (error ? t.palette.error.main : t.palette.grey['200'])}
      bgcolor={(t) => t.palette.background.level3}
      padding={2}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack gap={1}>
        <Stack gap={0.5} direction="row" alignItems="center">
          {isOverdue ? (
            <Tooltip title={<FormattedMessage id="createDeal.invoicesList.overdue" />}>
              <ErrorCircle fontSize="small" color="error" />
            </Tooltip>
          ) : (
            <CheckCircle fontSize="small" color="success" />
          )}
          <Typography.Body2SemiBold color={(t) => t.palette.text.primary}>
            <FormattedMessage
              id="createDeal.invoicesList.invoiceNumber"
              values={{
                invoiceNumber,
              }}
            />
          </Typography.Body2SemiBold>
        </Stack>
        <Typography.Body2 color={(t) => t.palette.text.secondary}>
          <FormattedMessage id="createDeal.invoicesList.invoiceValue" values={{ invoiceValue }} />
        </Typography.Body2>
        <Typography.Body2 color={(t) => t.palette.text.secondary}>
          <FormattedMessage id="createDeal.invoicesList.dueDate" values={{ dueDate }} />
        </Typography.Body2>
      </Stack>
      <Stack direction="row">
        <IconButton onClick={onClickEditButton}>
          <EditIcon fontSize="small" />
        </IconButton>
        <IconButton onClick={onClickDeleteButton}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Stack>
    </Stack>
    {Boolean(error) && error}
  </Stack>
);
