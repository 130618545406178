import { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';

import { CheckCircle } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import { Button, Card, Typography } from '@stenngroup/ui-kit';

import { useHttpClient } from '@app/api/lib/useHttpClient';
import { StepContainer } from '@app/components/features/StepContainer';
import { isDevFeatureEnabled } from '@app/components/ui/DevFeatureToggleContainer/helpers/isDevFeatureEnabled';
import { EDevFeature } from '@app/components/ui/DevFeatureToggleContainer/types';
import { TextSkeleton } from '@app/components/ui/TextSkeleton';
import { EDealTradeRole, EFlowStep } from '@app/context/FlowTransitionsContext';
import { apiRoutes } from '@app/core/__generated__/apiRoutes';
import { TEST_IDS } from '@app/core/constants';
import { useFlowTransitionsContext } from '@app/hooks/useFlowTransitionsContext';
import { routes } from '@app/routes/config';
import { calculateTypographyHeight } from '@app/utils/calculateTypographyHeight';
import { useNavigate } from 'react-router-dom';

interface IDealSubmittedProps {}

export const DealSubmitted: FC<IDealSubmittedProps> = () => {
  const { close, getFlowState, goTo } = useFlowTransitionsContext();
  const { counterpartyCompanyId, tradeRole } = getFlowState();
  const theme = useTheme();
  const navigate = useNavigate();

  const targetRequest = tradeRole === EDealTradeRole.Supplier ? apiRoutes.BuyerGetBuyer : apiRoutes.SupplierGetSupplier;
  const httpClient = useHttpClient();
  const { data } = useQuery({
    queryKey: [targetRequest, counterpartyCompanyId],
    queryFn: () => {
      return httpClient(targetRequest, 'post', {
        data: {
          id: counterpartyCompanyId!,
        },
      });
    },
  });

  const onViewDealsPageClick = useCallback(() => {
    close();
    navigate(routes.deals.path);
  }, [close, navigate]);

  const handleGoToInviteTradePartner = () => {
    goTo(EFlowStep.INVITE_TRADE_PARTNER);
  };

  const isInvitationFlowEnabled = isDevFeatureEnabled(EDevFeature.InvitationFlow);

  return (
    <StepContainer
      dataTestId={TEST_IDS.dealSubmitted}
      title={<FormattedMessage id="createDeal.dealSubmitted" />}
      canGoBack={false}
      showActions={false}
    >
      <Card
        sx={(theme) => ({
          padding: 2,
          boxShadow: 'none',
          border: `1px solid ${theme.palette.grey['200']}`,
          borderRadius: theme.shape.borderRadius / 2,
        })}
      >
        <Stack gap={2} textAlign="center" alignItems="center">
          <CheckCircle
            fontSize="large"
            style={{
              fontSize: 80,
            }}
            color="success"
          />
          <Typography.TextMd.SemiBold color="text.primary">
            <FormattedMessage
              id="createDeal.dealSubmitted.description1"
              values={{
                Name: data?.counterpartyCompanyName ? (
                  <span data-testid={TEST_IDS.companyName}>{data?.counterpartyCompanyName}</span>
                ) : (
                  <TextSkeleton
                    data-testid={TEST_IDS.companyNameLoading}
                    status="loading"
                    style={{
                      height: calculateTypographyHeight(theme.typography.body1),
                      width: 100,
                    }}
                  />
                ),
              }}
            />
          </Typography.TextMd.SemiBold>
          <Typography.TextMd.Regular color="text.primary">
            <FormattedMessage id="createDeal.dealSubmitted.description2" />
          </Typography.TextMd.Regular>
          <Typography.TextMd.Regular color="text.primary">
            <FormattedMessage id="createDeal.dealSubmitted.description3" />
          </Typography.TextMd.Regular>
          <Stack gap={2} direction="row" alignItems="center" justifyContent="stretch" sx={{ width: '100%' }}>
            {isInvitationFlowEnabled && (
              <Button
                variant="tertiary"
                size="small"
                onClick={handleGoToInviteTradePartner}
                data-testid={TEST_IDS.inviteTradePartner}
                sx={{ flexGrow: 1 }}
              >
                <FormattedMessage id="createDeal.dealSubmitted.inviteTradePartner" />
              </Button>
            )}
            <Button
              variant="tertiary"
              size="small"
              onClick={onViewDealsPageClick}
              data-testid={TEST_IDS.viewDealsButton}
              sx={{ flexGrow: 1 }}
            >
              <FormattedMessage id="createDeal.dealSubmitted.viewDealsPage" />
            </Button>
          </Stack>
        </Stack>
      </Card>
    </StepContainer>
  );
};
