import { IntlFormatters } from 'react-intl';

import { REGEXP } from '@app/core/constants';
import * as yup from 'yup';

import { IInviteTradePartnerForm } from './types';

export const getSchema = (formatMessage: IntlFormatters['formatMessage']) =>
  yup.object().shape<Record<keyof IInviteTradePartnerForm, yup.AnySchema>>({
    firstName: yup
      .string()
      .trim()
      .matches(RegExp(REGEXP.userName), formatMessage({ id: 'constants.frontendErrors.enterValidFirstName' }))
      .matches(RegExp(REGEXP.validName), formatMessage({ id: 'constants.frontendErrors.enterValidFirstName' }))
      .max(40)
      .required(formatMessage({ id: 'constants.frontendErrors.enterName' })),
    lastName: yup
      .string()
      .trim()
      .matches(RegExp(REGEXP.userName), formatMessage({ id: 'constants.frontendErrors.enterValidLastName' }))
      .matches(RegExp(REGEXP.validName), formatMessage({ id: 'constants.frontendErrors.enterValidLastName' }))
      .max(40)
      .required(formatMessage({ id: 'constants.frontendErrors.enterLastName' })),
    email: yup
      .string()
      .matches(RegExp(REGEXP.email), formatMessage({ id: 'constants.frontendErrors.enterValidEmail' }))
      .email(formatMessage({ id: 'constants.frontendErrors.enterValidEmail' }))
      .required(formatMessage({ id: 'constants.frontendErrors.wrongEmailFormat' })),
  });
