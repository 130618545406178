import { SvgIcon } from '@app/core/icons';
import styled, { css } from 'styled-components';

export const NotificationListContainer = styled.div<{
  isNotificationsListOpen: boolean;
}>`
  display: ${({ isNotificationsListOpen }) => (isNotificationsListOpen ? 'block' : 'none')};
  position: absolute;
  right: 16px;
  top: 45px;
  width: 385px;
  z-index: 99999;
  padding: ${({ theme }) => theme.spacing(1.5)};
  gap: ${({ theme }) => theme.spacing(1.5)};
  border-radius: ${({ theme }) => theme.spacing(2)};
  border: 1px solid ${({ theme }) => theme.palette.divider};
  background: ${({ theme }) => theme.palette.background.level3};
  box-shadow: 0 8px 30px rgb(0 0 0 / 12%);
  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      left: 0;
      max-height: 100%;
      width: 100%;
      height: 100%;
    }
  `}
`;

export const ScrollableContainer = styled.div<{
  isNotificationsListEmpty: boolean;
}>`
  overflow-y: auto;
  height: ${({ isNotificationsListEmpty }) => (isNotificationsListEmpty ? '342px' : '594px')};
  ${({ theme }) => `
  ${theme.breakpoints.down('sm')} {
    height: 100vh;
  }
`}
`;

export const NotificationListHeader = styled.div<{ isListEmpty: boolean }>`
  ${({ isListEmpty }) =>
    isListEmpty &&
    css`
      position: absolute;
      > div {
        visibility: hidden;
      }
    `};
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 20px;
`;

export const NewNotifications = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const SeeEarlier = styled.div`
  margin-left: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const DeleteAndMark = styled.div<{ count: number }>`
  display: flex;
  justify-content: space-around;
  margin-top: 4px;
  width: ${({ count }) => count > 0 && '60%'};

  ${({ theme }) => `
    ${theme.breakpoints.down('lg')} {
    width: 57%;
  }
`}
`;
export const MarkAllAsRead = styled.div`
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: ${({ theme }) => theme.palette.primary.light};
`;

export const DeleteAll = styled.div<{ count: number }>`
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: ${({ theme }) => {
    return theme.palette.primary.light;
  }};

  ${({ theme }) => `
  ${theme.breakpoints.down('lg')} {
    &::before {
      right: 171px;
    }
  }
`}
`;

export const Divider = styled.div`
  background: ${({ theme }) => theme.palette.grey[300]};

  position: static;
  width: 1px;
  height: 15px;
  left: 68px;
  top: 1.5px;
  flex: none;
  margin: 0 8px;
`;

export const CloseButton = styled.button`
  display: block;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  color: ${({ theme }) => theme.palette.grey[300]};
  font-size: 20px;
  cursor: pointer;

  ${({ theme }) => `
  ${theme.breakpoints.down('lg')} {
    display: inline-flex;
  }
`}
`;

export const StyledSvgIcon = styled(SvgIcon)<any>`
  height: 18px;
  width: 18px;

  ${({ theme }) => `
  ${theme.breakpoints.down('md')} {
    width: unset;
  }
`}
`;

export const EmptyListMessage = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.grey['900']};
`;

export const ReadedNotification = styled.div<{ viewMode: boolean }>`
  padding: 16px;
  cursor: ${({ viewMode }) => viewMode && 'pointer'};
`;

export const NotificationsDate = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: ${({ theme }) => theme.palette.grey[900]};
`;

export const NotificationText = styled.div`
  font-style: normal;
  font-weight: normal;
  margin-top: 2px;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.grey[900]};
`;

export const ViewButton = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  margin-top: 2px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: ${({ theme }) => theme.palette.primary.light};
`;

export const NewNotificationsList = styled.div`
  padding: 16px;
  cursor: pointer;
  transition: border 0.2s;
  border: 1px solid transparent;
  border-radius: 4px;
  &:hover {
    border: 1px solid ${({ theme }) => theme.palette.primary.light};
  }
`;

export const NewNotificationsDate = styled.div`
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: ${({ theme }) => theme.palette.grey[300]};
`;

export const NewNotificationDateText = styled.div`
  margin-left: 10px;
`;

export const NewNotificationRedIcon = styled.div`
  width: 10px;
  height: 10px;
  background: ${({ theme }) => theme.palette.error.main};
  border: ${({ theme }) => {
    return theme.palette.primary.contrast + ' solid 1px';
  }};
  border-radius: 2000px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: 5px;
`;

export const ListLoaderContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 12px;
  display: flex;
  justify-content: center;
  pointer-events: none;
  opacity: 0.5;
`;

export const HiddenScrollableSpace = styled.div`
  height: 110px;
`;
